var xhrSlide = null;
var xhrDel = null;
var INTERFACE_FRONT = {
    elts: {},
    twoBytesChar: ['0C', '5E', '7B', '7D', '5C', '5B', '7E', '5D', '7C', 'E282AC'],
    length1Sms: 161,
    length2Sms: 307,
    length3Sms: 460,
    length4Sms: 613,
    length5Sms: 766,
    length6Sms: 919,
    lengthSms: [0, 161, 307, 460, 613, 766, 919],
    currentLength: 0,
    stopLength: 14,
    stopLengthMms: 42,
    maxNbSms: 6,
    xhr: "",
    envMode: "",
    twig: {},
    requestCpteProspect: null,
    requestCpteProspectBtoB: null,
    urlCpteProspect: "",
    urlCpteProspectBtoB: "",

    init: function () {

        if ($("#nouvelleCampagneSms-Form").length) {
            $('input[type=checkbox]').prop('checked', false);

            // $('#modeleMiniSiteId').val(0);
            // $('#urlMiniSiteLength').val(0);

            //on supprime '{#lien_landing#}' du message au reload pour éviter les problèmes
            // var message = $('#campagne_donnee_sms_message').val();
            // $('#campagne_donnee_sms_message').val(message.replace('{#lien_landing#}',''));


            //            comme le message est effacé à l'init (voir ci dessus), plus la peine de vérifier les champs variables
            //            this._afficheChampsVariables($("#campagne_listeContacts"));
            this.initNbreContact();
            this.countChar($('#campagne_donnee_sms_message'), this.twig.stopLengthSms);
            this.countNbSms(this.currentLength, $('#campagne_donnee_sms_message'));
            this.updateLength('#charNum');
            $('#campagne_nbContactsBlackListManuel').val(0);
            $('.alert').addClass('hide');
            $(document).ready(function () {
                $('#campagne_nbContactsManuel').trigger('change');
                $('#campagne_donneesManuelles').trigger('blur');
                $("#campagne_listeContacts").trigger('change');
            });

            this.confirmBeforeLeaving();

            $('#campagne_donnee_sms_emetteur').on('blur', function (e) {
                var sender = $("#campagne_donnee_sms_emetteur_hidden").val();
                if ($(this).val() == '') {
                    $(this).val(sender);
                }
            });
        }

        $('.mydropdownMenu').hover(function () {
            $(this).addClass('show');
            $(this).find('.dropdown-menu').addClass('show');
        },
            function () {
                $(this).removeClass('show');
                $(this).find('.dropdown-menu').removeClass('show');
            });

        if ($("#nouvelleCampagneVms-Form").length) {
            this.initNbreContact();
            this.confirmBeforeLeaving();
            $('#campagne_nbContactsBlackListManuel').val(0);
            $('.alert').addClass('hide');
            if ($('#sauveCampagne').data('type') != "edition") {
                $('#sauveCampagne').attr('disabled', 'disabled');
            }

            $(document).ready(function () {
                $('#campagne_nbContactsManuel').trigger('change');
                $('#campagne_donneesManuelles').trigger('blur');

                if ($("#campagne_donnee_vms_numRappel").val() == "") {
                    $("#campagne_donnee_vms_afficheNumRappel").val("0");
                }
                if ($("#campagne_donnee_vms_afficheNumRappel").val() == 1) {
                    $("#afficheRappelCheck").attr("checked", true);
                }
            });

        }


        $('[data-toggle="tooltip"]').tooltip();

        if ($("#demande-inscription").length) {
            $("#demande-inscription").validate({
                rules: {
                    "user_registration[email]": {
                        emailValid: true,
                        required: true
                    }
                }
            });

            $("#user_registration_commentConnu").on('change', function (e) {
                if ($(this).val() == "Autres") {
                    $("#commentAutreReso").fadeOut();
                    $("#commentAutre").fadeIn();
                } else if ($(this).val() == "Réseaux sociaux") {
                    $("#commentAutre").fadeOut();
                    $("#commentAutreReso").fadeIn();
                } else {
                    $("#commentAutre").fadeOut();
                    $("#commentAutreReso").fadeOut();
                }
            });
        }

        if ($("#login-form-reset").length) {
            $("#login-form-reset").validate({
                rules: {
                    username: {
                        emailValid: true,
                        required: true
                    }
                }
            });
        }

        if ($("#formUserEdit").length) {
            $("#formUserEdit").validate({
                rules: {
                    "utilisateur[email]": {
                        emailValid: true,
                        required: true
                    }
                }
            });
        }

        if ($("#formUserCoord").length) {
            $("#formUserCoord").validate({
                rules: {
                    "utilisateur_coordonnee[email]": {
                        emailValid: true,
                        required: true
                    }
                }
            });
        }

        //on augmente la taille de preview des images
        $.widget('blueimp.fileupload', $.blueimp.fileupload, {
            options: {
                // The maximum width of the preview images:
                previewMaxWidth: 320,
                // The maximum height of the preview images:
                previewMaxHeight: 320,
                dropZone: null
            },
        });

        $('#chercheTextCampagne').val('');

        $('#min-date').val('');
        $('#max-date').val('');
        $('#marque_logos_file').fileupload({
            global: false
        });

        $('#bandeau_image_file').fileupload({
            global: false
        });

        $('#compte_campagne_headersPerso_file').fileupload({
            global: false
        });

        $('#donnee_mini_site_beforeImage_file').fileupload({
            global: false
        });

        $('#donnee_mini_site_afterImage_file').fileupload({
            global: false
        });

        $('#profilia_demande_comptage_bto_b_fichiers_file,#campagne_demande_mms_fichiers_file, #campagne_demande_email_fichiers_file, #crm_demande_fichiers_file, #crm_demande_fichiersVisuels_file, #crm_demande_fichiersVisuelsMms_file, #crm_demande_fichiersVisuelsEmail_file, #crm_demande_fichiersVisuelsMiniSite_file').fileupload({
            global: false
        });


        $('#donnee_mini_site_sliders_file').fileupload({
            global: false
        }).on("fileuploaddone", function (e, data) {
            var dataId = "";
            var sep = "";
            var sliderTimer;
            var doneSliderInterval = 1000;

            setTimeout(sliderTime, doneSliderInterval);

            function sliderTime() {
                if (xhrSlide !== null) {
                    xhrSlide.abort();
                    xhrSlide = null;
                }

                $('.ui-sortable .st-file-preview ').each(function (key, value) {
                    dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                    sep = "-";
                });

                xhrSlide = $.ajax({
                    url: $('#donnee_mini_site_exempleSliderUrl').data('route'),
                    data: { "ids": dataId },
                    type: "post",
                    cache: false,
                    dataType: "html",
                    global: false,
                    error: function (err) {
                        console.log('Traitement en cours');
                    },
                    success: function (html) {
                        $("#carouselExampleIndicators").html(html);
                    },
                });
            }
        });

        if ($("#nouveauPointDeVente-Form").length) {
            $('#compte_campagne_logos_file').fileupload({
                global: false
            });


            $(document).on('focusout', '#compte_campagne_codeFacturation', function () {
                if ($(this).val() != "" && $(this).val().length > 3) {
                    $.ajax({
                        type: "POST",
                        cache: false,
                        url: $(this).data("route"),
                        data: {
                            "codeFact": $(this).val(),
                        },
                        success: function (data) {
                            $('#text-retour-codeFact').html('<i class="ml-2 fas fa-check text-success"></i>');

                        },
                        statusCode: {
                            400: function (data) {
                                $('#text-retour-codeFact').html('<i class="fas fa-times ml-2 text-danger"></i>');
                                $.alert({
                                    title: '<i class="fas fa-exclamation-triangle mr-2"></i> Attention:',
                                    content: data.responseJSON.message + "<br>" + "<b>Groupe principal :</b><br/>" + data.responseJSON.groupPdv + "<br/><b>Point de vente :</b><br>" + data.responseJSON.nomPdv + "<br><b>Ville :</b><br>" +data.responseJSON.villePdv,
                                    type: 'orange',
                                });
                            }
                        }
                    });
                }
            });


            $('input[name="compte_campagne[memeAdresseFacturation]"]').on('change', function (e) {
                if ($(this).val() == 0) {
                    $("#adrFact").fadeIn();
                } else {
                    $("#adrFact").fadeOut();
                }
            });

            /* si coché -> forcer affichage pour édition */
            if ($('#compte_campagne_memeAdresseFacturation_1').is(':checked')) {
                $("#adrFact").show();
            }
            $('#compte_campagne_typePaiement').on('change', function (e) {
                if ($(this).val() == 2) {
                    $("#paiCred").fadeIn();
                    $("#paiAbo").fadeOut();
                } else {
                    $("#paiAbo").fadeIn();
                    $("#paiCred").fadeOut();
                }
            });

            /* si coché -> forcer affichage pour édition */
            if ($('#compte_campagne_typePaiement').val() == 2) {
                $("#paiCred").fadeIn();
                $("#paiAbo").fadeOut();
            }else{
                $("#paiAbo").fadeIn();
                $("#paiCred").fadeOut();
            }

            this._imageLabel();

            $("#nouveauPointDeVente-Form").validate({
                rules: {
                    "compte_campagne[telephone]": {
                        required: false,
                        phoneFR: true
                    }
                }
            });

            $('#nouveauPointDeVente-Form input').on('keyup blur', function () {
                if ($('#nouveauPointDeVente-Form').valid()) {
                    $('button.btn').prop('disabled', false);
                } else {
                    $('button.btn').prop('disabled', 'disabled');
                }
            });
        }

        if ($('#compte_campagne_logos').length) {
            $('#compte_campagne_logos > .st-files').sortable({
                placeholder: "ui-state-highlight",
                start: function (event, ui) {
                    ui.item.startPos = ui.item.index();
                },
                stop: function (event, ui) {
                    var dataId = "";
                    var sep = "";
                    /* On récupere tous les id pour les ordonner */
                    $('.ui-sortable .st-file-preview ').each(function (key, value) {
                        dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                        sep = "-";
                    });

                    /*idS = $(ui.item[0]).find('.st-remove-file-btn').data('file-id');
                    console.log("Start position: " + ui.item.startPos + 1);
                    console.log("New position: " + ui.item.index() + 1);
                    console.log("id: " + idS);
                    oldPos = parseInt(ui.item.startPos) + 1;
                    newPos = parseInt(ui.item.index()) + 1;*/
                    INTERFACE_FRONT._imageLabel();
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: $('#compte_campagne_positionLogosUrl').data('route'),
                        data: "idsImg=" + dataId + "&timestamp=" + new Date().getTime(),
                        success: function (json) {
                            console.log(json.message);
                        }
                    });
                }
            });

            $("#compte_campagne_logos > .st-files").disableSelection();
        }

        if ($('#donnee_mini_site_sliders').length) {
            //utilisation de tinyMCE car via le mycore bundle, impossible de traquer le NodeChange
            tinyMCE.init({
                selector: ".mini_site_color",
                toolbar: 'bold italic underline forecolor',
                plugins: 'advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality template paste textcolor colorpicker textpattern imagetools',
                statusbar: false,
                menubar: false,
                setup: function (ed) {
                    ed.on('NodeChange', function (e) {
                        var target = $('#' + ed.id).data('target');
                        var content = ed.getContent();
                        $(target).html(content.replace(/<\/?p>/g, ''));

                        if (content != '') {
                            $(target).show();
                        } else {
                            $(target).hide();
                        }
                    });
                }
            });


            $('.btnSave').on('click', function (e) {
                $("#theSaveType").val($(this).val());
            });
            $('#formControlTempsSlide').on('mousemove', function (e) {
                if (e.buttons == 1) {
                    $("#range_value_tps_slide").html($(this).val());
                    $("#donnee_mini_site_dureeSlider").val($(this).val());
                    var options = $(".carousel").data()['bs.carousel']["_config"];
                    options.interval = $(this).val() * 1000;
                }
            });

            $('#formControlRangeTpsRedirect').on('mousemove', function (e) {
                if (e.buttons == 1) {
                    $("#range_value_tps_redir").html($(this).val());
                    $("#donnee_mini_site_tempsAttenteRedirect").val($(this).val());
                }
            });

            $('#formControlRangeGrat').on('mousemove', function (e) {
                if (e.buttons == 1) {
                    $("#range_value_percent_grat").html($(this).val());
                    $("#donnee_mini_site_pourcentGrattage").val($(this).val());
                }
            });

            $('.clicType').on('click', function (e) {
                $('#donnee_mini_site_typeGraphismeAvance').val($(this).data("type"));
                if ($(this).data("type") == "0") {
                    $("#pourcentGrat").hide();
                    $("#pills-home").hide();
                } else if ($(this).data("type") == "1") {
                    $("#pourcentGrat").show();
                    $("#pills-home").show();
                } else {
                    $("#pourcentGrat").hide();
                    $("#pills-home").show();
                }
            });

            $('#donnee_mini_site_redirectionFormulaire').on('change', function (e) {
                if ($(this).val() == "2") {
                    $("#collapseRedirectTwo").show();
                } else {
                    $("#collapseRedirectTwo").hide();
                }
                if ($(this).val() == "0") {
                    $("#collapseRedirectOne").hide();
                } else {
                    $("#collapseRedirectOne").show();
                }
            });
            $('#donnee_mini_site_videoYoutube').on('blur', function (e) {
                if ($(this).val() != "") {
                    str = $(this).val();
                    strVid = str.replace("https://www.youtube.com/watch?v=", "");
                    that = $(this);
                    $.ajax({
                        url: $('#donnee_mini_site_exempleSliderUrl').data('route'),
                        data: { "vid": strVid },
                        type: "post",
                        cache: false,
                        dataType: "html",
                        global: false,
                        error: function (err) {
                            $(this).val('');
                            $.alert({
                                title: 'Erreur:',
                                content: err.responseText,
                                type: 'red',
                                onClose: function () {
                                    that.val('');
                                },
                            });
                        },
                        success: function (html) {
                            $("#carouselExampleIndicators").html(html);
                        },
                    });
                } else {
                    var dataId = "";
                    var sep = "";
                    /* On récupere tous les id pour les ordonner */
                    $('.ui-sortable .st-file-preview ').each(function (key, value) {
                        dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                        sep = "-";
                    });
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: $('#donnee_mini_site_positionSliderUrl').data('route'),
                        data: "idsImg=" + dataId + "&timestamp=" + new Date().getTime(),
                        success: function (json) {
                            console.log(json.message);

                            var sliderTimer;
                            var doneSliderInterval = 1000;

                            setTimeout(sliderMajTime, doneSliderInterval);

                            function sliderMajTime() {
                                if (xhrSlide !== null) {
                                    xhrSlide.abort();
                                    xhrSlide = null;
                                }

                                $('.ui-sortable .st-file-preview ').each(function (key, value) {
                                    dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                                    sep = "-";
                                });

                                xhrSlide = $.ajax({
                                    url: $('#donnee_mini_site_exempleSliderUrl').data('route'),
                                    data: { "ids": dataId },
                                    type: "post",
                                    cache: false,
                                    dataType: "html",
                                    global: false,
                                    error: function (err) {
                                        console.log('Traitement en cours');
                                    },
                                    success: function (html) {
                                        $("#carouselExampleIndicators").html(html);
                                    },
                                });
                            }
                        }
                    });
                }
            });

            $('#donnee_mini_site_sliders > .st-files').sortable({
                placeholder: "ui-state-highlight",
                start: function (event, ui) {
                    ui.item.startPos = ui.item.index();
                },
                stop: function (event, ui) {

                    var dataId = "";
                    var sep = "";
                    /* On récupere tous les id pour les ordonner */
                    $('.ui-sortable .st-file-preview ').each(function (key, value) {
                        dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                        sep = "-";
                    });
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: $('#donnee_mini_site_positionSliderUrl').data('route'),
                        data: "idsImg=" + dataId + "&timestamp=" + new Date().getTime(),
                        success: function (json) {
                            console.log(json.message);

                            var sliderTimer;
                            var doneSliderInterval = 1000;

                            setTimeout(sliderMajTime, doneSliderInterval);

                            function sliderMajTime() {
                                if (xhrSlide !== null) {
                                    xhrSlide.abort();
                                    xhrSlide = null;
                                }

                                $('.ui-sortable .st-file-preview ').each(function (key, value) {
                                    dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                                    sep = "-";
                                });

                                xhrSlide = $.ajax({
                                    url: $('#donnee_mini_site_exempleSliderUrl').data('route'),
                                    data: { "ids": dataId },
                                    type: "post",
                                    cache: false,
                                    dataType: "html",
                                    global: false,
                                    error: function (err) {
                                        console.log('Traitement en cours');
                                    },
                                    success: function (html) {
                                        $("#carouselExampleIndicators").html(html);
                                    },
                                });
                            }
                        }
                    });
                }
            });
            $('.st-files').on('click', '.st-remove-file-btn', function (event) {

                var dataId = "";
                var sep = "";

                var sliderTimer;
                var doneSliderInterval = 1000;

                setTimeout(sliderMajTime, doneSliderInterval);

                function sliderMajTime() {
                    if (xhrSlide !== null) {
                        xhrSlide.abort();
                        xhrSlide = null;
                    }

                    $('.ui-sortable .st-file-preview ').each(function (key, value) {
                        dataId = dataId + sep + $(value).find('.st-remove-file-btn').data('file-id');
                        sep = "-";
                    });

                    xhrSlide = $.ajax({
                        url: $('#donnee_mini_site_exempleSliderUrl').data('route'),
                        data: { "ids": dataId },
                        type: "post",
                        cache: false,
                        dataType: "html",
                        global: false,
                        error: function (err) {
                            console.log('Traitement en cours');
                        },
                        success: function (html) {
                            $("#carouselExampleIndicators").html(html);
                        },
                    });
                }
            });

            $("#donnee_mini_site_sliders > .st-files").disableSelection();
        }

        //on augmente la taille de preview des images
        $.widget('blueimp.fileupload', $.blueimp.fileupload, {
            options: {
                // The maximum width of the preview images:
                previewMaxWidth: 320,
                // The maximum height of the preview images:
                previewMaxHeight: 320,
            },
        });

        $.fn.invisible = function () {
            return this.each(function () {
                $(this).css("visibility", "hidden");
            });
        };
        $.fn.visible = function () {
            return this.each(function () {
                $(this).css("visibility", "visible");
            });
        };

        $.fn.moveToLeft = function () {
            return this.each(function () {
                $(this).css("position", "absolute");
                $(this).css("left", "-900px");
            });
        };

        $.fn.returnFromLeft = function () {
            return this.each(function () {
                $(this).css("position", "");
                $(this).css("left", "");
            });
        };

        if ($('#nouveauModeleMiniSite-Form').length) {
            this.confirmBeforeLeaving();
            this._initDatasMiniSite();
            this._displayErrorsSmsInt();
            this.resetIsSave();
        }

        if ($("#importMessageVocal-Form").length) {
            this._validImportMessageVocal();
        }

        if ($('#formDemande').length) {
            this.confirmBeforeLeaving();
            this.sauveExtractionProspects();
            this._bootstrapTabControl();
            this._setupHiddenInputChangeListener($('#profilia_demande_prospect_ageDebut')[0]);
            this._setupHiddenInputChangeListener($('#profilia_demande_prospect_ageFin')[0]);
            this.profilia(initValues);
            this.findCity();
            this.findState();
            this.deleteState();
            this.deleteCity();
            this.compteProspects();
            this.recapProspects();
            this.addState();
            this.addFrance();
            this.addCity();
            this._updateCoutProspect();
            this._eraseAllCityState();
        }

        if ($('#formDemandeBtoB').length) {
            this.confirmBeforeLeaving();
            this.sauveExtractionProspectsBtoB();
            this._bootstrapTabControlBtoB();
            this._setupHiddenInputChangeListener($('#emb_demande_prospect_anneeDebut')[0]);
            this._setupHiddenInputChangeListener($('#emb_demande_prospect_anneeFin')[0]);
            this.emb(initValuesBtoB);
            this.findCityBtoB();
            this._searchNaf();
            this.findStateBtoB();
            this.deleteStateBtoB();
            this.deleteCityBtoB();
            this.compteProspectsBtoB();
            this.recapProspectsBtoB();
            this.addStateBtoB();
            this.addFranceBtoB();
            this.addCityBtoB();
            this.addNafBtoB();
            this.deleteNafBtoB();
            this._updateCoutProspectBtoB();
            this._eraseAllCityStateBtoB();
        }
    },

    _imageLabel: function () {
        $('.logo').remove();
        $('#compte_campagne_logos .st-files').children().each(function (index, value) {
            //premier logo
            if (index == 0) {
                $(this).prepend('<p class="logo text-light">Par défaut</p>');
            } else {
                $(this).prepend('<p class="logo text-light">Logo ' + index + '</p>');
            }
        });
    },

    initGlobalDataTable: function () {
        $.extend(true, $.fn.DataTable.defaults, {
            "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
            },
            "aoColumnDefs": [{
                "bSortable": false,
                "aTargets": ["sorting-disabled"]
            }]
        });

        //fonction qui permet de faire une recherche sur le téléphone en entrant 06...
        //au lieu du format international +336...
        $.fn.DataTable.ext.type.search.string = function (data) {
            return !data ?
                '' :
                typeof data === 'string' ?
                    data
                        .replace(/^0/g, '') :
                    data;
        };

        //fonction pour filtrer une datable entre deux dates
        $.fn.DataTable.ext.search.push(
            function (settings, data, dataIndex) {
                if (settings.oInit.datePicker == true) {
                    var min = $('#min-date').datepicker("getDate");
                    var max = $('#max-date').datepicker("getDate");
                    var dateMoment = moment(data[3].substr(0, 10), 'DD-MM-YYYY');

                    var day = dateMoment.format('D');
                    var month = dateMoment.format('MM') - 1;
                    var year = dateMoment.format('YYYY');
                    var startDate = new Date(year, month, day);

                    if (min == null && max == null) {
                        true;
                    }
                    if (min == null && startDate <= max) {
                        return true;
                    }
                    if (max == null && startDate >= min) {
                        return true;
                    }
                    if (startDate <= max && startDate >= min) {
                        return true;
                    }
                    return false;
                }
                return true;
            });

        //fonctions pour trier les dates dans une datatable
        function trim(str) {
            str = str.replace(/^\s+/, '');
            for (var i = str.length - 1; i >= 0; i--) {
                if (/\S/.test(str.charAt(i))) {
                    str = str.substring(0, i + 1);
                    break;
                }
            }
            return str;
        }

        function dateHeight(dateStr) {
            if (trim(dateStr) != '') {
                var frDate = trim(dateStr).split(' ');
                var frTime = frDate[1].split(':');
                var frDateParts = frDate[0].split('/');
                var day = frDateParts[0] * 60 * 24;
                var month = frDateParts[1] * 60 * 24 * 31;
                var year = frDateParts[2] * 60 * 24 * 366;
                var hour = frTime[0] * 60;
                var minutes = frTime[1];
                var x = day + month + year + hour + minutes;
            } else {
                var x = 99999999999999999; //GoHorse!
            }
            return x;
        }

        $.fn.dataTableExt.oSort['date-euro-asc'] = function (a, b) {
            var x = dateHeight(a);
            var y = dateHeight(b);
            var z = ((x < y) ? -1 : ((x > y) ? 1 : 0));
            return z;
        };

        $.fn.dataTableExt.oSort['date-euro-desc'] = function (a, b) {
            var x = dateHeight(a);
            var y = dateHeight(b);
            var z = ((x < y) ? 1 : ((x > y) ? -1 : 0));
            return z;
        };

        // Attention format spécifique à moment.js cf https://momentjs.com/docs/#/displaying/
        $.fn.dataTable.moment('D.M.YYY HH:mm');

        $.fn.dataTable.ext.order['role-select'] = function (settings, col) {
            return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
                return $('select', td).val();
            });
        }

        $.fn.dataTable.ext.buttons.erase = {
            className: 'btn btn-sm btn-danger ml-4',
            text: '<i class="fas fa-trash"></i>',
            action: function (e, dt, node, config) {
                $.confirm({
                    title: config.title,
                    type: 'red',
                    content: config.content,
                    buttons: {
                        confirm: {
                            text: 'Oui',
                            btnClass: 'btn-red',
                            action: function () {
                                e.preventDefault();
                                $.ajax({
                                    type: "POST",
                                    cache: false,
                                    url: config.url,
                                    dataType: "json",
                                    success: function (json) {
                                        dt.clear().draw();
                                        if (json.emb) {
                                            INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                                        } else {
                                            INTERFACE_FRONT._ajaxCompteProspects();
                                        }
                                    }
                                });
                            }
                        },
                        cancel: {
                            text: 'Non'
                        }
                    }
                });
            }
        };
    },

    initDateTimePicker: function (typCamp) {
        $.timepicker.setDefaults($.timepicker.regional['fr']);
        $.datepicker.setDefaults($.datepicker.regional['fr']);

        var minHour = 8;
        var maxHour = 19;

        if(typCamp == "vms"){
            var maxHour = 18;
        }

        var day = moment().format('D');
        var month = moment().format('MM') - 1;
        var year = moment().format('YYYY');
        var hour = moment().format('HH');
        var minute = moment().format('mm');
        var minuteFive = Math.ceil(minute / 5) * 5;
        var defaultMinDate;

        if (hour > maxHour) {
            defaultMinDate = new Date(year, month, day, minHour, 0, 0);
            defaultMinDate.setDate(defaultMinDate.getDate() + 1);
        } else {
            defaultMinDate = new Date(year, month, day, hour, minuteFive, 0);
        }

        $('.js-datetimepicker').datetimepicker({
            controlType: 'select',
            timeFormat: 'HH:mm',
            hourMin: minHour,
            hourMax: maxHour,
            stepMinute: 5,
            minDateTime: defaultMinDate,
            beforeShowDay: this._noWeekendsOrOtherDays,
            beforeShow: function (textbox, instance) {
                setTimeout(function () {
                    $('#program').append($('#ui-datepicker-div'));
                }, 0);
            },
            onSelect: function (date) {
                $('#planDateInput').val(date);
            }
        }
        );

        $('.js-datepicker').datepicker({
            showOn: "both",
            buttonText: "<i class='fa fa-calendar'></i>",
        });

        //seulement pour les statistiques
        $("#min-date").datepicker('option', {
            dateFormat: 'dd/mm/yy',
            onSelect: function (date, inst) {
                var dateSplit = date.split('/');
                var selDate = new Date(dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0]);
                $('#max-date').datepicker('option', 'minDate', selDate);
            }
        });

        $("#max-date").datepicker('option', {
            dateFormat: 'dd/mm/yy',
            onSelect: function (date, inst) {
                var dateSplit = date.split('/');
                var selDate = new Date(dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0]);
                $('#min-date').datepicker('option', 'maxDate', selDate);
            }
        });
    },

    initChart: function () {
        Chart.plugins.register({
            afterDraw: function (chart) {
                //si pas de données à afficher, on met un messsage vide
                var sum = chart.data.datasets[0].data.reduce(function (acc, val) {
                    return acc + val;
                }, 0);
                if (sum == 0) {
                    // No data is present
                    var ctx = chart.chart.ctx;
                    var width = chart.chart.width;
                    var height = chart.chart.height;
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font = "20px normal 'Helvetica Nueue'";
                    ctx.fillStyle = '#FFFFFF';
                    ctx.fillText('Pas de données sur la période', width / 2, height / 2);
                    ctx.restore();
                }
            }
        });
    },

    createAllDataTable: function () {
        var listes = $('#listes').DataTable({
            "columnDefs": [
                {
                    "targets": [0, 1],
                    "visible": false,
                    "searchable": false,
                }
            ],
            "order": [[0, "desc"], [1, "desc"]]
        });
        var utilisateur = $('#utilisateur').DataTable(
            {
                "columns": [
                    null,
                    null,
                    null,
                    null,
                    { "orderDataType": "role-select" }
                ]
            }
        );
        var droitsCompteCampagnes = $('#droits_compte_campagnes').DataTable();
        var marque = $('#marque').DataTable();
        var themeCouleur = $('#theme_couleur').DataTable();
        var compteCampagne = $('#compte_campagne').DataTable();
        var compteVerif = $('#compte_verif').DataTable();
        var comptePrincipal = $('#compte_principal').DataTable();
        var editionListe = $('#editionListe').DataTable();
        var listeNoire = $('#liste-noire').DataTable();
        var listeContact = $('#liste-contact').DataTable();
        var listeUtilisateur = $('#liste_utilisateur').DataTable({
            "columnDefs": [
                {
                    "targets": [7],
                    "searchable": false,
                    "orderable": false,
                }
            ],
            "order": [[5, "asc"], [0, "desc"]]
        });
        var histoAchats = $('#commandes').DataTable();
        var histoAchatsProspect = $('#commande_prospects').DataTable({
            "order": [[0, "desc"]]
        });
        var campagnes = $('#campagnes').DataTable({
            "aoColumns": [
                null,
                null,
                {
                    "sType": "date-euro",
                    "bSortable": true,
                },
                null,
                null
            ],
            "order": [[2, "desc"]]
        });
        INTERFACE_FRONT.elts.statistiques = $('#statistiques').DataTable({
            "columnDefs": [
                {
                    "targets": [6],
                    "visible": false,
                    "searchable": false,
                }
            ],
            "datePicker": true,
        });
        var listeMessageVocal = $('#liste_messageVocal').DataTable({
            "columnDefs": [
                {
                    "targets": [2],
                    "searchable": false,
                }
            ],
            "order": [[3, "desc"], [0, "desc"]],
            "pageLength": 5,
            "lengthMenu": [5, 10, 15, 20]
        });

        // var localisationVille = $('#localisation-ville').DataTable();
        // var localisationDepartement = $('#localisation-departement').DataTable();
        var demandesProfilia = $('#demandes-profilia').DataTable({
            "order": [[0, "desc"]]
        });

        var demandesEmb = $('#demandes-emb').DataTable({
            "order": [[0, "desc"]]
        });

        $(document).on('keyup', ".dataTables_filter input[type='search']", function () {
            listeContact.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            listeNoire.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            // listes.search($.fn.DataTable.ext.type.search.string( this.value )).draw();
            marque.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            themeCouleur.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            utilisateur.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            droitsCompteCampagnes.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            compteCampagne.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            compteVerif.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            var pathname = window.location.pathname;

            if (INTERFACE_FRONT.envMode == "dev") {
                urlVerifCreaCp = "/app_dev.php/crm/verif-creation-cp";
                urlVerifCreapdv = "/app_dev.php/crm/verif-creation-pdv";
            } else {
                urlVerifCreaCp = "/crm/verif-creation-cp";
                urlVerifCreapdv = "/crm/verif-creation-pdv";
            }
            if (pathname == urlVerifCreaCp) {
                compteVerif.on('draw', function () {
                    $('#button-creation').html('<a href="creation-compte" class="btn btn-md btn-success"><i class="fas fa-hotel mr-2"></i>Créer un compte</a>');


                });
            } if(pathname == urlVerifCreapdv) {
                compteVerif.on('draw', function () {
                    $('#button-creation').html('<a href="ajout-point-de-vente" class="btn btn-md btn-success"><i class="fas fa-hotel mr-2"></i>Ajouter un point de vente</a>');

                });
            }
            comptePrincipal.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            editionListe.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            campagnes.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            listeMessageVocal.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
            INTERFACE_FRONT.elts.statistiques.search($.fn.DataTable.ext.type.search.string(this.value)).draw();
        });

        $('.js-datepicker').change(function () {
            INTERFACE_FRONT.elts.statistiques.draw();
        });

        $('#my-table_filter').hide();
    },

    transformClientDataToInput: function () {
        $('.changeDataClient').click(function () {
            $this = $(this);
            $this.replaceWith($('<input />').val($this.text()).attr('id', $this.attr('id')));
        });
    },

    initAjax: function () {
        $(document).ajaxStart(function () {
            $('body').append('<div class="loader"></div>');
            $(".loader").show();
        });

        $(document).ajaxComplete(function () {
            $(".loader").hide();
            $(".loader").remove();
        });

        $.ajaxSetup({
            statusCode: {
                401: function () {
                    $.confirm({
                        title: 'Déconnexion',
                        type: 'red',
                        content: 'En raison d\'une période d\'inactivité, vous avez été automatiquement déconnecté. Veuillez vous connecter à nouveau.',
                        buttons: {
                            confirm: {
                                text: 'OK',
                                btnClass: 'btn-red',
                                action: function () {
                                    if (INTERFACE_FRONT.envMode == "dev") {
                                        urlLogin = "/app_dev.php/login";
                                    } else {
                                        urlLogin = "/login";
                                    }
                                    location.href = urlLogin;
                                }
                            },
                        }
                    });
                }
            }
        });
    },

    // Ajax répertoire consultation
    ajaxConsulterListeContact: function () {
        $('.btnConsulterListe').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('route');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                dataType: "html",
                success: function (data) {
                    $("#listeContactContainer").html(data).fadeIn();
                },
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'L\'affichage des contacts n\'est pas disponible pour une liste de prospects.<br />Fin de validité : ' + err.responseText,
                        type: 'red',
                    });
                },
            });
        })
    },
    /* générique alert choix PDV */
    alertChoixPdv: function () {
        $('.alertChoixPdv').on('click', function (e) {
            e.preventDefault();
            INTERFACE_FRONT._alertChoixCompte();
            return false;
        });
    },
    /* générique alert plus de crédit */
    alertCredit: function () {
        $('.alertCredit').on('click', function (e) {
            e.preventDefault();
            var urlCredit = $("#urlRechargeCredit").attr('href');
            INTERFACE_FRONT._alertCredit(urlCredit);
            return false;
        });
    },
    // Ajax répertoire consultation
    ajaxSupprimerListeContact: function () {
        $('.btnDeleteListe').on('click', function (e) {
            e.preventDefault();
            var idListe = $(this).data('id');
            var urlDeleteListe = $(this).data('route');
            $.confirm({
                title: 'Supprimer le répertoire',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce répertoire ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDeleteListe,
                                dataType: "json",
                                success: function (json) {
                                    $("#trListeContact" + idListe).fadeOut();
                                    $("#listeContactContainer").fadeOut();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        })
    },

    // Fin Ajax répertoire

    // confirm delete point vente
    confirmDeletePtsVente: function () {
        $('.btnDeletePts').on('click', function (e) {
            e.preventDefault();
            var urlDelete = $(this).data('route');
            $.confirm({
                title: 'Supprimer le Point de vente',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce point de vente ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlDelete;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        })
    },

    // confirm delete point vente
    confirmDeleteUser: function () {
        $('.btnDeleteUser').on('click', function (e) {
            e.preventDefault();
            var urlDelete = $(this).data('route');
            $.confirm({
                title: 'Supprimer l\'utilisateur',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer cet utilisateur ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlDelete;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        })
    },

    ajaxListeContact: function () {
        $("button[name='Ajouter']").click(function (e) {
            e.preventDefault();
            var form = $("#nouvelleListe-Form")[0];
            var url = $(this).data('route');
            $.ajax({
                url: url,
                data: new FormData(form),
                type: "post",
                contentType: false,
                processData: false,
                cache: false,
                dataType: "json",
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red',
                    });
                },
                success: function (data) {
                    $("#apercu").html(data.html);
                },
            });
        });
    },

    ajaxListeContactTechnique: function () {
        $("button[name='AjouterTechnique']").click(function (e) {
            e.preventDefault();
            var form = $("#nouvelleListe-FormTechnique")[0];
            var url = $(this).data('route');
            $.ajax({
                url: url,
                data: new FormData(form),
                type: "post",
                contentType: false,
                processData: false,
                cache: false,
                dataType: "json",
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red',
                    });
                },
                success: function (data) {
                    $("#apercu").html(data.html);
                },
            });
        });
    },

    _checkProgress: function () {
        var interval = 1000;
        var myProgress;

        if (INTERFACE_FRONT.envMode == "dev") {
            urlProgress = "/app_dev.php/progress-upload";
        } else {
            urlProgress = "/progress-upload";
        }

        function sleep(milliseconds) {
            var start = new Date().getTime();
            for (var i = 0; i < 1e7; i++) {
                if ((new Date().getTime() - start) > milliseconds) {
                    break;
                }
            }
        }

        function doAjax() {
            $.ajax({
                type: 'POST',
                url: urlProgress,
                async: true,
                cache: false,
                dataType: "json",
                global: false,
                success: function (data) {
                    //mise à jour valeur
                    $('body').append('<div class="progress">' + data.percent + '</div>');
                    if (data.percent < 100) {
                        doAjax();
                    }
                },
                complete: function () {
                }
            });
        }

        for (var i = 0; i < 3; i++) {
            sleep(2000);
        }

    },

    // submitForm: function () {
    //     $("button[type='submit']").on("click", function (e) {
    //         e.preventDefault();
    //         var c = $(this).closest("form");
    //         c.submit();
    //     })
    // },

    ajaxSmsCheck: function () {
        var xhr = null;
        var typingTimer;
        var doneTypingInterval = 1000;
        var textarea;

        $("#campagne_donnee_sms_message").on("keyup", function (e) {
            if (xhr !== null) {
                xhr.abort();
                xhr = null;
            }

            textarea = document.getElementById('campagne_donnee_sms_message');
            posCaret = INTERFACE_FRONT._getCaretPosition(textarea);
            e.preventDefault();
            clearTimeout(typingTimer);
            if ($('#campagne_donnee_sms_message').val()) {
                var url = $(this).data('route');
                typingTimer = setTimeout(doneTyping, doneTypingInterval, url);
            }
        });

        function doneTyping(url) {
            xhr = $.ajax({
                url: url,
                data: { text_sms: $("#campagne_donnee_sms_message").val() },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                error: function (err) {
                    console.log('Traitement en cours');
                },
                success: function (data) {
                    $("#text_sms_erreur").html(data.html);
                    $("#campagne_donnee_sms_message").val(data.sms);
                    INTERFACE_FRONT._setCaretPosition(textarea, posCaret.start, posCaret.end);
                },
            });
        }
    },

    eventTextSms: function () {
        var that = this;
        $('#campagne_donnee_sms_message').on('input keyup',
            function (e) {
                e.preventDefault();
                that.countChar($('#campagne_donnee_sms_message'), that.twig.stopLengthSms);
                that.countNbSms(that.currentLength, $('#campagne_donnee_sms_message'));
                that.updateLength('#charNum');
            }
        );
    },

    _getCaretPosition: function (textarea) {
        // IE < 9 Support
        if (document.selection) {
            textarea.focus();
            var range = document.selection.createRange();
            var rangelen = range.text.length;
            range.moveStart('character', -textarea.value.length);
            var start = range.text.length - rangelen;
            return {
                'start': start,
                'end': start + rangelen
            };
        } // IE >=9 and other browsers
        else if (textarea.selectionStart || textarea.selectionStart == '0') {
            return {
                'start': textarea.selectionStart,
                'end': textarea.selectionEnd
            };
        } else {
            return {
                'start': 0,
                'end': 0
            };
        }
    },

    _setCaretPosition: function (textarea, start, end) {
        // IE >= 9 and other browsers
        if (textarea.setSelectionRange) {
            //            textarea.focus(); mantis 20
            textarea.setSelectionRange(start, end);
        }
        // IE < 9
        else if (textarea.createTextRange) {
            var range = textarea.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    },

    countChar: function (champ, stopLength) {
        var charLength = 0;
        var text_sms = champ.val();
        var len = 0;
        var urlLengthMiniSite = $('#urlMiniSiteLength').val();

        if (!text_sms) {
            this.currentLength = 0;
        }

        if (text_sms.indexOf('{\#lien_landing#}') != -1) {
            text_sms = text_sms.replace(/\{\#lien_landing\#\}/gi, '');
            len += parseInt(urlLengthMiniSite);
        }

        for (var i = 0, l = text_sms.length; i < l; i++) {
            var specialChar = this.utf8ToByte(text_sms[i]);
            if ($.inArray(specialChar, this.twoBytesChar) > -1) {
                charLength = 2;
            } else {
                charLength = 1;
            }
            len = len + charLength;
        }
        this.currentLength = len + stopLength;
    },

    utf8ToByte: function (str) {
        var byteArray = '';
        for (var i = 0; i < str.length; i++)
            if (str.charCodeAt(i) <= 0x7F)
                byteArray = Number(str.charCodeAt(i)).toString(16).toUpperCase();
            else {
                var h = encodeURIComponent(str.charAt(i)).substr(1).split('%');
                for (var j = 0; j < h.length; j++)
                    byteArray += h[j];
            }
        return byteArray;
    },

    countNbSms: function (len, champs) {
        var nbSms = 0;
        switch (true) {
            case len < 0:
                nbSms = 0;
                break;
            case len >= 0 && len < this.lengthSms[1]:
                nbSms = 1;
                break;
            case len >= this.lengthSms[1] && len < this.lengthSms[2]:
                nbSms = 2;
                break;
            case len >= this.lengthSms[2] && len < this.lengthSms[3]:
                nbSms = 3;
                break;
            case len >= this.lengthSms[3] && len < this.lengthSms[4]:
                nbSms = 4;
                break;
            case len >= this.lengthSms[4] && len < this.lengthSms[5]:
                nbSms = 5;
                break;
            case len >= this.lengthSms[5] && len < this.lengthSms[6]:
                nbSms = 6;
                break;
            case len >= this.lengthSms[6]:
                nbSms = 7;
                break;
        }

        if (nbSms > this.maxNbSms) {
            $.alert({
                title: 'Erreur:',
                content: 'Un message ne peut dépasser la taille de ' + this.maxNbSms + ' sms, il sera tronqué.',
                type: 'red',
            });
            var sms_text = champs.val();
            champs.val(sms_text.substring(0, this.length6Sms - 1));
            this.updateLength('#charNum', -1);
        } else {
            $('#nbreSms').text(nbSms);
            $('#totalChar').text(this.lengthSms[nbSms] - 1);
            $('#campagne_nbSms').val(nbSms);
        }
        this.calcPrixCampagne(nbSms);
    },

    updateLength: function (id, len) {
        if (typeof len === 'undefined') {
            len = 0;
        }
        $(id).text(this.currentLength + len);
        this.currentLength += len;
    },

    insertField: function () {
        $(document).on('click', '.data-field', function (e) {
            var sms_text = $('#campagne_donnee_sms_message');
            var caretPos = sms_text[0].selectionStart;
            var textAreaTxt = sms_text.val();
            var txtToAdd = '{#' + $(this).data('value') + '#} ';
            sms_text.val(textAreaTxt.substring(0, caretPos) + txtToAdd + textAreaTxt.substring(caretPos));
            sms_text.trigger('input');
        })
    },

    ajaxSendSmsTest: function () {
        $("button[name='campagne[donnee_sms][sendSmsTest]']").on("click", function (e) {
            e.preventDefault();
            var url = $(this).data('route');

            var regex = new RegExp('.*(campagne_edit\\/)([0-9]+)');
            var currentUrl = window.location.href;

            var find = regex.exec(currentUrl);
            var campagneId = '';

            if (find != null) {
                campagneId = find[2];
            }

            $.ajax({
                url: url,
                data: {
                    phone_test_number: $("#campagne_donnee_sms_testSmsNumber").val(),
                    message: $('#campagne_donnee_sms_message').val(),
                    id_liste_contacts: $("#campagne_listeContacts").find(":selected").val(),
                    emetteur_hidden: $("#campagne_donnee_sms_emetteur_hidden").val(),
                    emetteur: $("#campagne_donnee_sms_emetteur").val(),
                    nb_Sms: $("#campagne_nbSms").val(),
                    campagne_id: campagneId,
                    modele_minisite_id: $('#modeleMiniSiteId').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête d\'envoi',
                        type: 'red',
                    });
                },
                success: function (data) {
                    if (data.filter_phone_number != undefined) {
                        $.alert({
                            title: 'Information:',
                            content: data.html + data.filter_phone_number,
                            type: 'blue',
                        });
                        $("#campagne_donnee_sms_testSmsNumber").val(data.filter_phone_number);
                    } else {
                        $.alert({
                            title: 'Erreur:',
                            content: data.html,
                            type: 'red',
                        });
                    }

                },
            });

        });

    },

    checkSmsSender: function () {
        $("#campagne_donnee_sms_emetteur").on('focusout', function (e) {
            e.preventDefault();
            var sender = $("#campagne_donnee_sms_emetteur_hidden").val();

            if ($(this).val() == '') {
                $(this).val(sender);
            }

            var senderRegex1 = new RegExp("^[a-zA-Z0-9\-\\s]{3,11}$");
            var senderRegex2 = new RegExp("^[0-9]{3,11}$");
            if (senderRegex1.test($(this).val()) == false || (senderRegex2.test($(this).val()) == true && sender != $(this).val())) {
                $.alert({
                    title: 'Erreur:',
                    content: 'Erreur: L\'émetteur doit etre compris entre 3 et 11 caractères.\n' +
                        'Seules les lettres minuscules et majuscules sans accent et les chiffres sont acceptés.\n' +
                        'Aucun caractères spéciaux, ni uniquement des chiffres. ',
                    type: 'red',
                });
                $(this).val(sender);
            } else {
                $('#emetteur_recap').text($(this).val());
            }
        });
    },

    displayAlertSmsType: function () {
        $('#campagne_donnee_sms_type').on('change', function (e) {
            e.preventDefault();
            if ($(this).val() == 'transactionnel') {
                $.alert({
                    title: 'Erreur:',
                    content: 'Attention, vous allez envoyer une campagne de type transactionnel (Confirmation de commande, rendez vous, message d‘alerte...).\n' +
                        '\n' +
                        'Il est strictement INTERDIT d‘utiliser une campagne transactionnelle pour un usage marketing: de fortes amendes peuvent sanctionner ces abus et votre compte serait immédiatement suspendu.',
                    type: 'red',
                });
            }
        })
    },

    disableDatePlanifEnvoi: function () {
        $('#campagne_nowPlanifEnvoi').on('input', function (e) {
            e.preventDefault();
            if ($(this).is(':checked')) {
                $("#campagne_datePlanifEnvoi").attr("disabled", "disabled");
                $.datepicker._clearDate($("#campagne_datePlanifEnvoi"));
            } else {
                $("#campagne_datePlanifEnvoi").removeAttr("disabled");
            }
        })
    },

    ajaxSmsRecapitulatif: function () {
        $("button[name='recapitulatif']").click(function (e) {
            e.preventDefault();

            if ($('#checkTelephoneManuel').length) {
                $('#campagne_donneesManuelles').trigger('blur');
                $('#checkTelephoneManuel').remove();
            }

            var compteCampagne = $(".compte_campagne_select").find(":selected").val();
            if (!compteCampagne.length) {
                $.alert({
                    title: 'Erreur:',
                    content: 'Vous n\'avez pas sélectionné un point de vente',
                    type: 'red',
                });
            } else {
                var form = $("#nouvelleCampagneSms-Form")[0];
                var url = $(this).data('route');

                $.ajax({
                    url: url,
                    data: new FormData(form),
                    type: "post",
                    contentType: false,
                    processData: false,
                    cache: false,
                    dataType: "json",
                    error: function (err) {
                        var responseMessage = JSON.parse(err.responseText);
                        $.alert({
                            title: 'Erreur:',
                            content: 'Erreur dans le traitement de la campagne:\n' + responseMessage.message,
                            type: 'red',
                        });
                    },
                    success: function (data) {
                        // $("#recapitulatif").html(data.html);
                        $.fancybox.open({
                            src: data.html,
                            type: 'html',
                            afterClose: function () {
                                if ($('#modeleMiniSiteId').val() != '0') {
                                    if ($('#campagne_donnee_sms_message').val().indexOf('{\#lien_landing#}') == -1) {
                                        $('#campagne_donnee_sms_message').val($('#campagne_donnee_sms_message').val() + '{\#lien_landing\#\}');
                                    }
                                }
                                $('#campagne_typeEnvoi').val('');

                            }
                        })
                    },
                });
            }


        });
    },

    ajaxSmsRecapitulatifTechnique: function () {
        $("button[name='recapitulatifTechnique']").click(function (e) {
            e.preventDefault();

            if ($('#checkTelephoneManuel').length) {
                $('#campagne_donneesManuelles').trigger('blur');
                $('#checkTelephoneManuel').remove();
            }

                var form = $("#nouvelleCampagneSms-Form")[0];
                var url = $(this).data('route');

                $.ajax({
                    url: url,
                    data: new FormData(form),
                    type: "post",
                    contentType: false,
                    processData: false,
                    cache: false,
                    dataType: "json",
                    error: function (err) {
                        var responseMessage = JSON.parse(err.responseText);
                        $.alert({
                            title: 'Erreur:',
                            content: 'Erreur dans le traitement de la campagne:\n' + responseMessage.message,
                            type: 'red',
                        });
                    },
                    success: function (data) {
                        // $("#recapitulatif").html(data.html);
                        $.fancybox.open({
                            src: data.html,
                            type: 'html',
                            afterClose: function () {
                                if ($('#modeleMiniSiteId').val() != '0') {
                                    if ($('#campagne_donnee_sms_message').val().indexOf('{\#lien_landing#}') == -1) {
                                        $('#campagne_donnee_sms_message').val($('#campagne_donnee_sms_message').val() + '{\#lien_landing\#\}');
                                    }
                                }
                                $('#campagne_typeEnvoi').val('');

                            }
                        })
                    },
                });
            }
        )
    },

    choixSmsCampagne: function () {
        $("#campagne-sms").on("click", function (e) {
            e.preventDefault();
            var compteCampagne = $(".compte_campagne_select").find(":selected").val();
            if (!compteCampagne.length) {
                $.alert({
                    title: 'Erreur:',
                    content: 'Veuillez d\'abord sélectionner un point de vente',
                    type: 'red',
                });
            } else {
                $.fancybox.open({
                    src: "#newCampagneSMSChoose"
                });
                // var link = $(this).prop('href');
                // $(this).prop('href', link + '/' + compteCampagne);
                // window.location.replace($(this).prop('href'));
            }
        })
    },

    setCompteCampagneSession: function () {
        $(".compte_campagne_select").on("change", function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            $.ajax({
                url: url,
                data: { compte_campagne_id: $(this).find(":selected").val() },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                beforeSend: function () {
                    $('body').append('<div class="loader"></div>');
                    $(".loader").show();
                },
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête du point de vente',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                                action: function () {
                                    // window.location.reload();
                                    if (INTERFACE_FRONT.envMode == "dev") {
                                        urlTdb = "/app_dev.php";
                                    } else {
                                        urlTdb = "/";
                                    }
                                    window.location.replace(urlTdb);
                                }
                            }
                        }
                    });
                },
                success: function (data) {
                    $('#prix_sms_recap').text(data.prix_sms);
                    // location.reload();
                    if (INTERFACE_FRONT.envMode == "dev") {
                        urlTdb = "/app_dev.php";
                    } else {
                        urlTdb = "/";
                    }
                    window.location.replace(urlTdb);
                }
            });
        })
    },

    calcPrixCampagne: function (nbSms) {
        var nbContact = parseInt($('#destinataire_recap').text());
        var typeCompte = parseInt($('#type_compte').text());
        var typeCampagne = parseInt($('#type_campagne').text());
        var pxSmsUnit = parseFloat($('#prix_sms_recap').text());
        var nbCreditUnit = parseFloat($('#nb_credit').text());
        if (typeCompte == 1) { // illimité
            if (typeCampagne == 1) { /* Campagne SMS */
                var price = (nbContact * pxSmsUnit * nbSms);
            } else if (typeCampagne == 2) { /* Campagne VMS */
                var price = (nbContact * pxSmsUnit);
            }
            var myPrix = new Intl.NumberFormat("fr-FR", { minimumFractionDigits: 2 }).format(price);
            $('#prix_total_recap').text(myPrix);
            $('#ico_px_total').attr("class", 'ml-2 fas fa-euro-sign');
        } else {

            if (typeCampagne == 1) { /* Campagne SMS */
                var credits = (nbContact * nbCreditUnit * nbSms);
            } else if (typeCampagne == 2) { /* Campagne VMS */
                var credits = (nbContact * nbCreditUnit);
            }

            $("#prix_total_recap").text(Intl.NumberFormat("fr-FR").format(credits));
            $('#ico_px_total').attr("class", 'ml-2 fas fa-coins');
        }
    },

    updateNbreContact: function () {
        var that = this;
        $('#campagne_listeContacts').on('change', function (e) {
            e.preventDefault();
            $("#programVagues").html("");
            $("#nb_vague").text("1");
            $("#nb_vague_planif").text("0");
            $("#sauveCampagne").html("Choisissez une option d'envoi");
            var listeContact = $("#campagne_listeContacts").find(":selected").data('nbre-contacts-sms');
            var idListeContact = $("#campagne_listeContacts").find(":selected").val();
            $.when(that._filtreListeNoire(idListeContact)).then(
                function (data) {
                    if (data.data == "") {
                        $('#campagne_nbContactsRepertoire').val('');
                        $('#campagne_nbContactsBlackListRepertoire').val('');
                    } else {
                        if (data.data.not_blacklist.length) {
                            $('#campagne_nbContactsRepertoire').val(data.data.not_blacklist.length);
                        } else {
                            $('#campagne_nbContactsRepertoire').val(listeContactcampagne_donnee_sms_message);
                        }

                        if (data.data.blacklist.length) {
                            $('#campagne_nbContactsBlackListRepertoire').val(data.data.blacklist.length);
                        } else {
                            $('#campagne_nbContactsBlackListRepertoire').val(0);
                        }
                    }

                    $('#campagne_nbContactsRepertoire').trigger('change');
                    $('#campagne_nbContactsBlackListRepertoire').trigger('change');
                }
            );
        })
    },

    initNbreContact: function () {
        var listeContact = $("#campagne_listeContacts").find(":selected").data('nbre-contacts-sms');
        var idListeContact = $("#campagne_listeContacts").find(":selected").val();
        $.when(this._filtreListeNoire(idListeContact)).then(
            function (data) {
                if (data.data != undefined) {
                    if (data.data == "") {
                        $('#campagne_nbContactsRepertoire').val('');
                        $('#campagne_nbContactsBlackListRepertoire').val('');
                    } else {
                        if (data.data.not_blacklist.length) {
                            $('#campagne_nbContactsRepertoire').val(data.data.not_blacklist.length);
                        } else {
                            $('#campagne_nbContactsRepertoire').val(listeContact);
                        }

                        if (data.data.blacklist.length) {
                            $('#campagne_nbContactsBlackListRepertoire').val(data.data.blacklist.length);
                        } else {
                            $('#campagne_nbContactsBlackListRepertoire').val(0);
                        }
                    }

                    $('#campagne_nbContactsRepertoire').trigger('change');
                    $('#campagne_nbContactsBlackListRepertoire').trigger('change');
                }
            }
        );
    },

    checkTelephoneManuel: function () {
        $('#campagne_donneesManuelles').on('blur', function (e) {
            e.preventDefault();

            //clic sur récap
            if (e.relatedTarget && $(e.relatedTarget).is("button[name='recapitulatif']")) {
                $(this).append('<input type="hidden" id="checkTelephoneManuel" value="1">')
                return;
            }

            // clic sur ajouter répertoire
            if (e.relatedTarget && $(e.relatedTarget).is("label")) {
                if ($(e.relatedTarget).find("input[id='campagne_ajouterRepertoire']")) {
                    $('#campagne_ajouterRepertoire').trigger('click');
                }
            }

            var url = $(this).data('route');
            $.ajax({
                url: url,
                data: { phone_numbers: $(this).val(), id_rep: $("#campagne_listeContacts").find(":selected").val() },
                type: "post",
                cache: false,
                dataType: "json",
                // error: function (err) {
                //     alert('Erreur dans la requête');
                // },
                success: function (data) {
                    if (data.html != '' || data.nbContactsBlackList || data.nbDuplicate || data.nbExcludePhone || data.nbExcludeEmail) {
                        $('#campagne_donneesManuelles').val(data.html);
                        $('#campagne_nbContactsManuel').val(data.count);

                        if (data.nbDuplicate != 0 || data.nbExcludePhone != 0 || data.nbExcludeEmail != 0 || data.nbContactsBlackList != 0) {
                            if (data.nbDuplicate != 0) {
                                $('#campagne_nbDuplicate > span').text(data.nbDuplicate);
                                $('#campagne_nbDuplicate').show();
                            } else {
                                $('#campagne_nbDuplicate').hide();
                            }

                            if (data.nbExcludeEmail != 0) {
                                $('#campagne_nbExcludeEmail > span').text(data.nbExcludeEmail);
                                $('#campagne_nbExcludeEmail').show();
                            } else {
                                $('#campagne_nbExcludeEmail').hide();
                            }
                            if (data.nbExcludePhone != 0) {
                                $('#campagne_nbExcludePhone > span').text(data.nbExcludePhone);
                                $('#campagne_nbExcludePhone').show();
                            } else {
                                $('#campagne_nbExcludePhone').hide();
                            }

                            if (data.nbContactsBlackList != 0) {
                                $('#campagne_nbContactsBlackListManuel').val(data.nbContactsBlackList);
                                $('#campagne_nbContactsBlackList').show();
                            } else {
                                $('#campagne_nbContactsBlackList').hide();
                            }

                            $('.alert').addClass('show');
                        }
                    } else {
                        $('#campagne_nbContactsBlackListManuel').val(0);
                        $('#campagne_nbContactsManuel').val(0);
                        $('#campagne_nbDuplicate').hide();
                        $('#campagne_nbExcludeEmail').hide();
                        $('#campagne_nbExcludePhone').hide();
                    }

                    $('#campagne_nbContactsManuel').trigger('change');
                    $('#campagne_nbContactsBlackListManuel').trigger('change');
                },
                statusCode: {
                    400: function () {
                        $.alert({
                            title: 'Erreur:',
                            content: 'Pas de numéro valable',
                            type: 'red',
                        });
                        $('#campagne_donneesManuelles').val('');
                    }
                }
            });
        })
    },

    activeSaveTelephoneManuel: function () {
        $('#campagne_ajouterRepertoire,#campagne_creerRepertoire').on('click', function () {
            if ($('#campagne_ajouterRepertoire').is(':checked') || $('#campagne_creerRepertoire').is(':checked')) {
                $('#sauveRepertoire').removeClass('disabled');
            } else {
                $('#sauveRepertoire').addClass('disabled');
            }
        })
    },

    saveTelephoneManuel: function () {
        $('#sauveRepertoire').on('click', function (e) {
            e.preventDefault();

            if ($('#campagne_ajouterRepertoire').is(':checked') && $("#campagne_listeContacts").find(":selected").data('nbre-contacts-sms') == 0) {
                $.alert({
                    title: 'Erreur:',
                    content: 'Veuillez sélectionner un répertoire',
                    type: 'red',
                });
                return false;
            }

            if ($('#campagne_creerRepertoire').is(':checked') && $('#campagne_nomCreerRepertoire').val() == '') {
                $.alert({
                    title: 'Erreur:',
                    content: 'Veuillez entrer un nom de répertoire valide',
                    type: 'red',
                });
                return false;
            }

            var url = $(this).data('route');

            $.ajax({
                url: url,
                data: {
                    phone_numbers: $('#campagne_donneesManuelles').val(),
                    ajout_rep: $('#campagne_ajouterRepertoire').is(':checked'),
                    id_rep: $("#campagne_listeContacts").find(":selected").val(),
                    creer_rep: $('#campagne_creerRepertoire').is(':checked'),
                    nom_rep: $('#campagne_nomCreerRepertoire').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête de sauvegarde des entrées manuelles',
                        type: 'red',
                    });
                },
                success: function (data) {
                    $('#campagne_donneesManuelles').val(data.html);
                    $('#campagne_nbContactsManuel').val(data.count);
                    $('#campagne_nbContactsManuel').trigger('change');
                    $('#campagne_nbContactsBlackListManuel').trigger('change');
                    location.reload();
                }
            });

        })
    },

    saveModeleCampagne: function () {

        $(document).on('click', "#sauveModeleCampagne,.sauve-modele,#updateModeleCampagne", function () {
            var url = $(this).data('src');
            var campagneId = $(this).data('campagne-id');
            var modeleId = $(this).data('modele-id');
            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    'nom': $('#campagne_nom').val(),
                    'emetteur': $('#campagne_donnee_sms_emetteur').val(),
                    'message': $('#campagne_donnee_sms_message').val(),
                    'campagneId': campagneId,
                    'modeleId': modeleId
                },
                success: function (data) {
                    $.fancybox.open({
                        src: data,
                        type: 'html'
                    })
                },
                beforeSend: function (jqXHR, setting) {
                    if ($('#campagne_donnee_sms_message').val() == '') {
                        $.alert({
                            title: 'Erreur:',
                            content: 'Le message est vide !!!',
                            type: 'red',
                        });
                        jqXHR.abort();
                    }
                }
            });
            return false;
        });
    },

    saveModeleMiniSite: function () {
        $(document).on('click', '.sauve-modele-miniSite', function () {
            var url = $(this).data('route');
            var campagneId = $(this).data('campagne-id');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    'campagneId': campagneId,
                },
                success: function (data) {
                    $.fancybox.open({
                        src: data,
                        type: 'html'
                    })
                }
            });
            return false;
        });
    },

    afficheModeleCampagne: function () {

        $("#chargeModeleCampagne").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
            }
        );
    },

    afficheChoixModeleMiniSite: function () {

        $("#chargeChoixMiniSite").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
            }
        );
    },

    afficheApercuCampagne: function () {

        $(".chargeApercuCampagne").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
            }
        );
    },

    actionBrouillon: function () {
        $(document).on('click', '#nePasSauverBrouillon,#sauverBrouillon', function (e) {
            var url = $(this).data('route');
            var redirect = $(this).data('redirect');

            if (url == 'sav_modif') {
                $('form[name="campagne"],form[name="donnee_mini_site"],form[name="profilia_demande_prospect"]').append('<input type="hidden" name="brouillon" value="1"><input' +
                    ' type="hidden" name="edited" value="1"><input type="hidden" name="redirect"' +
                    ' value="' + redirect + '">').submit();
            } else {
                window.location.replace(url);
            }
        })
    },

    afficheChampsVariables: function () {
        var that = this;
        $("#campagne_listeContacts").on('change', function (e) {
            /* on test si c'est pas undefined car utilisé aussi pour les vms */
            if (typeof $(this).data('route') !== "undefined") {
                that._afficheChampsVariables($(this));
            }

            // var replaceString = $('#campagne_donnee_sms_message').val().replace(new RegExp('\\[[a-zA-Z0-9_\\s\\-]+\\]', 'g'), '');
            // $('#campagne_donnee_sms_message').val(replaceString.trim());
        });
    },

    _afficheChampsVariables: function (input) {
        var url = input.data('route');
        var that = this;
        $.ajax({
            url: url,
            data: {
                'repertoire': input.find(":selected").val(),
                'message': $('#campagne_donnee_sms_message').val(),
            },
            type: "post",
            cache: false,
            dataType: "json",
            error: function (err) {
                $.alert({
                    title: 'Erreur:',
                    content: 'Erreur dans la requête de l\'affichage des champs variables',
                    type: 'red',
                });
            },
            success: function (data) {
                $('#champs_variables').html(data.html);
                $('#campagne_donnee_sms_message').val(data.message_sms);
                that.countChar($('#campagne_donnee_sms_message'), that.twig.stopLengthSms);
                that.countNbSms(that.currentLength, $('#campagne_donnee_sms_message'));
            }
        });
    },

    _filtreListeNoire: function (datasToFilter) {
        var url = $('#campagne_filtrageListenoire').data('route');
        return $.ajax({
            url: url,
            data: { 'datasToFilter': datasToFilter },
            type: "post",
            cache: false,
            dataType: "json",
            error: function (err) {
                $.alert({
                    title: 'Erreur:',
                    content: 'Erreur dans la requête de filtrage de la liste noire',
                    type: 'red',
                });
            },
            success: function (data) {
            }
        });
    },

    updateNbreContacts: function () {
        var that = this;
        $('#campagne_nbContactsRepertoire,#campagne_nbContactsManuel').on('change', function () {
            that._compteNbreContactsManuel();
            var nbContactsRep = $('#campagne_nbContactsRepertoire').val() != '' ? $('#campagne_nbContactsRepertoire').val() : 0;
            var nbContatcsManuel = $('#campagne_nbContactsManuel').val() != '' ? $('#campagne_nbContactsManuel').val() : 0;
            $('#destinataire_recap').text(parseInt(nbContactsRep) + parseInt(nbContatcsManuel));
            that.calcPrixCampagne(parseInt($('#nbreSms').text()));
        });
    },

    updateNbreListeNoireContacts: function () {
        $('#campagne_nbContactsBlackListRepertoire,#campagne_nbContactsBlackListManuel').on('change', function () {
            var nbContactsBlackListRep = $('#campagne_nbContactsBlackListRepertoire').val() != '' ? $('#campagne_nbContactsBlackListRepertoire').val() : 0;
            var nbContatcsBlackListManuel = $('#campagne_nbContactsBlackListManuel').val() != '' ? $('#campagne_nbContactsBlackListManuel').val() : 0;
            var total = parseInt(nbContactsBlackListRep) + parseInt(nbContatcsBlackListManuel);

            if (total > 0) {
                $('#campagne_nbContactsBlackList').show();
                $('.alert').addClass('show');
                $('.alert').removeClass('hide');
            } else {
                $('#campagne_nbContactsBlackList').hide();

                //s'il y a déjà une erreur affichée, on ne cache pas le conteneur
                var erreur = 0;
                $('.alert > ul').children().each(function () {
                    if ($(this).css('display') != 'none') {
                        erreur++;
                    }
                });

                if (erreur > 0) {
                    $('.alert').addClass('show');
                    $('.alert').removeClass('hide');
                } else {
                    $('.alert').addClass('hide');
                    $('.alert').removeClass('show');
                }
            }

            $('#campagne_nbContactsBlackList > span').text(parseInt(nbContactsBlackListRep) + parseInt(nbContatcsBlackListManuel));

        });
    },

    sauveCampagneSMS: function () {
        $(document).on('click', '#sauveCampagne', function (e) {
            e.preventDefault();
            $("#nouvelleCampagneSms-Form").submit();
            $("#nouvelleCampagneVms-Form").submit();
            $("#editCampagneVms-Form").submit();
        })
    },

    sauveBrouillonCampagneSMS: function () {
        $(document).on('click', '#sauveBrouillon', function (e) {
            e.preventDefault();
            $("#nouvelleCampagneSms-Form").append('<input type="hidden" name="brouillon" value="1">').submit();
            $("#nouvelleCampagneVms-Form").append('<input type="hidden" name="brouillon" value="1">').submit();
        })
    },

    actionCampagne: function () {
        $(document).on('click', '.campagne_action', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var that = $(this);
            var ret;

            var action = function () {
                $.ajax({
                    type: "GET",
                    cache: false,
                    url: url,
                    success: function (data) {

                        if (data.action == 'play') {
                            var new_url = url.replace('play', 'pause');
                            that.html('pause');
                        }
                        if (data.action == 'pause') {
                            var new_url = url.replace('pause', 'play');
                            that.html('play');
                        }

                        window.location.reload();
                        that.data('route', new_url);
                    },
                    statusCode: {
                        400: function (data) {
                            $.alert({
                                title: 'Erreur:',
                                content: data.responseJSON.content,
                                type: 'red',
                            });
                        }
                    }
                });
            }

            if (url.indexOf('stop') >= 0) {
                $.confirm({
                    title: 'Information',
                    content: 'Attention !! l\'arrêt de la campagne est definitif.',
                    buttons: {
                        ok: function () {
                            action();
                        },
                        annuler: function () {
                            ret = false;
                        },
                    },
                    type: 'blue',
                });

                if (!ret) {
                    return false;
                }
            } else {
                action();
            }

        })
    },

    _compteNbreContactsManuel: function () {
        var text = $("#campagne_donneesManuelles").val();
        if (text != '') {
            var lines = text.split(/\r|\r\n|\n/);
            var count = lines.length;
            $('#campagne_nbContactsManuel').val(count);
        } else {
            $('#campagne_nbContactsManuel').val(0);
        }

    },

    initSelect2: function () {
        $.fn.select2.defaults.set('language', 'fr');
        $('#compte_campagne_select').select2({
            multiple: false,
            escapeMarkup: function (markup) {
                return markup;
            },
        }
        );
        $('#campagne_listeContact').select2({
            multiple: false,
            escapeMarkup: function (markup) {
                return markup;
            },
        }
        );

        $('#utilisateur_admin_comptePrincipal').select2({
            multiple: false,
            escapeMarkup: function (markup) {
                return markup;
            },
        }
        );

        $('#utilisateur_admin_comptesCampagne').select2({
            multiple: false,
            escapeMarkup: function (markup) {
                return markup;
            },
        }
        );

        $('.initSelect2Multi').select2({
            multiple: true,
            placeholder: "Sélectionnez une ou plusieurs forme juridique",
        }
        );
    },

    confirmBeforeLeaving: function () {
        $('.header a,#creerRepertoire, .btnFoot,.confirm-before-leave').not('a[href*="accountNavBar"]').on('click', function (e) {
            if ($("input[name*='isSave']").val() == 1) {
                return;
            }
            e.preventDefault();

            var url = $(this).attr('href');

            if (typeof url !== typeof undefined && url !== false && url.indexOf('false') == -1) {
                link = $(this).attr('href');
            } else if ($(this).hasClass('form-group')) {
                if (INTERFACE_FRONT.envMode == "dev") {
                    link = "/app_dev.php";
                } else {
                    link = "/";
                }
            } else {
                link = $(this).data('route');
            }

            var routeSave = $('#sauverBrouillon').data('route');

            $('#nePasSauverBrouillon').replaceWith('<button id="nePasSauverBrouillon" data-route="' + link + '" type="button" class="btn' +
                ' btn-danger"><i class="mr-2 "></i>Ne pas enregistrer</button>');
            $('#sauverBrouillon').replaceWith('<button id="sauverBrouillon" data-route="' + routeSave + '" data-redirect="' + link + '" type="button" class="btn btn-success"><i class="mr-2 "></i>Enregistrer</button>');
            $.fancybox.open({
                src: "#choixCreerRepertoire"
            });
        });
    },

    resetIsSave: function () {
        $('input').not('.header a,.header .form-group,#creerRepertoire,.confirm-before-leave').on('click', function (e) {
            $("input[name*='isSave']").val(0);
        });
    },

    comptePrincipalChooser: function () {
        $('#utilisateur_admin_comptePrincipal').on('change', function () {
            var cptePrincSelector = $(this);
            $.ajax({
                url: $(this).data('route'),
                type: "GET",
                dataType: "JSON",
                data: {
                    cptePrincId: cptePrincSelector.val()
                },
                success: function (comptesCampagne) {
                    var comptesCampagneSelect = $("#utilisateur_admin_comptesCampagne");
                    comptesCampagneSelect.html('');
                    comptesCampagneSelect.append('<option value>Sélectionnez un compte principal</option>');
                    $.each(comptesCampagne, function (key, compteCampagne) {
                        comptesCampagneSelect.append('<option value="' + compteCampagne.id + '">' + compteCampagne.name + '</option>');
                    });
                    if (comptesCampagne.length == 0) {
                        window.location.reload();
                    }
                },
                complete: function () {
                    if (cptePrincSelector.val() == '') {
                        window.location.reload();
                    }
                },
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: "Une erreur est survenue ...",
                        type: 'red',
                    });
                }
            });
        });
    },

    statistiquesExportGlobal: function () {
        $('#statistiques_export').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var filterType = $(this).data('filter-type');
            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "minDate": $('#min-date').datepicker({ dateFormat: 'dd/mm/yy' }).val(),
                    "maxDate": $('#max-date').datepicker({ dateFormat: 'dd/mm/yy' }).val(),
                    "filterType": filterType,
                },
                success: function (data) {
                    window.location.replace(data);
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
            return false;
        });
    },

    updateSmsInteractifChamps: function () {
        $('.mini_site').each(function () {
            $(this).on('input', function () {
                var target = $(this).data('target');
                var value = $(this).val() != '' ? $(this).val() : ($(this).hasClass('no_placeholder') ? '' : $(this).attr('placeholder'));
                $(target).html(value);
                if (value != '') {
                    $(target).show();
                } else {
                    $(target).hide();
                }
            })
        });
    },

    addReponseformulaire: function () {
        $('input[id^="donnee_mini_site_reponse"]').on('input', function () {
            var target = $(this).data('target');
            var value = $(this).val();
            if (value != '') {
                $(target).show();
            } else {
                $(target).hide();
            }
        });
    },

    toggleButton: function () {
        $('.switch').on('click', function () {
            if ($(this).hasClass('disabled')) {
                return;
            }

            $(this).removeClass('btn-light').addClass('btn-primary');
            $(this).siblings().not('[type="hidden"]').removeClass('btn-primary').addClass('btn-light');
            var targets = $(this).parent().data('target');
            var multiple = false;
            if (targets.split(',').length == 2) {
                var targetArr = targets.split(',');
                var multiple = true;
            }
            var hidden = $(this).parent().data('hidden');
            var val = $(this).children("input[type='radio']").val();

            switch (val) {
                case '0':
                    if (multiple) {
                        $(targetArr[0]).show();
                        $(targetArr[1]).hide();
                    } else {
                        $(targets).invisible();
                    }
                    break;
                case '1':
                    if (multiple) {
                        $(targetArr[0]).hide();
                        $(targetArr[1]).show();
                    } else {
                        $(targets).visible();
                    }
                    break;
                case '2':
                    var div1 = ($('#adresse')).detach();
                    ($('#logo')).parent().append(div1);
                    $('#logo').removeClass('text-right').addClass('text-left');
                    $('#adresse').removeClass('text-left').addClass('text-right');
                    break;
                case '3':
                    var div2 = ($('#logo')).detach();
                    ($('#adresse')).parent().append(div2);
                    $('#logo').removeClass('text-left').addClass('text-right');
                    $('#adresse').removeClass('text-right').addClass('text-left');
                    break;
                case '4':
                    $('#collapseChoseLogo').hide();
                    break;
                default:
                    break;
            }

            $(hidden).val(val);
        })
    },

    toggleDisplay: function () {
        $('.toggle-display').on('click', function () {
            var target = $(this).data('target');
            var hidden = $(this).data('hidden');
            if ($(this).children().hasClass('fa-eye')) {
                $(target).hide();
                $(this).children().removeClass('fa-eye').addClass('fa-eye-slash');
                $(hidden).val(0);
                return false;
            }

            if ($(this).children().hasClass('fa-eye-slash')) {
                $(target).show();
                $(this).children().removeClass('fa-eye-slash').addClass('fa-eye');
                $(hidden).val(1);
                return false;
            }
        })
    },

    gestionReseauxSociaux: function () {
        $('.reseaux_sociaux').on('focusout', function () {
            var target = $(this).data('target');
            var value = $(this).val();


            if ($(this).attr('id').indexOf('Facebook') != -1) {
                var regex = RegExp('facebook\.com|fb\.me');
            }

            if ($(this).attr('id').indexOf('Youtube') != -1) {
                var regex = RegExp('youtube\.com|youtu\.be');
            }

            if ($(this).attr('id').indexOf('Twitter') != -1) {
                var regex = RegExp('twitter\.com|t\.co');
            }

            if ($(this).attr('id').indexOf('Instagram') != -1) {
                var regex = RegExp('instagr\.am|instagram\.com');
            }

            if ($(this).attr('id').indexOf('Itineraire') != -1) {
                var regex = RegExp('goo\.gl|google\.com|google\.fr');
            }

            if (value != '') {
                var resLink = regex.test(value);
                var res = value.match('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(.*)?$');
                if (res == null || resLink == false) {
                    $(this).val('');
                    $.alert({
                        title: 'Erreur:',
                        content: 'Adresse web erronée.',
                        type: 'red',
                    });
                    $(target).hide();
                } else {
                    $(target).show();
                    if (value.trim().toLowerCase().substr(0, 5) != 'https') {
                        var reg = new RegExp("([hH][tT][tT][pP])", "g");
                        value = value.replace(reg, 'https');
                    }
                }
                $(target).attr('href', value);
            } else {
                $(target).hide();
            }
        })
    },


    gestionSlider: function () {
        //si ne fonctionne plus passer par un MutationObserver
        $('.carousel').on('DOMSubtreeModified', function (e) {
            $(document).ready(function ($) {
                $('.carousel').carousel();
            });
        });
    },

    gestionEmailRetour: function () {
        $(document).on('focusout', '#emails_retour', function () {
            var that = this;
            var emailsRetour = $(this).val().split(';');
            if (emailsRetour.length) {
                if (emailsRetour.length > 4) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Nombre de retour limité à 4.',
                        type: 'red',
                    });
                    emailsRetour.splice(4);
                    $(that).val(emailsRetour);
                }

                emailsRetour.forEach(function (element) {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(element) == false) {
                        emailsRetour.pop();
                        $.alert({
                            title: 'Erreur:',
                            content: 'E-mail erroné !!!',
                            type: 'red',
                        });
                        $(that).val(emailsRetour.join(';'));
                    } else {
                        $('#campagne_emailsRetour').val(emailsRetour.join(';'));
                    }
                });
            }
        });
    },

    hidePreviewList: function () {
        $('#liste_contacts_fichier_file').on('change', function (e) {
            e.preventDefault();
            $('#apercu').hide();
        });
    },

    statistiquesFilterDate: function () {
        $('#statistiques_filter_date').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var filterType = $(this).data('filter-type');
            var minDate = $('#min-date').datepicker({ dateFormat: 'dd/mm/yy' }).val();
            var maxDate = $('#max-date').datepicker({ dateFormat: 'dd/mm/yy' }).val();

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "minDate": minDate,
                    "maxDate": maxDate,
                    "filterType": filterType,
                },
                success: function (data) {
                    $('#statistiques_report').html(data.html);
                    $('#statistiques_graph > .row').html(data.graph);
                    $('#global-chart').addClass('mx-auto');
                    $('#week-chart').hide();
                    $('#statistiques_campagnes').html(data.campagnes)

                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
            return false;

        })
    },

    choixDatePlanif: function () {
        $(document).on('click', '#planNow,#planLate', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var choixPlanif = $(this).data('value');
            $('#campagne_typeEnvoi').val('');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "choixPlanif": choixPlanif,
                },
                success: function (data) {
                    if (data.choixPlanif == 'now') {
                        $("#sauveCampagne").text("Envoyer maintenant");
                        $("#sauveCampagne").removeAttr("disabled");
                        $("#planText").text("Envoyer maintenant");
                        $('#planCampagne').removeClass('show');
                    }

                    if (data.choixPlanif == 'scheduled') {
                        $.fancybox.open({
                            src: data.html,
                            type: 'html',
                            opts: {}
                        });
                    }
                },
                statusCode: {
                    400: function (data) {
                        $("#sauveCampagne").text("Choisissez une option d'envoi");
                        $("#sauveCampagne").attr("disabled", true);
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });
    },

    checkDatePlanif: function () {
        $(document).on('click', '#planOk', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var datePlanif = $('#planDateInput').val();
            var idListeContacts = $('#campagne_listeContacts').val();

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "datePlanif": datePlanif,
                    "idListeContacts": idListeContacts,
                },
                success: function (data) {
                    if (data.errors.length === 0) {
                        $.fancybox.close();
                        $("#planText").text(data.datePlanif);
                        $("#sauveCampagne").text("Planifier");
                        $("#sauveCampagne").removeAttr("disabled");
                        $('#campagne_datePlanifEnvoi').val(data.datePlanif);
                        $('#campagne_vms_edit_datePlanifEnvoi').val(data.datePlanif);
                    } else {
                        var content = '';
                        for (var i = 0; i < data.errors.length; i++) {
                            content += '<div class="alert alert-danger alert-dismissible fade show " role="alert"><strong>Erreur:</strong>' + data.errors[i] + '<br></div>';
                        }

                        $('#planErreurs').html(content);
                    }
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.content,
                            type: 'red',
                        });
                    }
                }
            });
            return false;
        });
    },

    afficheDetailsCampagne: function () {
        $(document).on('click', '.campagne_page .list-group-item-action', function (e) {
            var url = $(this).data('route');
            var slug = $(this).data('slug');
            $(this).addClass('active').siblings().removeClass('active');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {},
                success: function (data) {
                    $('#nav-tabContent').html(data.html);
                    window.history.pushState({}, '', slug);
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.content,
                            type: 'red',
                        });
                    }
                }
            });
        })
    },

    afficheDetailsCampagneTechnique: function () {
        $(document).on('click', '.campagne_page .trTechniqueAction, .campagne_page .trCampagneAction', function (e) {
            var url = $(this).data('route');
            $(this).addClass('active').siblings().removeClass('active');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {},
                success: function (data) {
                    $('#nav-tabContent').html(data.html);
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.content,
                            type: 'red',
                        });
                    }
                }
            });
        })
    },

    afficheContactsCampagne: function () {
        $(document).on('click', '#detail_contacts_campagne', function (e) {
            var url = $(this).data('route');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {},
                success: function (data) {
                    $.fancybox.open({
                        src: data.html,
                        type: 'html'
                    });
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.content,
                            type: 'red',
                        });
                    }
                }
            });
        })
    },

    chercheCampagne: function () {
        $('#chercheTextCampagne').keyup(function (e) {
            if (e.keyCode == 13) {
                $('#chercheBoutonCampagne').trigger("click");
            }

            if ($(this).val()) {
                $('#effaceCherche').addClass('d-block');
                $('#effaceCherche').removeClass('d-none');
            } else {
                $('#effaceCherche').addClass('d-none');
                $('#effaceCherche').removeClass('d-block');
            }
        });

        $('#chercheBoutonCampagne').on('click', function (e) {
            e.preventDefault();
            var chercheTexte = $('#chercheTextCampagne').val();
            var url = $(this).data('route');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "chercheTexte": chercheTexte,
                },
                success: function (data) {
                    if (data.message == 'success') {
                        $('#list-tab').html(data.html);
                        if (data.html != '') {
                            $('#list-1').trigger('click');
                            $('#paginate').html(data.paginate);
                        } else {
                            $('#nav-tabContent').html(data.html);
                        }

                        //mise à jour des valeurs des statuts
                        if (data.statuses['finished']) {
                            $('#finished > span').text(data.statuses['finished'].length);
                        } else {
                            $('#finished > span').text(0);
                        }

                        if (data.statuses['canceled']) {
                            $('#canceled > span').text(data.statuses['canceled'].length);
                        } else {
                            $('#canceled > span').text(0);
                        }

                        if (data.statuses['pending']) {
                            $('#pending > span').text(data.statuses['pending'].length);
                        } else {
                            $('#pending > span').text(0);
                        }

                        if (data.statuses['no_job']) {
                            $('#draft > span').text(data.statuses['no_job'].length);
                        } else {
                            $('#draft > span').text(0);
                        }

                        $('[data-toggle="tooltip"]').tooltip();
                    }

                    if (data.message == 'no_result') {
                        location.reload();
                    }
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
            return false;
        });
    },

    filterCampagne: function () {
        $('.filter-search').on('click', function (e) {
            var url = $(this).data('route');

            if($(this).data('filter')!= undefined){
                $("#filtreVal").val($(this).data('filter'));
                $(".filter_val").removeClass("active");
                $("#"+$(this).data('filter')).addClass("active");
            }

            if($(this).data('type')!= undefined){
                $("#filtreTyp").val($(this).data('type'));
                $(".filter_typ").removeClass("btn-secondary").addClass("btn-dark");
                $("#"+$(this).data('type')).removeClass("btn-dark").addClass("btn-secondary");
            }

            var filterValue = $("#filtreVal").val();
            var filterType = $("#filtreTyp").val();

            $('#table_campagne').DataTable().clear().destroy();
            var listeCamp = $('#table_campagne').DataTable(
                {
                    order: [ 6, 'desc' ],
                    "pageLength" : 10,
                    "processing": true,
                    "serverSide": true,
                    "language": {
                        "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                    },
                    "ajax": "./display-datatable-campagne?filtreVal="+filterValue+"&filtreTyp="+filterType,
                    "createdRow": function (row, data, index) {
                        if(index == 0){
                            $(row).addClass('active show');
                        }
                        $(row).addClass('trCampagneAction');
                        $(row).attr('style','cursor:pointer');
                        $(row).attr('data-route',"./campagne-affiche-details/"+data[0]);
                        $(row).attr('id','list-'+data[1]);
                        $(row).attr('role','tab');

                    },
                    "columnDefs": [
                        {
                            "targets": [0,1],
                            "searchable": false,
                            "orderable": false,
                            "visible": false,
                        },{
                            "targets": [2],
                            "searchable": false,
                            "orderable": false,
                            "className": "text-center"
                        },{
                            "targets": [3],
                            width: "260px",
                        },
                        {
                            "targets": [6],
                            "searchable": false,
                            "orderable": false,
                            "className": "text-center"
                        },
                        {width:"100px", type: 'date-euro', targets: 5},

                    ],

                }
            );

        });
    },

    filterCampagneTechnique: function () {
        $('.filter-search-tech').on('click', function (e) {
            var url = $(this).data('route');

            if($(this).data('filter')!= undefined){
                $("#filtreVal").val($(this).data('filter'));
                $(".filter_val_tech").removeClass("active");
                $("#"+$(this).data('filter')).addClass("active");
            }

            if($(this).data('type')!= undefined){
                $("#filtreTyp").val($(this).data('type'));
                $(".filter_typ_tech").removeClass("btn-secondary").addClass("btn-dark");
                $("#"+$(this).data('type')).removeClass("btn-dark").addClass("btn-secondary");
            }

            var filterValue = $("#filtreVal").val();
            var filterType = $("#filtreTyp").val();
            var clientCamp = $("#clientCamp").val();

            $('#table_technique_campagne').DataTable().clear().destroy();
            var listeCamp = $('#table_technique_campagne').DataTable(
                {
                    order: [ 6, 'desc' ],
                    "pageLength" : 10,
                    "processing": true,
                    "serverSide": true,
                    "language": {
                        "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                    },
                    "ajax": "./display-datatable-campagne-technique?filtreVal="+filterValue+"&filtreTyp="+filterType+"&clientCamp="+clientCamp,
                    "createdRow": function (row, data, index) {
                        if(index == 0){
                            $(row).addClass('active show');
                        }
                        $(row).addClass('trTechniqueAction');
                        $(row).attr('style','cursor:pointer');
                        $(row).attr('data-route',"./campagne-affiche-details/"+data[0]);
                        $(row).attr('id','list-'+data[1]);
                        $(row).attr('role','tab');

                    },
                    "columnDefs": [
                        {
                            "targets": [1],
                            "searchable": false,
                            "orderable": false,
                            "visible": false,
                        },
                        {type: 'date-euro', targets: 6},

                    ],

                }
            );

        });
    },

    effaceCherche: function () {
        $('#effaceCherche').on('click', function (e) {
            $('#chercheTextCampagne').val('');
            location.reload();
        })
    },

    effaceDatesStatistique: function () {
        $('#recharge_statistique').on('click', function (e) {
            $('#min-date').val('');
            $('#max-date').val('');
            location.reload();
        })
    },

    paginateCampagne: function () {
        $(document).on('click', '.page.campagne_page .page-item', function (e) {
            e.preventDefault();
            var url = $(this).find('a').attr('href');

            if (url == '#') {
                return;
            }

            $.ajax({
                type: 'GET',
                url: url,
                cache: false,
                data: {},
                dataType: 'json',
                success: function (data) {
                    window.history.pushState({}, '', data.url);
                    $('#list-tab').html(data.html);
                    $('#list-1').trigger('click');
                    $('#paginate').html(data.paginate);

                    $('[data-toggle="tooltip"]').tooltip();

                }
            });
        })
    },

    activeCampagneDropDown: function () {
        $('.campagne_page .list-group-item-action .btn-group,#dropdownMenuLink,#dropdownEditButton').on('click', function (e) {
            var compteCampagne = $(".compte_campagne_select").find(":selected").val();
            var comptePrincipal = $('#utilisateur_admin_comptePrincipal').length ? $('#utilisateur_admin_comptePrincipal').find(':selected').val() : [1];

            if (!compteCampagne.length || !comptePrincipal.length) {
                INTERFACE_FRONT._alertChoixCompte();
                return false;
            }
            $(this).parents(':eq(2)').addClass('active').siblings().removeClass('active');
            $(this).parents(':eq(2)').trigger('click');
        })
    },

    limitLogos: function () {
        if ($('#nouveauPointDeVente-Form').length) {
            $('#compte_campagne_logos > div > a').on('click', function (e) {
                var nbLogos = $('#compte_campagne_logos_id').val().split(',');
                if (nbLogos.length == GLOBAL_PARAMETERS.limitLogosPdv) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Vous avez atteint le nombre maximale d\'images.',
                        type: 'red',
                    });
                    return false;
                }
            })
        }
    },

    _noWeekendsOrOtherDays: function (date) {
        var noSunday = function (date) {
            return [date.getDay() != 0, ''];
        };
        if (noSunday(date)[0]) {
            return INTERFACE_FRONT._getDisableDays(date);
        } else {
            return noSunday(date);
        }
    },

    _getDisableDays: function (date) {
        var m = date.getMonth();
        var d = date.getDate();
        var y = date.getFullYear();
        var disabledDays = moment().getFerieList(y);

        for (var i = 0; i < disabledDays.length; i++) {
            if ((m == disabledDays[i].date.format('M') - 1) && (d == disabledDays[i].date.format('D')) && (y == disabledDays[i].date.format('Y'))) {
                return [false];
            }
        }
        return [true];
    },

    updateThemeCouleur: function () {
        $("#donnee_mini_site_marque").on("change", function (e) {
            e.preventDefault();
            var url = $(this).closest('form').attr('action');
            $.ajax({
                url: url,
                data: { marque_id: $(this).find(":selected").val() },
                type: "post",
                cache: false,
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête de la marque',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                },
                success: function (html) {
                    $('#donnee_mini_site_themeCouleur').replaceWith($(html).find('#donnee_mini_site_themeCouleur'));
                    $('#collapseChoseLogo').replaceWith($(html).find('#collapseChoseLogo'));
                    $('#logo').replaceWith($(html).find('#logo'));
                    $('#previsu_style').replaceWith($(html).find('#previsu_style'));
                }
            });
        })
    },

    getMarqueFont: function () {
        $("#donnee_mini_site_marque").on("change", function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            $.ajax({
                url: url,
                data: { marque_id: $(this).find(":selected").val() },
                type: "post",
                cache: false,
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête de la marque',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                },
                success: function (data) {
                    $('body').find('#font_style').remove();
                    if (data.style != '') {
                        $('body').prepend(data.style);
                        $('#nouveauModeleMiniSite-Form > div').append('<input type="hidden"');
                    }
                }
            });
        })
    },

    applyThemeCouleur: function () {
        $(document).on('change', '#donnee_mini_site_themeCouleur label', function () {
            $('#contenu_formulaire').find('style').remove();
            var url = $('#donnee_mini_site_themeCouleur').data('route');

            $.ajax({
                url: url,
                data: { theme_id: $(this).find('input').val(), marque_id: $('#donnee_mini_site_marque').find(':selected').val() },
                type: "post",
                cache: false,
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête du thème couleur',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                },
                success: function (html) {
                    $('#contenu_formulaire').prepend(html);
                }
            });

        })
    },

    updateLogo: function () {
        $(document).on('click', '.logo_formulaire', function () {
            var target = $(this).closest('[data-target="#logo"]').data('target');
            //si pas de logo
            if ($(this).children('input').val() == "") {
                $(target).css("display", "none");
                $("#adresse").removeClass("text-left");
                $("#adresse").removeClass("text-right");
                $("#adresse").addClass("text-center");
                $("#adresse").addClass("w-100");
            } else {
                $(target).css("display", "block");
                $("#adresse").removeClass("text-center");
                $("#adresse").removeClass("w-100");
                var imgSrc = $(this).children('img').attr('src');
                var height = $(this).children('img').attr('height');
                var width = $(this).children('img').attr('width');

                $(target).empty();
                var html = '<img src="' + imgSrc + '" alt="logo" width="' + width + '" height="' + height + '">';
                $(target).html(html);
            }
        })
    },

    updateHeaderImage: function () {
        $(document).on('click', '.headers_formulaire', function () {
            var target = $(this).closest('[data-target="#header_perso"]').data('target');
            //si pas de header
            if ($(this).children('input').val() == "") {
                $(target).hide();
            } else {
                $(target).show();
                var imgSrc = $(this).children('img').attr('src');

                $(target).empty();
                var html = '<img class="img-fluid" src="' + imgSrc + '" alt="header image" >';
                $(target).html(html);
            }
        })
    },

    _alertChoixCompte: function () {
        $.alert({
            title: 'Choix du point de vente',
            content: 'Attention vous devez choisir un point de vente avant de continuer',
            type: 'red'
        });

        $("#selecteurPdvSelect2 > .select2-container").addClass("compte_campagne_select_blink");
        $("#selecteurPdvSelect2 > .select2-container > .selection").addClass("compte_campagne_select_blink");
        $("#selecteurPdvSelect2 > .select2-container > .selection > .select2-selection--single").addClass("compte_campagne_select_blink");

    },

    _alertCredit: function (urlCredit) {

        $.confirm({
            title: 'Vous n\'avez plus de crédit',
            type: 'red',
            content: 'Attention, pour envoyer des campagnes vous devez créditer votre compte.',
            buttons: {
                confirm: {
                    text: 'Acheter des crédits',
                    btnClass: 'btn-success',
                    action: function () {
                        window.location = urlCredit;
                    }
                },
                cancel: {
                    text: 'Retour'
                }
            }
        });

    },

    copyClipboard: function () {
        $(document).on('click', '.copy_clip', function (e) {
            e.preventDefault();
            var $temp = $("<input>");
            var copyText = $(this).parents(':eq(1)').find('input[type="text"]').val();

            $("body").append($temp);
            $temp.val(copyText).select();
            document.execCommand("copy");
            $temp.remove();

            $.alert({
                title: 'Information:',
                content: "Texte copié: <br>" + copyText,
                type: 'blue',
            });
        })
    },

    afficheModeleSmsInteractif: function () {

        $("#choisirModeleSmsInteractif").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
            }
        );
    },

    previsuEnLigne: function () {
        $(document).on('click', '.online_preview,#previewSmsInteractif,#qrcode', function (e) {
            e.preventDefault();
            var url = $('.minisite_url').val();
            var win = window.open(url, '_blank');
            win.focus();
        })
    },

    redirectAdminMiniSite: function () {
        $(document).on('click', '.url_admin_minisite', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var win = window.open(url, '_blank');
            win.focus();
        })
    },

    _initDatasMiniSite: function () {
        $('input[type="hidden"]').each(function () {
            var targetName = $(this);
            //init des radio button
            if ($(this).data('initName')) {
                var elementTargetName = 'input[name=' + targetName.data('initName') + ']';

                if ($(this).data('disable') == 1) {
                    $(elementTargetName).attr('disabled', true);
                    $(elementTargetName).each(function () {
                        $(this).parent().addClass('disabled');
                    });
                } else {
                    var elementTargetName = 'input[name=' + targetName.data('initName') + '][value=' + $(this).val() + ']';
                    $(elementTargetName).attr('checked', true);
                    $(elementTargetName).parent().addClass('btn-primary').removeClass('btn-light');
                    $(elementTargetName).parent().siblings().addClass('btn-light').removeClass('btn-primary');

                    if (!$(elementTargetName).hasClass('not_click')) {
                        $(elementTargetName).trigger('click');
                    }
                }
            }

            //init des emails
            // if ($(this).data('initEmails')) {
            //     if ($(this).val() != '') {
            //         var emailsRetours = $(this).val().split(';');
            //         var elementTargetName = 'input[name=' + targetName.data('initEmails') + ']';
            //
            //         for (var i = 0; i < emailsRetours.length; i++) {
            //             if (i == 0) {
            //                 $(elementTargetName).val(emailsRetours[i]);
            //                 continue;
            //             }
            //
            //             $(elementTargetName).closest('.col-12').append('<div class="input-group mt-2"><input type="text" class="email_retour form-control border-0 bg-light text-dark" placeholder="ex : commercial@maconcession.fr" value="' + emailsRetours[i] + '" aria-label="Titre du formulaire"><div class="input-group-append"><button class="btn btn-secondary suppr_email_retour" type="button">-</button></div></div>');
            //
            //         }
            //     }
            // }

            //init header et formulaire
            if ($(this).data('initBloc')) {
                var target = $('#' + targetName.data('initBloc')).data('target');
                if ($(this).val() == 0) {
                    $(target).hide();
                }

                if ($(this).val() == 1) {
                    $(target).show();
                }
            }
        });
    },

    disabledItem: function () {
        $(document).on('click', '.disabled-item', function (e) {
            e.preventDefault();
            $.alert({
                title: 'Alerte:',
                content: "Vous n'êtes pas autorisé à effectuer cette action.",
                type: 'red',
            });
        })
    },

    activeItem: function () {
        var target = $(".list-group-item-action");
        target.each(function (index, value) {
            if ($(value).hasClass('active')) {
                $(value).trigger('click')
            }
        });
    },

    confirmDeleteCampagne: function () {
        $('#deleteCampagne').on('click', function (e) {
            e.preventDefault();
            var urlDelete = $(this).data('route');
            $.confirm({
                title: 'Supprimer la campagne',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer cette campagne ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlDelete;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        })
    },
    rechargeCredit: function () {
        $("#credit_demande").on("change", function (e) {
            e.preventDefault();
            var nbCredit = $(this).val();
            var nbCreditMini = (nbCreditClient < 1000 ? 1000 : nbCreditClient);
            if (nbCredit < nbCreditMini) {
                nbCredit = nbCreditMini;
                $(this).val(nbCredit);
                if (nbCreditClient != 0) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Vous devez commander au minimum le nombre de crédit dont vous disposez déjà (' + Intl.NumberFormat("fr-FR").format(nbCreditClient) + ').',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                }
            } else if (nbCredit > 19999) {
                nbCredit = 19999;
                $(this).val(nbCredit);
            }
            $("#sms_desti_demande").html(Intl.NumberFormat("fr-FR").format(Math.round(nbCredit / valCredSms)));
            $("#vms_desti_demande").html(Intl.NumberFormat("fr-FR").format(Math.round(nbCredit / valCredVms)));
            $("#mms_desti_demande").html(Intl.NumberFormat("fr-FR").format(Math.round(nbCredit / valCredMms)));
            $("#total_recharge_demande").html(Intl.NumberFormat("fr-FR").format(nbCredit));

            var url = $("#routePrix").data('route');
            $.ajax({
                url: url,
                data: { nbCredit: nbCredit },
                type: "post",
                cache: false,
                dataType: 'json',
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la récupération du prix.',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                },
                success: function (data) {
                    var myPrix = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.prix);
                    $("#prix_demande").html(myPrix);
                    $("#solde_demande").html(Intl.NumberFormat("fr-FR").format(data.nbCredit));
                }
            });


        });

        $("#selectPro").on("change", function (e) {
            e.preventDefault();
            var nbCredit = $(this).val();

            $("#sms_desti_pro").html(Intl.NumberFormat("fr-FR").format(Math.round(nbCredit / valCredSms)));
            $("#vms_desti_pro").html(Intl.NumberFormat("fr-FR").format(Math.round(nbCredit / valCredVms)));
            $("#mms_desti_pro").html(Intl.NumberFormat("fr-FR").format(Math.round(nbCredit / valCredMms)));
            $("#total_recharge_pro").html(Intl.NumberFormat("fr-FR").format(nbCredit));

            var url = $("#routePrix").data('route');
            $.ajax({
                url: url,
                data: { nbCredit: nbCredit },
                type: "post",
                cache: false,
                dataType: 'json',
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la récupération du prix.',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                },
                success: function (data) {
                    var myPrixOld = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.prix);
                    var myPrix = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format((data.prixRemisee));
                    $("#prix_pro_old").html(myPrixOld);
                    $("#prix_pro").html(myPrix);
                    $("#solde_pro").html(Intl.NumberFormat("fr-FR").format(data.nbCredit));
                }
            });


        });


        $(".deletePanier").on("click", function (e) {
            e.preventDefault();
            var idProd = $(this).data("id");

            $.confirm({
                title: 'Supprimer du panier',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce produit de votre panier ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            $.ajax({
                                data: { idP: idProd },
                                type: "POST",
                                cache: false,
                                url: routeSupProd,
                                dataType: "json",
                                success: function (data) {
                                    var montantTotalHT = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.montantTotalHT);
                                    var montantTotalTTC = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.montantTotalTTC);
                                    var montantTVA = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.montantTVA);

                                    $("#montantTotalTTC").html(montantTotalTTC);
                                    $("#montantTotalHT").html(montantTotalHT);
                                    $("#montantTVA").html(montantTVA);

                                    $("#line_produit_" + idProd).fadeOut();
                                    $("#nbProdPanier").html(data.nbProduits);

                                    if (data.nbProduits == 0) {
                                        $("#viewPanier").html('<div class="row justify-content-center mt-3"><h3 class="text-light pb-4">Votre panier est vide</h3></div>');
                                    }
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });

        });

        $("#recharge_illimite").on("click", function (e) {
            e.preventDefault();
            $.confirm({
                title: 'Compte premium',
                type: 'green',
                content: 'Voulez-vous vraiment être contacter pour passer en compte premium ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-green',
                        action: function () {
                            $(location).attr('href', routeRecontact);
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });

        });
    },
    checkTelephonesParamGen: function () {
        $('#formParamGen .phones').on('blur', function (e) {
            e.preventDefault();
            var submit = false;

            if (e.relatedTarget && $(e.relatedTarget).is("button")) {
                submit = true;
            }

            var url = $(this).data('route');
            var that = $(this);
            $.ajax({
                url: url,
                data: { phone_numbers: $(this).val() },
                type: "post",
                cache: false,
                dataType: "json",
                // error: function (err) {
                //     alert('Erreur dans la requête');
                // },
                success: function (data) {
                    if (data.html != '') {
                        that.val(data.html);
                    }

                    if (submit == true) {
                        var form = that.closest("form");
                        form.submit();
                    }
                },
                statusCode: {
                    400: function () {
                        $.alert({
                            title: 'Erreur:',
                            content: 'Pas de numéro valable',
                            type: 'red',
                        });
                        that.val('');
                    }
                }
            });
        })
    },

    _validImportMessageVocal: function () {
        $('.st-files').on('DOMSubtreeModified', function (e) {

            if ($(this).find('.st-fileupload-errors').html() != '') {
                $('#import-message-vocal').addClass('d-none').removeClass('d-inline');
                $('.alert').each(function () {
                    $(this).fadeOut('fast');
                });
            } else {
                $('#import-message-vocal').removeClass('d-none').addClass('d-inline');
            }
        });

        $('.st-files').on('click', '.st-remove-file-btn', function (event) {
            setTimeout(removedBtn, 200);

            function removedBtn() {
                if ($(this).find('.st-file-name').html() == undefined) {
                    $('#import-message-vocal').addClass('d-none').removeClass('d-inline');
                }
            }
        });

    },

    deleteMessageVocal: function () {
        $(document).off('click', '.btn-delete-message-vocal');
        $(document).on('click', '.btn-delete-message-vocal', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer le message vocal',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce message vocal ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                    location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    enregistrerMessageVocal: function () {
        $('#enregistrer-message-vocal').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var urlApiReturn = $('#enregistrer-message-vocal').data('apiReturn');
            if ($(this).hasClass('alertChoixPdv')) {
                return false;
            }

            $.ajax({
                url: url,
                type: "post",
                cache: false,
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans la requête de l\'enregistrement',
                        type: 'red',
                        buttons: {
                            ok: {
                                text: 'OK',
                            }
                        }
                    });
                },
                success: function (data) {
                    $('#recorder-container').html(data.html);
                    $("#recorder_timeout").countdown($("#recorder_timeout").data('timeout'), function (event) {
                        $(this).html("Durée de validitée du code : " + event.strftime('%M m %S s'));
                    }).on('finish.countdown', function () {
                        window.location.reload();
                    });
                    INTERFACE_FRONT._checkCallbackApiRecorder(urlApiReturn);
                }
            });
        });
    },

    _checkCallbackApiRecorder: function (urlApiReturn) {
        var myFunc = setInterval(function () {
            var recorderSessionId = $('#recorder_session_id').html();
            $.ajax({
                url: urlApiReturn,
                type: "post",
                cache: false,
                global: false,
                data: { recorder_session_id: recorderSessionId },
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: 'Erreur dans le lien du fichier audio',
                        type: 'red',
                        onOpen: function () {
                            clearInterval(myFunc);
                        },
                        buttons: {
                            ok: {
                                text: 'OK',
                                action: function () {
                                    clearInterval(myFunc);
                                    window.location.reload();
                                }
                            }
                        }
                    });
                },
                success: function (data) {
                    if (data.message == "success") {
                        $('#recorder-container').html(data.html);
                        clearInterval(myFunc);
                    }
                }
            });
        }, 5000)
    },

    vmsEnvoi: function () {
        $(document).on("focusout", "#campagne_donnee_vms_numRappel", function (e) {
            var url = $(this).data('route');
            var tel = $(this).val();
            var that = $(this);
            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "filtre_fixe": 0,
                    "filtre_mobile": 1,
                    "phone_number": tel
                },
                success: function (data) {
                    that.val(data.numTel);
                    $("#campagne_donnee_vms_afficheNumRappel").val("1");
                    $("#afficheRappelCheck").attr("checked", true);
                },
                statusCode: {
                    400: function (data) {
                        that.val("");
                        $("#campagne_donnee_vms_afficheNumRappel").val("0");
                        $("#afficheRappelCheck").attr("checked", false);
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });
        $(document).on("click", "#afficheRappelCheck", function (e) {
            if ($(this).is(':checked')) {
                $("#campagne_donnee_vms_afficheNumRappel").val("1");
                $('#campagne_donnee_vms_numRappel').removeAttr('disabled');
                $('#campagne_donnee_vms_numRappel').prop('required', 'required');
            } else {
                $("#campagne_donnee_vms_afficheNumRappel").val("0");
                $('#campagne_donnee_vms_numRappel').prop('disabled', 'disabled');
                $('#campagne_donnee_vms_numRappel').removeAttr('required');
                $('#campagne_donnee_vms_numRappel').val('');
            }
        });
    },

    planifVague: function () {
        $(document).on('click', '.planVagues', function (e) {
            e.preventDefault();
            $('#sauveCampagne').attr('disabled', 'disabled');
            $('#typeEnvoi').val('vague');
            var url = $(this).data('route');
            var nbContact = parseInt($('#destinataire_recap').text());
            var nbVague = parseInt($('#nb_vague').text());
            var typeVague = $(this).data('method');
            INTERFACE_FRONT._planifVague(url, nbContact, nbVague, typeVague);
        });
    },

    _planifVague: function (url, nbContact, nbVague, typeVague) {
        if (typeVague == "del" && nbVague == 1) {
            $.alert({
                title: 'Erreur:',
                content: "Vous ne pouvez pas supprimer toutes les vagues d'envoi.",
                type: 'red',
            });
        } else {
            if (nbContact > 1) {
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: url,
                    data: {
                        "nbContact": nbContact,
                        "nbVague": nbVague,
                        "typeVague": typeVague,
                    },
                    success: function (data) {
                        $("#programVagues").html(data.html);
                        $("#nb_vague").text(data.nbVague);
                        if ($('#planifVague').length) {
                            $("#programVagues").find('div.row.mt-2.align-items-center > .col-auto').append('<button type="button" onclick="$.fancybox.close()" class="btn btn-success" data-dismiss="modal">Valider</button>');
                            $(document).trigger('display-wave');
                        }
                    },
                    statusCode: {
                        400: function (data) {
                            $.alert({
                                title: 'Erreur:',
                                content: data.responseJSON.message,
                                type: 'red',
                            });
                        }
                    }
                });
            } else {
                $.alert({
                    title: 'Erreur:',
                    content: "Vous devez avoir plus d'un contact pour faire un envoi en plusieurs vagues.",
                    type: 'red',
                });
            }
        }
    },
    choixDatePlanifVms: function () {
        $(document).on('click', '#planNowVms,#planLateVms', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var choixPlanif = $(this).data('value');
            $("#programVagues").html("");
            $("#nb_vague").text("1");
            $("#nb_vague_planif").text("0");
            $('#sauveCampagne').attr('disabled', 'disabled');
            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "choixPlanif": choixPlanif,
                },
                success: function (data) {
                    if (data.choixPlanif == 'now') {
                        $("#sauveCampagne").text("Envoyer maintenant");
                        $("#sauveCampagne").removeAttr("disabled");
                        $("#planText").text("Envoyer maintenant");
                        $('#planCampagne').removeClass('show');
                        $('#typeEnvoi').val('now');
                    }

                    if (data.choixPlanif == 'scheduled') {
                        $('#typeEnvoi').val('planifier');
                        $.fancybox.open({
                            src: data.html,
                            type: 'html',
                            opts: {}
                        });
                    }
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });
    },
    choixDatePlanifVague: function () {
        $(document).on('click', '.datevague', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var idVague = $(this).attr('id');

            var nbVague = parseInt($('#nb_vague').text());
            var nbVaguePlanif = parseInt($('#nb_vague_planif').text());

            if (nbVaguePlanif < nbVague) {
                $('#sauveCampagne').attr('disabled', 'disabled');
            }

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "idVague": idVague,
                },
                success: function (data) {
                    $.fancybox.open({
                        src: data.html,
                        type: 'html',
                        opts: {}
                    });

                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });
    },

    checkDatePlanifVague: function () {
        $(document).on('click', '#planVagueOk', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var datePlanif = $('#planDateInput').val();
            var idVague = $('#idVague').val();

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "datePlanif": datePlanif,
                },
                success: function (data) {
                    if (data.errors.length === 0) {
                        $.fancybox.close();
                        $('#' + idVague).val(data.datePlanif);
                        var nbVague = parseInt($('#nb_vague').text());
                        var nbVaguePlanif = parseInt($('#nb_vague_planif').text());
                        nbVaguePlanif = nbVaguePlanif + 1;
                        $('#nb_vague_planif').text(nbVaguePlanif);

                        if (nbVaguePlanif >= nbVague) {
                            $("#planText").text("Vagues planifiées");
                            $("#sauveCampagne").text("Planifier les vagues");
                            $("#sauveCampagne").removeAttr("disabled");
                        } else {
                            $("#sauveCampagne").text("Encore " + (nbVague - nbVaguePlanif) + " vague(s) a planifier");
                        }
                    } else {
                        var content = '';
                        for (var i = 0; i < data.errors.length; i++) {
                            content += '<div class="alert alert-danger alert-dismissible fade show " role="alert"><strong>Erreur:</strong>' + data.errors[i] + '<br></div>';
                        }

                        $('#planErreurs').html(content);
                    }
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.content,
                            type: 'red',
                        });
                    }
                }
            });
            return false;
        });
    },

    dupliquerSmsInteractif: function () {
        $(document).on('click', '#dupliquer-sms-interactif', function () {
            var url = $(this).data('route');
            var campagneId = $(this).data('campagne-id');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    'campagneId': campagneId,
                },
                success: function (data) {
                    $.fancybox.open({
                        src: data,
                        type: 'html'
                    })
                }
            });
            return false;
        });
    },

    _displayErrorsSmsInt: function () {
        var form = $('#nouveauModeleMiniSite-Form'),
            errorList = $("ul.error-messages", form);

        var showAllErrorMessages = function () {
            errorList.empty();

            // Find all invalid fields within the form.
            var invalidFields = form.find(":invalid").each(function (index, node) {
                // Find the field's corresponding label
                var label = $("label[for=" + node.id + "] "),
                    // Opera incorrectly does not fill the validationMessage property.
                    message = node.validationMessage || 'Invalid value.';

                errorList
                    .show()
                    .append("<div class='alert alert-dismissible alert-danger fade show' role='alert'>" + label.html() + " : " + message + "<button type='button' class='close' data-dismiss='alert' aria-label='Fermer'><span aria-hidden='true'>&times;</span></button></div>");
                $.fancybox.close();
            });

            if (invalidFields.length == 0) {
                errorList.hide();
            }
        };

        // Support Safari
        form.on("submit", function (event) {
            if (this.checkValidity && !this.checkValidity()) {
                $(this).find(":invalid").first().focus();
                event.preventDefault();
            }
        });

        $("input[type=submit], button:not([type=button])", form)
            .on("click", showAllErrorMessages);

        $("input", form).on("focusout", function (event) {
            var type = $(this).attr("type");
            if (/date|email|month|number|search|tel|text|time|url|week/.test(type)) {
                showAllErrorMessages();
            }
        });
    },
    sanitizeInputText: function () {
        $(document).on('focusout', 'input.sanitize[type="text"]', function () {
            if ($(this).val() != '') {
                var newString = $(this).val().replace(/[^0-9a-z]/gi, '-');
                if (newString.search(/^-/) == 0) {
                    $(this).val('');
                    $('.error-flash').html('<div class="alert alert-danger alert-dismissible fade show " role="alert"><strong>Erreur:</strong> Le nom du fichier ne peut contenir que des caractères alphanumériques<br></div>');
                } else {
                    $(this).val(newString);
                }
            }
        });
    },

    crmMiniSite: function () {
        $(".btnPreviewFor").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
                afterClose: function () {
                    location.reload();
                },
            }
        );
    },

    crmEventText: function () {
        var that = this;

        $('#crm_devis_express_messageSMS').on('input keyup',
            function (e) {
                e.preventDefault();
                that.countChar($('#crm_devis_express_messageSMS'), that.twig.stopLengthSms);
                that.countNbSms(that.currentLength, $('#crm_devis_express_messageSMS'));
                that.updateLength('#charNum');
                $("#crm_devis_express_nbSMS").val($("#nbreSms").text());
            }
        );

        $('#crm_devis_express_messageMMS').on('input keyup',
            function (e) {
                e.preventDefault();
                that.countChar($('#crm_devis_express_messageMMS'), that.stopLengthMms);
                that.updateLength('#charNumMms');
            }
        );
    },

    crmExportPdv: function () {
        $("#exportPdvCrm").on('click', function () {
            $(".alert").hide();
        });

    },
    crmDemandeCrea: function () {


        var xhr = null;
        var typingTimer;
        var doneTypingInterval = 1000;
        var textarea;

        $(document).on('click', '#actuSwitch', function () {
            if ($.cookie("timerRefreshInterPhone") == undefined || $.cookie("timerRefreshInterPhone") == "0") {
                $.cookie("timerRefreshInterPhone", "1", { expires: 365 });
            } else {
                $.cookie("timerRefreshInterPhone", "0", { expires: 365 });
            }
            location.reload();
        });

        if ($('#refreshZone').length) {
            if ($.cookie("timerRefreshInterPhone") == "1") {
                var time = 60
                setInterval(function () {
                    time--;
                    $('#refreshZone').html(time);
                    if (time === 0) {
                        location.reload()
                    }
                }, 1000);
                $("#actuSwitch").attr("checked", true);
            } else {
                $("#refreshTimer").hide();
                $("#actuSwitch").attr("checked", false);
            }
        }

        $("#crm_devis_express_messageSMS").on("keyup", function (e) {
            if (xhr !== null) {
                xhr.abort();
                xhr = null;
            }

            textarea = document.getElementById('crm_devis_express_messageSMS');
            posCaret = INTERFACE_FRONT._getCaretPosition(textarea);
            e.preventDefault();
            clearTimeout(typingTimer);
            if ($('#crm_devis_express_messageSMS').val()) {
                var url = $(this).data('route');
                typingTimer = setTimeout(doneTypingCrmExpress, doneTypingInterval, url);
            }
        });

        function doneTypingCrm(url) {
            xhr = $.ajax({
                url: url,
                data: { text_sms: $("#crm_demande_messageSMS").val() },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                error: function (err) {
                    console.log('Traitement en cours');
                },
                success: function (data) {
                    $("#crm_demande_messageSMS").val(data.sms);
                    INTERFACE_FRONT._setCaretPosition(textarea, posCaret.start, posCaret.end);
                },
            });
        }

        function doneTypingCrmExpress(url) {
            xhr = $.ajax({
                url: url,
                data: { text_sms: $("#crm_devis_express_messageSMS").val() },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                error: function (err) {
                    console.log('Traitement en cours');
                },
                success: function (data) {
                    $("#crm_devis_express_messageSMS").val(data.sms);
                    INTERFACE_FRONT._setCaretPosition(textarea, posCaret.start, posCaret.end);
                },
            });
        }

        function doneTypingCrmModif(url) {
            xhr = $.ajax({
                url: url,
                data: { text_sms: $("#crm_modif_demande_messageSMS").val() },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                error: function (err) {
                    console.log('Traitement en cours');
                },
                success: function (data) {
                    $("#crm_modif_demande_messageSMS").val(data.sms);
                    INTERFACE_FRONT._setCaretPosition(textarea, posCaret.start, posCaret.end);
                },
            });
        }

        $(document).on('click', '#planOkCrm', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            var datePlanif = $('#planDateInput').val();

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                data: {
                    "datePlanif": datePlanif,
                },
                success: function (data) {
                    if (data.errors.length === 0) {
                        $.fancybox.close();
                        $('#crm_devis_express_datePlanifEnvoi').val(data.datePlanif);
                        $('#campagne_demande_mms_datePlanifEnvoi').val(data.datePlanif);
                        $('#campagne_demande_email_datePlanifEnvoi').val(data.datePlanif);
                        $('#planifCrm').html("<i class=\"mr-2 fas fa-clock\"></i>Date prévue : " + data.datePlanif);
                    } else {
                        var content = '';
                        for (var i = 0; i < data.errors.length; i++) {
                            content += '<div class="alert alert-danger alert-dismissible fade show " role="alert"><strong>Erreur:</strong>' + data.errors[i] + '<br></div>';
                        }

                        $('#planErreurs').html(content);
                    }
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.content,
                            type: 'red',
                        });
                    }
                }
            });
            return false;
        });


        $(document).on('click', '.archiveDem', function (e) {
            e.preventDefault();
            urlArchiveDem = $(this).data("route");
            $.confirm({
                title: 'Archiver la demande',
                type: 'red',
                content: 'Voulez-vous vraiment archiver la demande ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlArchiveDem;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });

        $(document).on('click', '.modifDemCrm', function () {
            window.location = $(this).data('route');
        });

        $("#planifCrm").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>'
            }
        );

        $("#raccourciLien").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
                afterClose: function () {
                    location.reload();
                },
            }
        );

        $(".actionsCrm").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
                afterClose: function () {
                    location.reload();
                },
            }
        );

        $(".actionsCrmWithoutReload").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
            }
        );

        $(".btnCrmRemise").fancybox(
            {
                type: 'ajax',
                spinnerTpl: '<div style="display: none;"></div>',
                afterClose: function () {
                    location.reload();
                },
            }
        );

        $(document).on('change', '#crm_import_devis_comptePrincipal, #crm_devis_express_comptePrincipal', function () {
            $.ajax({
                type: "POST",
                cache: false,
                url: $(this).data("route"),
                data: {
                    "idCp": $(this).val(),
                },
                success: function (data) {
                    $('#divMarque').html(data.html);

                    $.fn.select2.defaults.set('language', 'fr');
                    $('#selectMarque').select2({
                        escapeMarkup: function (markup) {
                            return markup;
                        },
                    }
                    );
                },
                statusCode: {
                    400: function (data) {

                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });

        $(document).on('click', '.crmAction', function (e) {
            urlCrmAct = $(this).data("route");
            urlCrmArchive = $(this).data("translink");
            urlCrmVerif = $(this).data("veriflink");
            urlZeroDevis = $(this).data("zerodevis");
            urlFormChoixDate = $("#urlFormDatePlani").data("val");
            urlCrmChoixDate = $(this).data("dateplani");
            status = $(this).data("status");
            bilan = $(this).data("bilan");
            if (status == 1) {
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: urlCrmVerif,
                    dataType: "json",
                    success: function (data) {
                        $.confirm({
                            theme: 'dark',
                            title: 'Transformer le devis en bon de commande',
                            type: 'blue',
                            content: 'url:' + urlCrmArchive,
                            buttons: {
                                confirm: {
                                    text: 'Confirmer',
                                    btnClass: 'btn-blue',
                                    action: function () {
                                        var serializedForm = this.$content.find('#formAct').serialize();
                                        $.ajax({
                                            type: "POST",
                                            cache: false,
                                            url: urlCrmAct,
                                            data: serializedForm,
                                            dataType: "json",
                                            success: function (data) {
                                                location.reload();
                                            },
                                            statusCode: {
                                                400: function (data) {
                                                    $.alert({
                                                        theme: 'dark',
                                                        title: 'Erreur:',
                                                        content: data.responseJSON.message,
                                                        type: 'red',
                                                    });
                                                }
                                            }
                                        });

                                    }
                                },
                                cancel: {
                                    btnClass: 'btn-red',
                                    text: 'Annuler'
                                }
                            }
                        });
                    },
                    statusCode: {
                        400: function (data) {
                            if (data.responseJSON.typeRet == "C") {
                                $.alert({
                                    theme: 'dark',
                                    title: 'Erreur:',
                                    content: data.responseJSON.message,
                                    type: 'red',
                                    columnClass: 'col-md-7',
                                    buttons: {
                                        createCompte: {
                                            text: 'Créer un nouveau compte',
                                            btnClass: 'btn-blue',
                                            action: function () {
                                                window.location = data.responseJSON.routeCreateCompte;
                                            }
                                        },
                                        addPdv: {
                                            text: 'Ajouter un point de vente',
                                            btnClass: 'btn-light text-dark',
                                            action: function () {
                                                window.location = data.responseJSON.routeAddPdv;
                                            }

                                        },
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Annuler'
                                        },
                                    }
                                });
                            } else if (data.responseJSON.typeRet == "D") {

                                $.confirm({
                                    theme: 'dark',
                                    title: 'Sélectionner la date d\'envoi prévu',
                                    type: 'blue',
                                    content: 'url:' + urlFormChoixDate,
                                    buttons: {
                                        confirm: {
                                            text: 'Confirmer',
                                            btnClass: 'btn-blue',
                                            action: function () {
                                                var serializedForm = this.$content.find('#formActDate').serialize();
                                                $.ajax({
                                                    type: "POST",
                                                    cache: false,
                                                    url: urlCrmChoixDate,
                                                    data: serializedForm,
                                                    dataType: "json",
                                                    success: function (data) {
                                                        $.confirm({
                                                            theme: 'dark',
                                                            title: 'Transformer le devis en bon de commande',
                                                            type: 'blue',
                                                            content: 'url:' + urlCrmArchive,
                                                            buttons: {
                                                                confirm: {
                                                                    text: 'Confirmer',
                                                                    btnClass: 'btn-blue',
                                                                    action: function () {
                                                                        var serializedForm = this.$content.find('#formAct').serialize();
                                                                        $.ajax({
                                                                            type: "POST",
                                                                            cache: false,
                                                                            url: urlCrmAct,
                                                                            data: serializedForm,
                                                                            dataType: "json",
                                                                            success: function (data) {
                                                                                location.reload();
                                                                            },
                                                                            statusCode: {
                                                                                400: function (data) {
                                                                                    $.alert({
                                                                                        theme: 'dark',
                                                                                        title: 'Erreur:',
                                                                                        content: data.responseJSON.message,
                                                                                        type: 'red',
                                                                                    });
                                                                                }
                                                                            }
                                                                        });

                                                                    }
                                                                },
                                                                cancel: {
                                                                    btnClass: 'btn-red',
                                                                    text: 'Annuler'
                                                                }
                                                            }
                                                        });
                                                    },
                                                    statusCode: {
                                                        400: function (data) {
                                                            $.alert({
                                                                theme: 'dark',
                                                                title: 'Erreur:',
                                                                content: data.responseJSON.message,
                                                                type: 'red',
                                                            });
                                                        }
                                                    }
                                                });
                                            }
                                        },
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Annuler'
                                        }
                                    }
                                });
                                /*

                                                                $.alert({
                                                                    theme: 'dark',
                                                                    title: 'Erreur:',
                                                                    content: data.responseJSON.message,
                                                                    type: 'red',
                                                                    columnClass: 'col-md-7',
                                                                    buttons: {
                                                                        editDemande: {
                                                                            text: 'Modifier le devis',
                                                                            btnClass: 'btn-blue',
                                                                            action: function () {
                                                                                window.location = data.responseJSON.routeModif;
                                                                            }
                                                                        },
                                                                        cancel: {
                                                                            btnClass: 'btn-red',
                                                                            text: 'Annuler'
                                                                        },
                                                                    }
                                                                });




                                 */
                            } else if (data.responseJSON.typeRet == "M") {

                                $.confirm({
                                    theme: 'dark',
                                    title: 'Attention !!',
                                    content: 'Voulez-vous vraiment transformer un devis à 0€ ?',
                                    type: 'blue',
                                    buttons: {
                                        confirm: {
                                            text: 'Confirmer',
                                            btnClass: 'btn-blue',
                                            action: function () {
                                                $.ajax({
                                                    type: "POST",
                                                    cache: false,
                                                    url: urlZeroDevis,
                                                    dataType: "json",
                                                    success: function (data) {
                                                        $.confirm({
                                                            theme: 'dark',
                                                            title: 'Transformer le devis en bon de commande',
                                                            type: 'blue',
                                                            content: 'url:' + urlCrmArchive,
                                                            buttons: {
                                                                confirm: {
                                                                    text: 'Confirmer',
                                                                    btnClass: 'btn-blue',
                                                                    action: function () {
                                                                        var serializedForm = this.$content.find('#formAct').serialize();
                                                                        $.ajax({
                                                                            type: "POST",
                                                                            cache: false,
                                                                            url: urlCrmAct,
                                                                            data: serializedForm,
                                                                            dataType: "json",
                                                                            success: function (data) {
                                                                                location.reload();
                                                                            },
                                                                            statusCode: {
                                                                                400: function (data) {
                                                                                    $.alert({
                                                                                        theme: 'dark',
                                                                                        title: 'Erreur:',
                                                                                        content: data.responseJSON.message,
                                                                                        type: 'red',
                                                                                    });
                                                                                }
                                                                            }
                                                                        });

                                                                    }
                                                                },
                                                                cancel: {
                                                                    btnClass: 'btn-red',
                                                                    text: 'Annuler'
                                                                }
                                                            }
                                                        });
                                                    },
                                                    statusCode: {
                                                        400: function (data) {
                                                            $.alert({
                                                                theme: 'dark',
                                                                title: 'Erreur:',
                                                                content: data.responseJSON.message,
                                                                type: 'red',
                                                            });
                                                        }
                                                    }
                                                });
                                            }
                                        },
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Annuler'
                                        }
                                    }
                                });

                            } else {
                                $.alert({
                                    theme: 'dark',
                                    title: 'Erreur:',
                                    content: data.responseJSON.message,
                                    type: 'red',
                                    columnClass: 'col-md-7',
                                    buttons: {
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Ok'
                                        },
                                    }
                                });
                            }
                        }
                    }
                });


            } else {
                $.confirm({
                    theme: 'dark',
                    title: (status == 2 && bilan != "N" ? 'Envoyez le bon de commande dans les demandes de bilan' : (status == 4 ? 'Archiver la facture' : 'Archiver le bon de commande')),
                    type: 'blue',
                    content: (status == 2 && bilan != "N" ? 'Voulez-vous vraiment transférer le bon de commande dans les demandes de bilan ?' : (status == 4 ? 'Voulez-vous vraiment archiver la facture ?' : 'Voulez-vous vraiment archiver le bon de commande ?')),
                    buttons: {
                        confirm: {
                            text: 'Oui',
                            btnClass: 'btn-blue',
                            action: function () {
                                $.ajax({
                                    type: "POST",
                                    cache: false,
                                    url: urlCrmAct,
                                    dataType: "json",
                                    success: function (data) {
                                        location.reload();
                                    },
                                    statusCode: {
                                        400: function (data) {
                                            $.alert({
                                                theme: 'dark',
                                                title: 'Erreur:',
                                                content: data.responseJSON.message,
                                                type: 'red',
                                            });
                                        }
                                    }
                                });
                            }
                        },
                        cancel: {
                            btnClass: 'btn-red',
                            text: 'Non'
                        }
                    }
                });
            }

        });

        $(document).on('change', '#crm_devis_express_clientProspect', function () {
            if ($(this).val() == 0) {
                $("#prospectYes").removeClass("divHide");
                $("#clientYes").addClass("divHide");
            } else {
                $("#prospectYes").addClass("divHide");
                $("#clientYes").removeClass("divHide");
            }

        });

        $(document).on('change', '#selectMarque', function () {
            var idCp = $("#crm_import_devis_comptePrincipal").val();
            var multi = false;
            if (idCp == undefined) {
                idCp = $("#crm_devis_express_comptePrincipal").val();
            }

            $.ajax({
                type: "POST",
                cache: false,
                url: $(this).data("route"),
                data: {
                    "idCp": idCp,
                    "idMarque": $(this).val(),
                    "multi": multi
                },
                success: function (data) {
                    $('#divAgence').html(data.html);

                    $.fn.select2.defaults.set('language', 'fr');
                    if (multi) {
                        $('#selectAgence').select2({
                            multiple: true,
                            placeholder: "Sélectionnez une ou plusieurs agence(s)",
                        }
                        );
                    } else {
                        $('#selectAgence').select2({
                            placeholder: "Sélectionnez une agences",
                        }
                        );
                    }
                },
                statusCode: {
                    400: function (data) {

                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });

        $(document).on('change', '#selectAgence', function () {

            $.ajax({
                type: "POST",
                cache: false,
                url: $("#urlInterloc").data("val"),
                data: {
                    "idPdv": $(this).val(),
                },
                success: function (data) {
                    $('#divInterlo').html(data.html);

                    $.fn.select2.defaults.set('language', 'fr');

                },
                statusCode: {
                    400: function (data) {

                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });

        /** Partie Data **/
        $(document).on('change', '#crm_devis_express_activeData', function () {
            if ($(this).val() == "1") {
                $("#divDATA").css('display', 'block');
                $("#headingProdData").removeClass('bg-secondary');
                $("#headingProdData").addClass("bg-primary");
                if ($("#crm_devis_express_texteConqueteDATA").val() == "") {
                    $("#crm_devis_express_texteConqueteDATA").val("Base de données à usage unique. Après votre campagne merci de sortir ces contacts de vos listes (cf. CNIL).");
                }
            }else {
                $("#divDATA").css('display', 'none');
                $("#headingProdData").removeClass('bg-primary');
                $("#headingProdData").addClass("bg-secondary");
                $("#crm_devis_express_texteConqueteDATA").val("");
            }
        });

        $(document).on('change', '#crm_devis_express_exportOffertDATA', function () {
            if ($(this).val() == "1") {
                $("#tarifExportDATA").css('display', 'none');
                $("#afficheTarifExportDATA").css('display', 'block');
            } else {
                $("#tarifExportDATA").css('display', 'block');
                $("#afficheTarifExportDATA").css('display', 'none');
            }
        });

        /** Partie SMS **/
        $(document).on('change', '#crm_devis_express_activeSms', function () {
            if ($(this).val() == "1") {
                $("#divSMS").css('display', 'block');
                $("#headingProdSms").removeClass('bg-secondary');
                $("#headingProdSms").addClass("bg-primary");
            } else {
                $("#divSMS").css('display', 'none');
                $("#headingProdSms").removeClass('bg-primary');
                $("#headingProdSms").addClass("bg-secondary");
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteSMS', function () {
            if ($(this).val() == "1" || $(this).val() == "2") {

                if($(this).val() == "1"){
                    $("#addMiniSiteRetSMS").css('display', 'flex');
                    $("#crm_devis_express_texteMiniSiteSMS").val("Création, hébergement, tracking des cliqueurs, statistiques.");
                }else{
                    $("#addMiniSiteRetSMS").css('display', 'none');
                    $("#crm_devis_express_texteMiniSiteSMS").val("Tracking des cliqueurs, statistiques.");
                }

                $("#addMinisiteSMS").css('display', 'block');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'green',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est :</b><br> <small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera transformer en :</b><br><small>À compter de la date de campagne, vous avez 14' +
                            ' jours' +
                            ' pour' +
                            ' contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).</small>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-green',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("À compter de la date de campagne, vous avez 14 jours pour contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            } else {
                $("#addMinisiteSMS").css('display', 'none');
                $("#addMiniSiteRetSMS").css('display', 'none');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'red',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est : </b><br><small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera vide après validation.</b>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-red',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            }
        });

        $(document).on('change', '#crm_devis_express_retourDeLeadsSMS', function () {
            if ($(this).val() == "1") {
                $("#emailLeadsCrmSMS").css('display', 'block');
            } else {
                $("#emailLeadsCrmSMS").hide();
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteOffertSMS', function () {
            if ($(this).val() == "1") {
                $("#tarifCreaMinisiteSMS").css('display', 'none');
                $("#afficheTarifCreaMinisiteSMS").css('display', 'block');
            } else {
                $("#tarifCreaMinisiteSMS").css('display', 'block');
                $("#afficheTarifCreaMinisiteSMS").css('display', 'none');
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientSMS', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientSMS").css('display', 'block');
            } else {
                $("#addFichierClientSMS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_traitementOffertSMS', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesSMS").css('display', 'none');
                $("#afficheTarifDonneesSMS").css('display', 'block');
            } else {
                $("#tarifDonneesSMS").css('display', 'block');
                $("#afficheTarifDonneesSMS").css('display', 'none');
            }
        });


        /** Partie VMS **/
        $(document).on('change', '#crm_devis_express_activeVms', function () {
            if ($(this).val() == "1") {
                $("#divVMS").css('display', 'block');
                $("#headingProdVms").removeClass('bg-secondary');
                $("#headingProdVms").addClass("bg-primary");
            } else {
                $("#divVMS").css('display', 'none');
                $("#headingProdVms").removeClass('bg-primary');
                $("#headingProdVms").addClass("bg-secondary");
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientVMS', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientVMS").css('display', 'block');
            } else {
                $("#addFichierClientVMS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_enregistrementVMS', function () {
            if ($(this).val() == "Client") {
                $("#addEnregistrementVMS").css('display', 'none');
            } else {
                $("#addEnregistrementVMS").css('display', 'block');
            }
        });

        $(document).on('change', '#crm_devis_express_audioOffertVMS', function () {
            if ($(this).val() == "1") {
                $("#tarifAudioVMS").css('display', 'none');
                $("#afficheTarifAudioVMS").css('display', 'block');
            } else {
                $("#tarifAudioVMS").css('display', 'block');
                $("#afficheTarifAudioVMS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_traitementOffertVMS', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesVMS").css('display', 'none');
                $("#afficheTarifDonneesVMS").css('display', 'block');
            } else {
                $("#tarifDonneesVMS").css('display', 'block');
                $("#afficheTarifDonneesVMS").css('display', 'none');
            }
        });


        /** Partie MMS **/
        $(document).on('change', '#crm_devis_express_activeMms', function () {
            if ($(this).val() == "1") {
                $("#divMMS").css('display', 'block');
                $("#headingProdMms").removeClass('bg-secondary');
                $("#headingProdMms").addClass("bg-primary");
            } else {
                $("#divMMS").css('display', 'none');
                $("#headingProdMms").removeClass('bg-primary');
                $("#headingProdMms").addClass("bg-secondary");
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteMMS', function () {
            if ($(this).val() == "1" || $(this).val() == "2") {

                if($(this).val() == "1"){
                    $("#addMiniSiteRetMMS").css('display', 'flex');
                    $("#crm_devis_express_texteMiniSiteMMS").val("Création, hébergement, tracking des cliqueurs, statistiques.");
                }else{
                    $("#addMiniSiteRetMMS").css('display', 'none');
                    $("#crm_devis_express_texteMiniSiteMMS").val("Tracking des cliqueurs, statistiques.");
                }

                $("#addMinisiteMMS").css('display', 'block');

                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'green',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est :</b><br> <small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera transformer en :</b><br><small>À compter de la date de campagne, vous avez 14' +
                            ' jours' +
                            ' pour' +
                            ' contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).</small>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-green',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("À compter de la date de campagne, vous avez 14 jours pour contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            } else {
                $("#addMinisiteMMS").css('display', 'none');
                $("#addMiniSiteRetMMS").css('display', 'none');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'red',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est : </b><br><small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera vide après validation.</b>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-red',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            }
        });

        $(document).on('change', '#crm_devis_express_retourDeLeadsMMS', function () {
            if ($(this).val() == "1") {
                $("#emailLeadsCrmMMS").css('display', 'block');
            } else {
                $("#emailLeadsCrmMMS").hide();
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteOffertMMS', function () {
            if ($(this).val() == "1") {
                $("#tarifCreaMinisiteMMS").css('display', 'none');
                $("#afficheTarifCreaMinisiteMMS").css('display', 'block');
            } else {
                $("#tarifCreaMinisiteMMS").css('display', 'block');
                $("#afficheTarifCreaMinisiteMMS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_creaOffertMMS', function () {
            if ($(this).val() == "1") {
                $("#tarifCreaMMS").css('display', 'none');
                $("#afficheTarifCreaMMS").css('display', 'block');
            } else {
                $("#tarifCreaMMS").css('display', 'block');
                $("#afficheTarifCreaMMS").css('display', 'none');
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientMMS', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientMMS").css('display', 'block');
            } else {
                $("#addFichierClientMMS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_traitementOffertMMS', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesMMS").css('display', 'none');
                $("#afficheTarifDonneesMMS").css('display', 'block');
            } else {
                $("#tarifDonneesMMS").css('display', 'block');
                $("#afficheTarifDonneesMMS").css('display', 'none');
            }
        });


        /** Partie EMAIL **/
        $(document).on('change', '#crm_devis_express_activeEmail', function () {
            if ($(this).val() == "1") {
                $("#divEMAIL").css('display', 'block');
                $("#headingProdEmail").removeClass('bg-secondary');
                $("#headingProdEmail").addClass("bg-primary");
            } else {
                $("#divEMAIL").css('display', 'none');
                $("#headingProdEmail").removeClass('bg-primary');
                $("#headingProdEmail").addClass("bg-secondary");
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteEMAIL', function () {
            if ($(this).val() == "1") {
                $("#addMiniSiteRetEMAIL").css('display', 'flex');
                $("#addMinisiteEMAIL").css('display', 'block');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'green',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est :</b><br> <small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera transformer en :</b><br><small>À compter de la date de campagne, vous avez 14' +
                            ' jours' +
                            ' pour' +
                            ' contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).</small>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-green',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("À compter de la date de campagne, vous avez 14 jours pour contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            } else {
                $("#addMinisiteEMAIL").css('display', 'none');
                $("#addMiniSiteRetEMAIL").css('display', 'none');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'red',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est : </b><br><small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera vide après validation.</b>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-red',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            }
        });

        $(document).on('change', '#crm_devis_express_retourDeLeadsEMAIL', function () {
            if ($(this).val() == "1") {
                $("#emailLeadsCrmEMAIL").css('display', 'block');
            } else {
                $("#emailLeadsCrmEMAIL").hide();
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteOffertEMAIL', function () {
            if ($(this).val() == "1") {
                $("#tarifCreaMinisiteEMAIL").css('display', 'none');
                $("#afficheTarifCreaMinisiteEMAIL").css('display', 'block');
            } else {
                $("#tarifCreaMinisiteEMAIL").css('display', 'block');
                $("#afficheTarifCreaMinisiteEMAIL").css('display', 'none');
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientEMAIL', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientEMAIL").css('display', 'block');
            } else {
                $("#addFichierClientEMAIL").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_creaOffertEMAIL', function () {
            if ($(this).val() == "1") {
                $("#tarifCreaEMAIL").css('display', 'none');
                $("#afficheTarifCreaEMAIL").css('display', 'block');
            } else {
                $("#tarifCreaEMAIL").css('display', 'block');
                $("#afficheTarifCreaEMAIL").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_traitementOffertEMAIL', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesEMAIL").css('display', 'none');
                $("#afficheTarifDonneesEMAIL").css('display', 'block');
            } else {
                $("#tarifDonneesEMAIL").css('display', 'block');
                $("#afficheTarifDonneesEMAIL").css('display', 'none');
            }
        });

        /** Partie RCS **/
        $(document).on('change', '#crm_devis_express_activeRcs', function () {
            if ($(this).val() == "1") {
                $("#divRCS").css('display', 'block');
                $("#headingProdRcs").removeClass('bg-secondary');
                $("#headingProdRcs").addClass("bg-primary");
            } else {
                $("#divRCS").css('display', 'none');
                $("#headingProdRcs").removeClass('bg-primary');
                $("#headingProdRcs").addClass("bg-secondary");
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientRCS', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientRCS").css('display', 'block');
            } else {
                $("#addFichierClientRCS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteRCS', function () {
            if ($(this).val() == "1") {
                $("#addMiniSiteRetRCS").css('display', 'flex');
                $("#addMinisiteRCS").css('display', 'block');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'green',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est :</b><br> <small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera transformer en :</b><br><small>À compter de la date de campagne, vous avez 14' +
                            ' jours' +
                            ' pour' +
                            ' contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).</small>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-green',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("À compter de la date de campagne, vous avez 14 jours pour contacter les cliqueurs. Au-delà, merci de sortir ces contacts de vos listes (cf. CNIL).");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            } else {
                $("#addMinisiteRCS").css('display', 'none');
                $("#addMiniSiteRetRCS").css('display', 'none');
                if($("#crm_devis_express_activeData").val() == 1) {
                    $.confirm({
                        title: 'Changer le texte de l\'encart DATA ?',
                        type: 'red',
                        content: 'Voulez-vous changer le texte encart DATA ?<br><br><b>Actuellement le texte est : </b><br><small>' + $("#crm_devis_express_texteConqueteDATA").val() + '</small><br><br><b>Il sera vide après validation.</b>',
                        buttons: {
                            confirm: {
                                text: 'Oui',
                                btnClass: 'btn-red',
                                action: function () {
                                    $("#crm_devis_express_texteConqueteDATA").val("");
                                }
                            },
                            cancel: {
                                text: 'Non'
                            }
                        }
                    });
                }
            }
        });

        $(document).on('change', '#crm_devis_express_retourDeLeadsRCS', function () {
            if ($(this).val() == "1") {
                $("#emailLeadsCrmRCS").css('display', 'block');
            } else {
                $("#emailLeadsCrmRCS").hide();
            }
        });

        $(document).on('change', '#crm_devis_express_miniSiteOffertRCS', function () {
            if ($(this).val() == "1") {
                $("#tarifCreaMinisiteRCS").css('display', 'none');
                $("#afficheTarifCreaMinisiteRCS").css('display', 'block');
            } else {
                $("#tarifCreaMinisiteRCS").css('display', 'block');
                $("#afficheTarifCreaMinisiteRCS").css('display', 'none');
            }
        });


        $(document).on('change', '#crm_devis_express_traitementOffertRCS', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesRCS").css('display', 'none');
                $("#afficheTarifDonneesRCS").css('display', 'block');
            } else {
                $("#tarifDonneesRCS").css('display', 'block');
                $("#afficheTarifDonneesRCS").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_typeRCS', function () {

            $("#pxDevis").html('<i class="fas fa-spinner fa-pulse"></i>');

            var pdvIdDevis = $("#selectAgence").val();

            if (!pdvIdDevis) {
                pdvIdDevis = "-1";
            }

            $.ajax({
                type: "POST",
                cache: false,
                global: false,
                data: {
                    "idPdv": pdvIdDevis,
                    "typeRcs" : $("#crm_devis_express_typeRCS").val()
                },
                url: $("#crm_devis_express_prixProduitSMS").data("route"),
                dataType: "json",
                success: function (json) {
                    $("#crm_devis_express_prixProduitRCS").val(json.prixRcs);
                    $("#crm_devis_express_prixSetUpRCS").val(json.prixSetUpRcs);
                    reloadPrixDevis();
                }
            });
        });


        /** Partie APPEL FIXE **/
        $(document).on('change', '#crm_devis_express_activeAppelFixe', function () {
            if ($(this).val() == "1") {
                $("#divAPFIXE").css('display', 'block');
                $("#headingProdAppel").removeClass('bg-secondary');
                $("#headingProdAppel").addClass("bg-primary");
            } else {
                $("#divAPFIXE").css('display', 'none');
                $("#headingProdAppel").removeClass('bg-primary');
                $("#headingProdAppel").addClass("bg-secondary");
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientFIXE', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientFIXE").css('display', 'block');
            } else {
                $("#addFichierClientFIXE").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_enregistrementFIXE', function () {
            if ($(this).val() == "Client") {
                $("#addEnregistrementFIXE").css('display', 'none');
            } else {
                $("#addEnregistrementFIXE").css('display', 'block');
            }
        });

        $(document).on('change', '#crm_devis_express_audioOffertFIXE', function () {
            if ($(this).val() == "1") {
                $("#tarifAudioFIXE").css('display', 'none');
                $("#afficheTarifAudioFIXE").css('display', 'block');
            } else {
                $("#tarifAudioFIXE").css('display', 'block');
                $("#afficheTarifAudioFIXE").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_traitementOffertFIXE', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesFIXE").css('display', 'none');
                $("#afficheTarifDonneesFIXE").css('display', 'block');
            } else {
                $("#tarifDonneesFIXE").css('display', 'block');
                $("#afficheTarifDonneesFIXE").css('display', 'none');
            }
        });


        /** Partie APPEL MOBILE **/
        $(document).on('change', '#crm_devis_express_activeAppelMobile', function () {
            if ($(this).val() == "1") {
                $("#divAPMOBILE").css('display', 'block');
                $("#headingProdMob").removeClass('bg-secondary');
                $("#headingProdMob").addClass("bg-primary");
            } else {
                $("#divAPMOBILE").css('display', 'none');
                $("#headingProdMob").removeClass('bg-primary');
                $("#headingProdMob").addClass("bg-secondary");
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteClientMOBILE', function () {
            if ($(this).val() > 0) {
                $("#addFichierClientMOBILE").css('display', 'block');
            } else {
                $("#addFichierClientMOBILE").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_enregistrementMOBILE', function () {
            if ($(this).val() == "Client") {
                $("#addEnregistrementMOBILE").css('display', 'none');
            } else {
                $("#addEnregistrementMOBILE").css('display', 'block');
            }
        });

        $(document).on('change', '#crm_devis_express_audioOffertMOBILE', function () {
            if ($(this).val() == "1") {
                $("#tarifAudioMOBILE").css('display', 'none');
                $("#afficheTarifAudioMOBILE").css('display', 'block');
            } else {
                $("#tarifAudioMOBILE").css('display', 'block');
                $("#afficheTarifAudioMOBILE").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_devis_express_traitementOffertMOBILE', function () {
            if ($(this).val() == "1") {
                $("#tarifDonneesMOBILE").css('display', 'none');
                $("#afficheTarifDonneesMOBILE").css('display', 'block');
            } else {
                $("#tarifDonneesMOBILE").css('display', 'block');
                $("#afficheTarifDonneesMOBILE").css('display', 'none');
            }
        });

        /** Partie Autre **/
        $(document).on('change', '#crm_devis_express_activeAutre', function () {
            if($(this).val() == "1"){
                $("#divAutre").css('display', 'block');
                $("#headingProdAutr").removeClass('bg-secondary');
                $("#headingProdAutr").addClass("bg-primary");
            }else{
                $("#divAutre").css('display', 'none');
                $("#headingProdAutr").removeClass('bg-primary');
                $("#headingProdAutr").addClass("bg-secondary");
            }
        });

        $(document).on('change', '#crm_devis_express_choixProd', function () {
            if($(this).val() == "SPOTI"){
                $("#addSpoti").css('display', 'flex');
                $("#addSpoti2").css('display', 'flex');
                if($("#crm_devis_express_texteAutre").val() == ""){
                    $("#crm_devis_express_texteAutre").val("Achat d'espace publicitaire");
                }
                if($("#crm_devis_express_texteSpotiPresta").val() == ""){
                    $("#crm_devis_express_texteSpotiPresta").val("Conception du visuel, enregistrement du spot audio, paramétrage et suivi de la campagne, rassemblement des statistiques en fin de campagne");
                }
            }else{
                $("#addSpoti").css('display', 'none');
                $("#addSpoti2").css('display', 'none');
            }
        });

        /** Gestion du nombre contact conquete */
        $(document).on('focusout', '#crm_devis_express_quantiteConqueteDATA', function () {
            if ($(this).val() > 0) {
                qteConquete = $(this).val();

                $(".affTotConquete").css('display', 'block');
                $(".nbTotConquete").html(Intl.NumberFormat("fr-FR").format(qteConquete));
            } else {
                $(".affTotConquete").css('display', 'none');
            }
        });

        $(document).on('focusout', '#crm_devis_express_quantiteConqueteSMS, #crm_devis_express_quantiteConqueteVMS, #crm_devis_express_quantiteConqueteMMS, #crm_devis_express_quantiteConqueteEMAIL, #crm_devis_express_quantiteConqueteFIXE, #crm_devis_express_quantiteConqueteMOBILE', function () {
            qteConquete = $("#crm_devis_express_quantiteConqueteDATA").val();

            qteConqueteToT = qteConquete - $("#crm_devis_express_quantiteConqueteSMS").val() - $("#crm_devis_express_quantiteConqueteVMS").val() - $("#crm_devis_express_quantiteConqueteMMS").val() - $("#crm_devis_express_quantiteConqueteEMAIL").val() - $("#crm_devis_express_quantiteConqueteFIXE").val() - $("#crm_devis_express_quantiteConqueteMOBILE").val();

            $(".affTotConquete").css('display', 'block');
            $(".nbTotConquete").html(Intl.NumberFormat("fr-FR").format(qteConqueteToT));
            if (qteConqueteToT < 0) {
                $.alert({
                    title: 'Erreur:',
                    content: "Il n'y a pas assez de contact DATA pour saisir ce nombre de contact conquête.",
                    type: 'red',
                });
            }
        });


        /** Calcul Prix devis **/

        $(document).on('change', '#selectAgence, #crm_devis_express_activeData, #crm_devis_express_activeAppelMobile, #crm_devis_express_activeAutre, #crm_devis_express_activeAppelFixe, #crm_devis_express_activeEmail, #crm_devis_express_exportOffertDATA, #crm_devis_express_activeSms, #crm_devis_express_activeMms, #crm_devis_express_activeVms, #crm_devis_express_audioOffertVMS, #crm_devis_express_audioOffertMOBILE, #crm_devis_express_audioOffertFIXE, #crm_devis_express_miniSiteSMS, #crm_devis_express_miniSiteMMS, #crm_devis_express_miniSiteEMAIL, #crm_devis_express_nbSMS, #crm_devis_express_creaOffertMMS, #crm_devis_express_creaOffertEMAIL, #crm_devis_express_miniSiteOffertSMS, #crm_devis_express_miniSiteOffertMMS, #crm_devis_express_miniSiteOffertEMAIL, #crm_devis_express_traitementOffertSMS, #crm_devis_express_traitementOffertVMS, #crm_devis_express_traitementOffertMMS, #crm_devis_express_traitementOffertEMAIL, #crm_devis_express_traitementOffertFIXE, #crm_devis_express_traitementOffertMOBILE, #crm_devis_express_codeRemise , #crm_devis_express_activeRcs,#crm_devis_express_creaOffertRCS,#crm_devis_express_traitementOffertRCS, #crm_devis_express_miniSiteRCS, #crm_devis_express_miniSiteOffertRCS', function () {
            reloadPrixDevis();
        });

        $(document).on('focusout', '#crm_devis_express_quantiteConqueteDATA, #crm_devis_express_prixTraitementDonneesMOBILE, #crm_devis_express_prixEnregistrementAppelMOBILE, #crm_devis_express_prixProduitMOBILE, #crm_devis_express_prixTraitementDonneesFIXE, #crm_devis_express_prixEnregistrementAppelFIXE, #crm_devis_express_prixProduitFIXE, #crm_devis_express_prixTraitementDonneesEMAIL, #crm_devis_express_prixMiniSiteEMAIL, #crm_devis_express_prixCreaEMAIL, #crm_devis_express_prixProduitEMAIL, #crm_devis_express_prixTraitementDonneesMMS, #crm_devis_express_prixMiniSiteMMS, #crm_devis_express_prixCreaMMS, #crm_devis_express_prixTraitementDonneesSMS, #crm_devis_express_prixProduitMMS, #crm_devis_express_prixTraitementDonneesVMS, #crm_devis_express_prixEnregistrementAppelVMS, #crm_devis_express_prixProduitVMS, #crm_devis_express_prixFraisExportationDATA, #crm_devis_express_prixMiniSiteSMS, #crm_devis_express_prixLocationContactDATA, #crm_devis_express_prixProduitSMS,#crm_devis_express_quantiteConqueteSMS, #crm_devis_express_quantiteConqueteVMS, #crm_devis_express_quantiteConqueteMMS, #crm_devis_express_quantiteConqueteEMAIL, #crm_devis_express_quantiteConqueteFIXE, #crm_devis_express_quantiteConqueteMOBILE,#crm_devis_express_quantiteClientSMS, #crm_devis_express_quantiteClientVMS, #crm_devis_express_quantiteClientMMS, #crm_devis_express_quantiteClientEMAIL, #crm_devis_express_prixtotalAutre,#crm_devis_express_prixSpotiPresta, #crm_devis_express_quantiteClientFIXE, #crm_devis_express_quantiteClientMOBILE, #crm_devis_express_montantSurcout, #crm_devis_express_quantiteClientRCS,#crm_devis_express_prixProduitRCS, #crm_devis_express_quantiteConqueteRCS,#crm_devis_express_prixCreaRCS,#crm_devis_express_prixTraitementDonneesRCS, #crm_devis_express_prixMiniSiteRCS ,#crm_devis_express_prixSetUpRCS', function () {
            reloadPrixDevis();
        });

        $(document).on('focusout', '#crm_devis_express_percentRemise', function () {
            reloadPrixDevis();
            var prixVirg = $(this).val();
            $(this).val(prixVirg.replace(",","."));
        });

        // prix du produit
        $(document).on('change', '#selectAgence', function () {
            var pdvIdDevis = $("#selectAgence").val();

            if (!pdvIdDevis) {
                pdvIdDevis = "-1";
            }

            $.ajax({
                type: "POST",
                cache: false,
                global: false,
                data: {
                    "idPdv": pdvIdDevis,
                    "typeRcs" : $("#crm_devis_express_typeRCS").val()
                },
                url: $("#crm_devis_express_prixProduitSMS").data("route"),
                dataType: "json",
                success: function (json) {
                    $("#crm_devis_express_prixProduitSMS").val(json.prixSms);
                    $("#crm_devis_express_prixProduitVMS").val(json.prixVms);
                    $("#crm_devis_express_prixProduitMMS").val(json.prixMms);
                    $("#crm_devis_express_prixProduitEMAIL").val(json.prixEmail);
                    $("#crm_devis_express_prixFraisExportationDATA").val(json.prixExportData);
                    $("#crm_devis_express_prixLocationContactDATA").val(json.prixLocaData);
                    $("#crm_devis_express_prixProduitRCS").val(json.prixRcs);
                    $("#crm_devis_express_prixSetUpRCS").val(json.prixSetUpRcs);
                    $("#crm_devis_express_prixProduitFIXE").val(json.prixAppelFixe);
                    $("#crm_devis_express_prixProduitMOBILE").val(json.prixAppelPortable);
                    reloadPrixDevis();
                }
            });

        });

        function reloadPrixDevis() {

                $("#pxDevis").html('<i class="fas fa-spinner fa-pulse"></i>');
                $.ajax({
                    type: "POST",
                    cache: false,
                    global: false,
                    data: {
                        "activeData": $("#crm_devis_express_activeData").val(),
                        "qteCqtTotal": $("#crm_devis_express_quantiteConqueteDATA").val(),
                        "exportConqFree": $("#crm_devis_express_exportOffertDATA").val(),
                        "pxExportConq": $("#crm_devis_express_prixFraisExportationDATA").val(),
                        "pxLocaConq": $("#crm_devis_express_prixLocationContactDATA").val(),

                        "activeSms": $("#crm_devis_express_activeSms").val(),
                        "prixSms": $("#crm_devis_express_prixProduitSMS").val(),
                        "nbSms": $("#crm_devis_express_nbSMS").val(),
                        "qteCliSms": $("#crm_devis_express_quantiteClientSMS").val(),
                        "qteCqtSms": $("#crm_devis_express_quantiteConqueteSMS").val(),
                        "miniSiteSms": $("#crm_devis_express_miniSiteSMS").val(),
                        "miniSiteFreeSms": $("#crm_devis_express_miniSiteOffertSMS").val(),
                        "pxMiniSiteSms": $("#crm_devis_express_prixMiniSiteSMS").val(),
                        "traitementCliFreeSms": $("#crm_devis_express_traitementOffertSMS").val(),
                        "pxTraitementCliSms": $("#crm_devis_express_prixTraitementDonneesSMS").val(),

                        "activeVms": $("#crm_devis_express_activeVms").val(),
                        "prixVms": $("#crm_devis_express_prixProduitVMS").val(),
                        "qteCliVms": $("#crm_devis_express_quantiteClientVMS").val(),
                        "qteCqtVms": $("#crm_devis_express_quantiteConqueteVMS").val(),
                        "audioScenaVms": $("#crm_devis_express_enregistrementVMS").val(),
                        "audioFreeVms": $("#crm_devis_express_audioOffertVMS").val(),
                        "pxaudioVms": $("#crm_devis_express_prixEnregistrementAppelVMS").val(),
                        "traitementCliFreeVms": $("#crm_devis_express_traitementOffertVMS").val(),
                        "pxTraitementCliVms": $("#crm_devis_express_prixTraitementDonneesVMS").val(),

                        "activeMms": $("#crm_devis_express_activeMms").val(),
                        "prixMms": $("#crm_devis_express_prixProduitMMS").val(),
                        "qteCliMms": $("#crm_devis_express_quantiteClientMMS").val(),
                        "qteCqtMms": $("#crm_devis_express_quantiteConqueteMMS").val(),
                        "creaFreeMms": $("#crm_devis_express_creaOffertMMS").val(),
                        "pxCreaMms": $("#crm_devis_express_prixCreaMMS").val(),
                        "miniSiteMms": $("#crm_devis_express_miniSiteMMS").val(),
                        "miniSiteFreeMms": $("#crm_devis_express_miniSiteOffertMMS").val(),
                        "pxMiniSiteMms": $("#crm_devis_express_prixMiniSiteMMS").val(),
                        "traitementCliFreeMms": $("#crm_devis_express_traitementOffertMMS").val(),
                        "pxTraitementCliMms": $("#crm_devis_express_prixTraitementDonneesMMS").val(),

                        "activeEmail": $("#crm_devis_express_activeEmail").val(),
                        "prixEmail": $("#crm_devis_express_prixProduitEMAIL").val(),
                        "qteCliEmail": $("#crm_devis_express_quantiteClientEMAIL").val(),
                        "qteCqtEmail": $("#crm_devis_express_quantiteConqueteEMAIL").val(),
                        "creaFreeEmail": $("#crm_devis_express_creaOffertEMAIL").val(),
                        "pxCreaEmail": $("#crm_devis_express_prixCreaEMAIL").val(),
                        "miniSiteEmail": $("#crm_devis_express_miniSiteEMAIL").val(),
                        "miniSiteFreeEmail": $("#crm_devis_express_miniSiteOffertEMAIL").val(),
                        "pxMiniSiteEmail": $("#crm_devis_express_prixMiniSiteEMAIL").val(),
                        "traitementCliFreeEmail": $("#crm_devis_express_traitementOffertEMAIL").val(),
                        "pxTraitementCliEmail": $("#crm_devis_express_prixTraitementDonneesEMAIL").val(),

                        "activeRcs": $("#crm_devis_express_activeRcs").val(),
                        "prixRcs": $("#crm_devis_express_prixProduitRCS").val(),
                        "qteCliRcs": $("#crm_devis_express_quantiteClientRCS").val(),
                        "qteCqtRcs": $("#crm_devis_express_quantiteConqueteRCS").val(),
                        "typeRcs": $("#crm_devis_express_typeRCS").val(),
                        "pxSetUpRcs": $("#crm_devis_express_prixSetUpRCS").val(),
                        "miniSiteRcs": $("#crm_devis_express_miniSiteRCS").val(),
                        "miniSiteFreeRcs": $("#crm_devis_express_miniSiteOffertRCS").val(),
                        "pxMiniSiteRcs": $("#crm_devis_express_prixMiniSiteRCS").val(),
                        "traitementCliFreeRcs": $("#crm_devis_express_traitementOffertRCS").val(),
                        "pxTraitementCliRcs": $("#crm_devis_express_prixTraitementDonneesRCS").val(),

                        "activeFixe": $("#crm_devis_express_activeAppelFixe").val(),
                        "prixAppelFixe": $("#crm_devis_express_prixProduitFIXE").val(),
                        "qteCliFixe": $("#crm_devis_express_quantiteClientFIXE").val(),
                        "qteCqtFixe": $("#crm_devis_express_quantiteConqueteFIXE").val(),
                        "audioScenaFixe": $("#crm_devis_express_enregistrementFIXE").val(),
                        "audioFreeFixe": $("#crm_devis_express_audioOffertFIXE").val(),
                        "pxaudioFixe": $("#crm_devis_express_prixEnregistrementAppelFIXE").val(),
                        "traitementCliFreeFixe": $("#crm_devis_express_traitementOffertFIXE").val(),
                        "pxTraitementCliFixe": $("#crm_devis_express_prixTraitementDonneesFIXE").val(),

                        "activeMobile": $("#crm_devis_express_activeAppelMobile").val(),
                        "prixAppelPortable": $("#crm_devis_express_prixProduitMOBILE").val(),
                        "qteCliPortable": $("#crm_devis_express_quantiteClientMOBILE").val(),
                        "qteCqtPortable": $("#crm_devis_express_quantiteConqueteMOBILE").val(),
                        "audioScenaPortable": $("#crm_devis_express_enregistrementMOBILE").val(),
                        "audioFreePortable": $("#crm_devis_express_audioOffertMOBILE").val(),
                        "pxaudioPortable": $("#crm_devis_express_prixEnregistrementAppelMOBILE").val(),
                        "traitementCliFreePortable": $("#crm_devis_express_traitementOffertMOBILE").val(),
                        "pxTraitementCliPortable": $("#crm_devis_express_prixTraitementDonneesMOBILE").val(),

                        "activeAutre": $("#crm_devis_express_activeAutre").val(),
                        "prixtotalAutre": $("#crm_devis_express_prixtotalAutre").val(),
                        "prixSpotiPresta": $("#crm_devis_express_prixSpotiPresta").val(),
                        "choixProdAutre": $("#crm_devis_express_choixProd").val(),

                        "pxRemise": $("#crm_devis_express_percentRemise").val(),
                        "codeRemise": $("#crm_devis_express_codeRemise").val(),
                        "pxSurcout": $("#crm_devis_express_montantSurcout").val(),
                    },
                    url: $("#pxDevis").data("route"),
                    dataType: "json",
                    success: function (json) {
                        $("#pxDevis").html(json.prixDevis);
                        $("#crm_devis_express_montantRemise").val(json.mntRemise);
                        $("#mntRem").html(json.infosMntRemise);
                    }
                });
        }
        /** FIN calcul Prix devis **/


        $(document).on('click', '.stopDemande', function (e) {
            e.preventDefault();
            urlDeleteDem = $(this).data("route");
            $.confirm({
                title: 'Supprimer le devis',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer le devis ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDeleteDem,
                                dataType: "json",
                                success: function (json) {
                                    location.reload();
                                },
                                statusCode: {
                                    400: function (data) {
                                        $.alert({
                                            title: 'Erreur:',
                                            content: data.responseJSON.message,
                                            type: 'red',
                                        });
                                    }
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });


        $(document).on('click', '.comDevCrm', function (e) {
            e.preventDefault();
            urlCommentaireDem = $(this).data("route");
            $.ajax({
                type: "POST",
                cache: false,
                url: urlCommentaireDem,
                dataType: "json",
                success: function (data) {
                    $.alert({
                        theme: 'dark',
                        title: 'Commentaire:',
                        content: data.html,
                        type: 'blue',
                        buttons: {
                            cancel: {
                                btnClass: 'btn-blue',
                                text: 'Ok'
                            },
                        }
                    });
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });

        $(document).on('click', '.retourEditionDevis', function (e) {
            e.preventDefault();
            urlUndoDem = $(this).data("route");
            $.confirm({
                title: 'Renvoyer le devis en édition',
                type: 'blue',
                content: 'Voulez-vous vraiment renvoyer le devis en édition ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-green',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlUndoDem,
                                dataType: "json",
                                success: function (json) {
                                    location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        })
    },

    demandeAssistance: function () {
        $(document).on('click', '#sendAssistance', function (e) {
            e.preventDefault();
            $.ajax({
                type: "POST",
                cache: false,
                url: $(this).data("route"),
                data: {
                    "sujet": $("#sujetAssistance").val(),
                    "msg": $("#msgAssistance").val(),
                    "tel": $("#telAssistance").val(),
                    "email": $("#emailAssistance").val(),
                },
                success: function (data) {
                    $('#alertBoxAssist').removeClass("d-none");
                    $('#messageBoxAssist').html(data.message);
                },
                statusCode: {
                    400: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });
    },

    menuLoader: function () {
        $('body').append('<div class="loader"></div>');
        $(".loader").show();
    },

    hideLoader: function () {
        $(document).on('click', '.hide-loader', function (e) {
            e.preventDefault();
            $(window).off('beforeunload', null);
            window.location = $(this).attr('href');
        });
    },

    //permet de scroller sur l'input où il y a l'erreur qui est cachée par la navbar
    scrollToError: function (form) {
        var myform = $(form);
        var navbar = $('.navbar-fixed-top');

        myform.find(':input').on('invalid', function (event) {
            var input = $(this);
            var first = myform.find(':invalid').first();

            if (input[0] === first[0]) {
                var navbarHeight = navbar.height() + 50;
                var elementOffset = input.offset().top - navbarHeight;
                var pageOffset = window.pageYOffset - navbarHeight;

                if (elementOffset > pageOffset && elementOffset < pageOffset + window.innerHeight) {
                    return true
                }
                $('html,body').scrollTop(elementOffset);
            }
        })
    },

    triDateCampagne: function () {
        $("#tri_date_campagne").on('change', function () {
            var triDate = $(this).find(":selected").val();
            var url = $(this).data('route');
            var newUrl = url + "tri_date_campagne/" + triDate;
            window.location = newUrl;
        });
    },

    reinitFilterMiniSite: function () {
        $('#miniSiteReinitFilter').on('change', function (e) {
            var url = $(this).data('route');
            window.location.replace(url);
        })
    },

    applyFilterMiniSite: function () {
        if ($('#themesAutre input[type="checkbox"]').is(':checked')) {
            $('#collapseThemesAutre').addClass('show');
        }

        $('#filterMiniSite input[name!="term"],#filterMiniSite button[type!="submit"]').on('change', function () {
            var myForm = $("#filterMiniSite-form")[0];
            myForm.submit();
        })
    },

    confirmDeleteMiniSite: function () {
        $('.deleteMiniSite').on('click', function (e) {
            e.preventDefault();
            var urlDelete = $(this).data('route');
            $.confirm({
                title: 'Supprimer la landing page',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer cette landing page ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlDelete;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });

        $('.showMiniSite').on('click', function (e) {
            e.preventDefault();
            var urlUpdate = $(this).data('route');
            $.confirm({
                title: 'Afficher la landing page',
                type: 'green',
                content: 'Voulez-vous vraiment réafficher cette landing page ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-green',
                        action: function () {
                            window.location = urlUpdate;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });

        $('.hideMiniSite').on('click', function (e) {
            e.preventDefault();
            var urlUpdate = $(this).data('route');
            $.confirm({
                title: 'Cacher la landing page',
                type: 'red',
                content: 'Voulez-vous vraiment cacher cette landing page ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlUpdate;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    confirmDuplicateMiniSite: function () {
        $('.duplicateMiniSite').on('click', function (e) {
            e.preventDefault();
            var urlDelete = $(this).data('route');
            var typeModel = $(this).data('typeModel');
            var contentText = (typeModel == 0 ? 'Ceci est un modèle Phone-Contact, l\'édition est impossible, souhaitez-vous le dupliquer ?' : 'Voulez-vous dupliquer ce modèle ?');
            $.confirm({
                title: 'Dupliquer la landing page',
                type: 'red',
                content: contentText,
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            window.location = urlDelete;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        })
    },

    confirmDemandeMiniSitePremium: function () {

        $(".demandePremium").on("click", function (e) {
            e.preventDefault();
            var urlDemande = $(this).data('route');
            $.confirm({
                title: 'Landing page à la carte',
                type: 'green',
                content: 'Voulez-vous faire une demande de landing page à la carte ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-green',
                        action: function () {
                            window.location = urlDemande;
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });

        });

    },

    integrationMiniSite: function () {
        $(document).on('click', '#deleteChoixModelMiniSite', function (e) {
            e.preventDefault();
            $('#modeleMiniSiteId').val(0);
            $('#urlMiniSiteLength').val(0);
            $('#modeleMiniSiteNom').html("");
            var chaine = $('.inteMiniSite').val();
            var nouvChaine = chaine.replace(/\{\#lien_landing\#\}/gi, '');
            $('.inteMiniSite').val(nouvChaine);
            $('#campagne_donnee_sms_message').trigger('keyup');
        });
    },

    disableKeySubmitForm: function () {
        $(document).on('keydown', '.disSubForm', function (e) {
            if ((e.keyCode == 13)) {
                e.preventDefault();
                return false;
            }
        });
    },

    paginationPlaningCampagne: function () {
        $(document).on('click', '.pagiPlanCamp a', function (e) {
            if ($("#filterType").val() != "" || $("#filterValue").val() != "" || $("#compte_prince").val() != "" || $("#compte_camp").val() != "") {
                e.preventDefault();
                var url = $(this).attr('href');
                console.log(url);
                if (url == '#') {
                    return;
                }


                campTerm = $("#planningTerm").val();

                if (campTerm) {
                    campTerm = 1;
                } else {
                    campTerm = 0;
                }

                $.ajax({
                    type: 'GET',
                    url: url,
                    cache: false,
                    data: {
                        "filterType": $("#filterType").val(),
                        "filterValue": $("#filterValue").val(),
                        "compteCamp": $("#compte_camp").val(),
                        "comptePrince": $("#compte_prince").val(),
                        "campTerm": campTerm,
                    },
                    dataType: 'json',
                    success: function (data) {
                        $('#list-tab').html(data.html);
                        $('#list-1').trigger('click');
                        $('#paginate').html(data.paginate);
                        $('[data-toggle="tooltip"]').tooltip();
                    }
                });
            }
        });
    },

    crmAddPdv: function () {
        $(document).on('change', '#crm_compte_campagne_comptePrincipal', function () {
            $.ajax({
                type: "POST",
                cache: false,
                url: $(this).data("route"),
                data: {
                    "idCp": $(this).val(),
                },
                success: function (data) {
                    $('#divInterlo').html(data.html);

                    $.fn.select2.defaults.set('language', 'fr');
                    $('#selectInterloc').select2({
                        escapeMarkup: function (markup) {
                            return markup;
                        },
                    }
                    );
                },
                statusCode: {
                    400: function (data) {

                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });

        $(document).on('change', '#selectInterloc', function () {
            if ($(this).val() == "0") {
                $("#newInterloc").css('display', 'block');
            } else {
                $("#newInterloc").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_compte_campagne_memeAdresseFacturation, #crm_creation_compte_memeAdresseFacturation', function () {
            if ($(this).val() == "0") {
                $("#divAdresseFact").css('display', 'block');
            } else {
                $("#divAdresseFact").css('display', 'none');
            }
        });

        $(document).on('change', '#crm_compte_campagne_tarifPerso, #crm_creation_compte_tarifPerso', function () {
            if ($(this).val() == "1") {
                $("#divPrix").css('display', 'block');
            } else {
                $("#divPrix").css('display', 'none');
            }
        });

        $(document).on('focusout', '#crm_compte_campagne_nom, #crm_creation_compte_nomPointVente', function () {
            if ($(this).val() != "" && $(this).val().length > 3) {
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: $(this).data("route"),
                    data: {
                        "nom": $(this).val(),
                    },
                    success: function (data) {
                        $('#text-retour').html('<i class="ml-2 fas fa-check text-success"></i>');

                    },
                    statusCode: {
                        400: function (data) {
                            $('#text-retour').html('');
                            $.alert({
                                title: '<i class="fas fa-exclamation-triangle mr-2"></i> Attention:',
                                content: data.responseJSON.message + "<br>" + "<b>Nom du point de vente :</b><br>" + data.responseJSON.nomPdv + "<br>" + (data.responseJSON.onlinePdv ? "" : "<br><em class='text-warning'>Mais ce point de vente n'est plus actif.</em>"),
                                type: 'orange',
                            });
                        }
                    }
                });
            }
        });

        $(document).on('focusout', '#crm_creation_compte_nomEntreprise', function () {
            if ($(this).val() != "" && $(this).val().length > 3) {
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: $(this).data("route"),
                    data: {
                        "nom": $(this).val(),
                    },
                    success: function (data) {
                        $('#text-retour-prince').html('<i class="ml-2 fas fa-check text-success"></i>');

                    },
                    statusCode: {
                        400: function (data) {
                            $('#text-retour-prince').html('');
                            $.alert({
                                title: '<i class="fas fa-exclamation-triangle mr-2"></i> Attention:',
                                content: data.responseJSON.message + "<br>" + "<b>Nom du compte principal :</b><br>" + data.responseJSON.nomComptePrince + "<br>" + (data.responseJSON.onlineComptePrince ? "" : "<br><em class='text-warning'>Mais ce compte principal n'est plus actif.</em>"),
                                type: 'orange',
                            });
                        }
                    }
                });
            }
        });


        $(document).on('focusout', '#crm_devis_express_emailInterlocuteur, #crm_compte_campagne_emailInterlocuteur, #crm_creation_compte_email, #crm_user_email', function () {
            if ($(this).val() != "" && $(this).val().length > 3) {
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: $(this).data("route"),
                    data: {
                        "email": $(this).val(),
                    },
                    success: function (data) {
                        $('#text-retour-interloc').html('<i class="ml-2 fas fa-check text-success"></i>');

                    },
                    statusCode: {
                        400: function (data) {
                            $('#text-retour-interloc').html('');
                            $.alert({
                                title: '<i class="fas fa-exclamation-triangle mr-2"></i> Attention:',
                                content: data.responseJSON.message + "<br>" + "<b>Groupe principal :</b><br/>" + data.responseJSON.groupInterlocuteur + "<br/><b>Nom de l'utilisateur :</b><br>" + data.responseJSON.nomInterlocuteur + "<br>" + (data.responseJSON.onlineInterlocuteur ? "" : "<br><em" +
                                    " class='text-warning'>Mais cet utilisateur n'est plus actif.</em>"),
                                type: 'orange',
                            });
                        }
                    }
                });
            }
        });

        $(document).on('change', '#crm_user_comptePrincipal', function () {
            $.ajax({
                type: "POST",
                cache: false,
                url: $(this).data("route"),
                data: {
                    "idCp": $(this).val(),
                },
                success: function (data) {
                    $('#divCompteCamp').html(data.html);

                    $.fn.select2.defaults.set('language', 'fr');
                    $('#selectAgence').select2({
                        multiple: true,
                        placeholder: "Sélectionnez un ou plusieurs point(s) de vente",
                    }
                    );
                },
                statusCode: {
                    400: function (data) {

                        $.alert({
                            title: 'Erreur:',
                            content: data.responseJSON.message,
                            type: 'red',
                        });
                    }
                }
            });
        });

        if ($("#crm_creation_compte_typeCompte").length) {
            $('#crm_creation_compte_typeCompte').select2();
        }

    },
    profilia: function (initValues) {
        if ($.isEmptyObject(initValues)) {
            $("#slider-range").slider({
                range: true,
                min: 18,
                max: 100,
                values: [18, 65],
                slide: function (event, ui) {
                    $("#inputAge").val(ui.values[0] + " - " + ui.values[1]);
                },
                stop: function (event, ui) {
                    $("#profilia_demande_prospect_ageDebut").val(ui.values[0]);
                    $("#profilia_demande_prospect_ageFin").val(ui.values[1]);
                }
            });
        } else {
            $("#slider-range").slider({
                range: true,
                min: 18,
                max: 100,
                values: [parseInt(initValues.ageDebut), parseInt(initValues.ageFin)],
                slide: function (event, ui) {
                    $("#inputAge").val(ui.values[0] + " - " + ui.values[1]);
                },
                stop: function (event, ui) {
                    $("#profilia_demande_prospect_ageDebut").val(ui.values[0]);
                    $("#profilia_demande_prospect_ageFin").val(ui.values[1]);
                }
            });

            //init des valeurs des onglets
            for (tabInfo in initValues.tabDatas) {
                $('#' + tabInfo).html(initValues.tabDatas[tabInfo]);

                INTERFACE_FRONT._bootstrapActiveTabControlEdit(tabInfo, initValues.tabDatas[tabInfo]);
            };

            // init tab
            INTERFACE_FRONT._bootstrapActiveTabControlEditEnd();
        }

        $("#inputAge").val($("#slider-range").slider("values", 0) + " - " + $("#slider-range").slider("values", 1));
        $("#profilia_demande_prospect_ageDebut").val($("#slider-range").slider("values", 0));
        $("#profilia_demande_prospect_ageFin").val($("#slider-range").slider("values", 1));

        $("#slider-city-radius").slider({
            min: 0,
            max: 100,
            step: 5,
            disabled: true,
            slide: function (event, ui) {
                $("#profilia_demande_prospect_villeRayon").val(ui.value);
                $("#inputRadius").html(ui.value);
            }
        });


        $(document).on('change', '#customSwitchEmail', function () {
            if ($('#customSwitchEmail').is(':checked')) {
                $("#profilia_demande_prospect_topEmail").val(1);
            } else {
                $("#profilia_demande_prospect_topEmail").val(0);
            }
        });

        $(document).on('change', '#customSwitchMobile', function () {
            if ($('#customSwitchMobile').is(':checked')) {
                $("#profilia_demande_prospect_topTelMobile").val(1);
            } else {
                $("#profilia_demande_prospect_topTelMobile").val(0);
            }
        });

        $(document).on('change', '#customSwitchMobileRens', function () {
            if ($('#customSwitchMobileRens').is(':checked')) {
                $("#profilia_demande_prospect_telMobileRenseigne").val(1);
            } else {
                $("#profilia_demande_prospect_telMobileRenseigne").val(0);
            }
        });

        $(document).on('change', '#customSwitchFixeRens', function () {
            if ($('#customSwitchFixeRens').is(':checked')) {
                $("#profilia_demande_prospect_telFixeRenseigne").val(1);
            } else {
                $("#profilia_demande_prospect_telFixeRenseigne").val(0);
            }
        });

        $(document).on('change', '#customSwitchGrandeSurface', function () {
            if ($('#customSwitchGrandeSurface').is(':checked')) {
                $("#profilia_demande_prospect_topGdeSurf").val(1);
            } else {
                $("#profilia_demande_prospect_topGdeSurf").val(0);
            }
        });

        $(document).on('change', '#customSwitchEmmenagesRecent', function () {
            if ($('#customSwitchFixeRens').is(':checked')) {
                $("#profilia_demande_prospect_topEmmenageRec").val(1);
            } else {
                $("#profilia_demande_prospect_topEmmenageRec").val(0);
            }
        });

        $(document).on('change', '#customSwitchHabitatCollectif', function () {
            if ($('#customSwitchHabitatCollectif').is(':checked')) {
                $("#profilia_demande_prospect_topHabCol").val(1);
            } else {
                $("#profilia_demande_prospect_topHabCol").val(0);
            }
        });

        $(document).on('change', '#customSwitchHabitatIndividuel', function () {
            if ($('#customSwitchHabitatIndividuel').is(':checked')) {
                $("#profilia_demande_prospect_topHabIndiv").val(1);
            } else {
                $("#profilia_demande_prospect_topHabIndiv").val(0);
            }
        });

        $(document).on('change', '#customSwitchProprietaire', function () {
            if ($('#customSwitchProprietaire').is(':checked')) {
                $("#profilia_demande_prospect_topProprio").val(1);
            } else {
                $("#profilia_demande_prospect_topProprio").val(0);
            }
        });

        $(document).on('change', '#customSwitchLocataire', function () {
            if ($('#customSwitchLocataire').is(':checked')) {
                $("#profilia_demande_prospect_topLocat").val(1);
            } else {
                $("#profilia_demande_prospect_topLocat").val(0);
            }
        });

        $(document).on('change', '#customSwitchMenagesAvecEnfant', function () {
            if ($('#customSwitchMenagesAvecEnfant').is(':checked')) {
                $("#profilia_demande_prospect_topMenagEnf").val(1);
            } else {
                $("#profilia_demande_prospect_topMenagEnf").val(0);
            }
        });

        $(document).on('change', '#customSwitch65', function () {
            if ($('#customSwitch65').is(':checked')) {
                $("#profilia_demande_prospect_top65A").val(1);
            } else {
                $("#profilia_demande_prospect_top65A").val(0);
            }
        });

        $(document).on('change', '#customSwitch75', function () {
            if ($('#customSwitch75').is(':checked')) {
                $("#profilia_demande_prospect_top75A").val(1);
            } else {
                $("#profilia_demande_prospect_top75A").val(0);
            }
        });

        $(document).on('change', '#customSwitchCSPPlus', function () {
            if ($('#customSwitchCSPPlus').is(':checked')) {
                $("#profilia_demande_prospect_topCsp").val(1);
            } else {
                $("#profilia_demande_prospect_topCsp").val(0);
            }
        });

        $(document).on('change', '#customSwitchHautsRevenus', function () {
            if ($('#customSwitchHautsRevenus').is(':checked')) {
                $("#profilia_demande_prospect_topHautRev").val(1);
            } else {
                $("#profilia_demande_prospect_topHautRev").val(0);
            }
        });

        $(document).on('change', '#customSwitchTresHautsRevenus', function () {
            if ($('#customSwitchTresHautsRevenus').is(':checked')) {
                $("#profilia_demande_prospect_topTreHautRev").val(1);
            } else {
                $("#profilia_demande_prospect_topTreHautRev").val(0);
            }
        });

        $(document).on('change', '#customSwitchMaison', function () {
            if ($('#customSwitchMaison').is(':checked')) {
                $("#profilia_demande_prospect_topMais").val(1);
            } else {
                $("#profilia_demande_prospect_topMais").val(0);
            }
        });

        $(document).on('change', '#customSwitchAppartement', function () {
            if ($('#customSwitchAppartement').is(':checked')) {
                $("#profilia_demande_prospect_topAppart").val(1);
            } else {
                $("#profilia_demande_prospect_topAppart").val(0);
            }
        });

        $.fn.select2.defaults.set('language', 'fr');
        //        $('#profilia_demande_prospect_secteurActivite').select2({
        //                multiple: false,
        //                placeholder: "Sélectionnez un secteur d'activité",
        //            }
        //        );
        //
        //        $('#profilia_demande_prospect_sexe').select2({
        //                multiple: true,
        //                placeholder: "Sélectionnez un ou plusieurs sexe",
        //            }
        //        );
        //
        //        $('#profilia_demande_prospect_revenus').select2({
        //                multiple: true,
        //                placeholder: "Sélectionnez une ou plusieurs tranche de revenus",
        //            }
        //        );
    },

    findCity: function () {

        var cache = {};
        var url = $("#profilia_demande_prospect_ville").data('route');
        $("#profilia_demande_prospect_ville").on('focus', function (e) {
            $("#slider-city-radius").slider({
                disabled: true,
                value: 0,
                change: function (event, ui) {
                    $("#profilia_demande_prospect_villeRayon").val(ui.value);
                    $("#inputRadius").html(ui.value);
                }
            });
            $(this).val('');
        });

        $("#profilia_demande_prospect_ville").autocomplete({
            minLength: 2,
            source: function (request, response) {
                var term = request.term;
                if (term in cache) {
                    response(cache[term]);
                    return;
                }

                $.ajax({
                    url: url,
                    dataType: "json",
                    global: false,
                    data: {
                        term: request.term
                    },
                    success: function (data) {
                        cache[term] = data;
                        response(data);
                    }
                });
            },

            select: function (event, ui) {
                $('#profilia_demande_prospect_ville').val(ui.item.label);
                $('#profilia_demande_prospect_villeInsee').val(ui.item.value);
                $("#slider-city-radius").slider('enable');
                return false;

            }


        });
    },

    findState: function () {

        var cache = {};
        var url = $("#profilia_demande_prospect_departement").data('route');
        $("#profilia_demande_prospect_departement").autocomplete({
            minLength: 2,
            source: function (request, response) {
                var term = request.term;
                if (term in cache) {
                    response(cache[term]);
                    return;
                }

                $.ajax({
                    url: url,
                    dataType: "json",
                    global: false,
                    data: {
                        term: request.term
                    },
                    success: function (data) {
                        cache[term] = data;
                        response(data);
                    }
                });
            },

            select: function (event, ui) {
                $('#profilia_demande_prospect_departement').val(ui.item.label);
                $('#profilia_demande_prospect_departementInsee').val(ui.item.value);
                return false;

            }


        });
        $('#profilia_demande_prospect_departement').on('focus', function (e) {
            $(this).val('');
        });
    },
    deleteState: function () {
        $(document).off('click', '.btn-delete-state');
        $(document).on('click', '.btn-delete-state', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer le département',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce département ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                    INTERFACE_FRONT._ajaxCompteProspects();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    deleteCity: function () {
        $(document).off('click', '.btn-delete-city');
        $(document).on('click', '.btn-delete-city', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer la ville',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer cette ville ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                    INTERFACE_FRONT._ajaxCompteProspects();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    compteProspects: function () {
        $('.compte-prospects').on('change', function (e) {
            e.preventDefault();
            INTERFACE_FRONT._ajaxCompteProspects();
        });
    },

    _ajaxCompteProspects: function () {
        var form = $("#formDemande")[0];

        if (this.requestCpteProspect && this.requestCpteProspect != 4) {
            this.requestCpteProspect.abort();
        }

        this.requestCpteProspect = $.ajax({
            url: INTERFACE_FRONT.urlCpteProspect,
            data: new FormData(form),
            type: "post",
            cache: false,
            dataType: "json",
            contentType: false,
            processData: false,
            global: false,
            beforeSend: function () {
                $('.nb_prospects').hide();
                $('#loader').show();
            },
            error: function (err) {
                if (err.readyState == 4) {
                    $('.nb_prospects').html(0);
                    $('#profilia_demande_prospect_nbProspects').val(0);
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red'
                    });
                }
            },
            success: function (data) {
                var nf = new Intl.NumberFormat();
                $('.nb_prospects').html(nf.format(data.html));
                for (tabInfo in data.tabDatas) {
                    $('#' + tabInfo).html(data.tabDatas[tabInfo]);
                };
                $('#profilia_demande_prospect_nbProspects').val(data.html);
                $('#unit-price').html(data.prixBase);
                $('#frais-export').html(data.fraisExport);
                $('#global-price').html(data.prixTotal);
                INTERFACE_FRONT._bootstrapActiveTabControl();
            },
            complete: function () {
                $('#loader').hide();
                $('.nb_prospects').show();
            }
        });

    },

    sauveExtractionProspects: function () {
        $(document).on('click', '#sauveExtractionProspects,#ajouterPanierProspects', function (e) {
            e.preventDefault();
            var form = $("#formDemande");
            if ($(this).attr('id').indexOf('Panier') != -1) {
                form.append('<input type="hidden" name="ajouterPanierProspects" value="1">');
            }
            form.submit();
        });
    },
    sauveExtractionProspectsBtoB: function () {
        $(document).on('click', '#sauveExtractionProspects,#ajouterPanierProspects', function (e) {
            e.preventDefault();
            var form = $("#formDemandeBtoB");
            if ($(this).attr('id').indexOf('Panier') != -1) {
                form.append('<input type="hidden" name="ajouterPanierProspects" value="1">');
            }
            form.submit();
        });
    },

    addCity: function () {
        $('#addCity,#addInsee').on('click', function (e) {
            e.preventDefault();

            if ($(this).attr('id').indexOf('addCity') != -1) {
                $('#profilia_demande_prospect_codesInsee').val('');
                $('#profilia_demande_prospect_codesCity').val('');
            }
            if ($(this).attr('id').indexOf('addInsee') != -1) {
                $('#profilia_demande_prospect_codesCity').val('');
                $('#profilia_demande_prospect_villeRayon').val('');
                $('#profilia_demande_prospect_villeInsee').val('');
                $('#profilia_demande_prospect_ville').val('');
            }

            if ($(this).parent().find('textarea').val() === '' || $(this).parent().find('input').val() === '') {

                $.alert({
                    title: 'Erreur:',
                    content: 'Le champ ne peut être vide.',
                    type: 'red'
                });

                return false;

            }

            INTERFACE_FRONT._addCityAjax();
        }
        );
    },
    _addCityAjax: function () {
        var url = $('#profilia_demande_prospect_addCityPath').data('route');
        $.ajax({
            url: url,
            data: {
                'radius_city': $('#profilia_demande_prospect_villeRayon').val(),
                'insee_city': $('#profilia_demande_prospect_villeInsee').val(),
                'codes_insee': $('#profilia_demande_prospect_codesInsee').val(),
                'codes_city': $('#profilia_demande_prospect_codesCity').val(),
                'term': $('#profilia_demande_prospect_ville').val(),
            },
            type: "post",
            cache: false,
            dataType: "json",
            global: false,
            beforeSend: function () {
                $('#profilia_demande_prospect_ville').val('');
            },
            error: function (err) {
                $.alert({
                    title: 'Erreur:',
                    content: err.responseJSON.message,
                    type: 'red'
                });
            },
            success: function (data) {
                if (data.message == 'duplicate') {
                    $.alert({
                        title: 'Information:',
                        content: 'Les villes en double n\'ont pas été ajoutées !',
                        type: 'orange'
                    });
                }

                if (data.message != 'no_result') {
                    $("#locationResults").html(data.html);
                } else {
                    $.alert({
                        title: 'Information:',
                        content: 'Aucune ville ne correspond aux critères !',
                        type: 'orange'
                    });
                }
            },
            complete: function () {
                INTERFACE_FRONT._ajaxCompteProspects();
            }
        });
    },
    checkCity: function () {
        $('#addPostal').on('click', function (e) {
            e.preventDefault();

            $('#profilia_demande_prospect_codesInsee').val('');
            $('#profilia_demande_prospect_villeRayon').val('');
            $('#profilia_demande_prospect_villeInsee').val('');
            $('#profilia_demande_prospect_ville').val('');

            if ($(this).parent().find('textarea').val() === '') {

                $.alert({
                    title: 'Erreur:',
                    content: 'Le champ ne peut être vide.',
                    type: 'red'
                });

                return false;

            }

            var url = $('#profilia_demande_prospect_checkCityPath').data('route');

            $.ajax({
                url: url,
                data: {
                    'codes_city': $('#profilia_demande_prospect_codesCity').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                // error: function (err) {
                //     $.alert({
                //         title: 'Erreur:',
                //         content: err.responseJSON.message,
                //         type: 'red'
                //     });
                // },
                success: function (data) {
                    INTERFACE_FRONT._addCityAjax();
                },
                // complete: function () {
                //     INTERFACE_FRONT._ajaxCompteProspects();
                // },
                statusCode: {
                    400: function (data) {
                        $.fancybox.open({
                            src: data.responseText,
                            type: 'html',
                            afterClose: function () {
                                INTERFACE_FRONT._addCityAjax();
                            },
                        })
                        return false;
                    },
                    404: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: 'Aucune ville ne correspond aux critères !',
                            type: 'red'
                        });
                    }
                }
            });
        });
    },

    chooseCity: function () {
        $(document).on('click', '.choix-ville', function (e) {
            var that = $(this);
            if (INTERFACE_FRONT.envMode == "dev") {
                url = "/app_dev.php/choose-city";
            } else {
                url = "/choose-city";
            }
            $.ajax({
                url: url,
                data: {
                    'identifier_city': that.val(),
                    'action': (that.prop('checked') == true ? 'add' : 'remove')

                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                success: function (data) {

                },
            });
        })
    },

    deleteModeleSms: function () {
        $(document).off('click', '#btn-delete-modele-sms');
        $(document).on('click', '#btn-delete-modele-sms', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer le modèle Sms',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce modèle Sms ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    updateVueMsgMaj: function () {
        $(document).on('click', '#mazMsgMaj', function (e) {
            e.preventDefault();
            var url = $(this).data('route');
            console.log(url);
            $.confirm({
                title: 'Message mise à jour',
                type: 'red',
                content: 'Voulez-vous vraiment remettre à zéro les vues du message de mise à jour ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: url,
                                dataType: "json",
                                success: function (json) {
                                    $.alert({
                                        title: 'Information:',
                                        content: "Message de mise à jour de nouveau visible pour tous.",
                                        type: 'green',
                                    });
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    afficheVague: function () {
        $(document).on('click', '#display-wave', function (e) {
            e.preventDefault();
            $('#sauveCampagne').attr('disabled', 'disabled');
            $('#campagne_typeEnvoi').val('vague');
            var url = $(this).data('route');
            var nbVaguePlan = parseInt($('#nb_vague_planif').text());
            var nbContact = parseInt($('#destinataire_recap').text());
            var nbVague = 1;
            var typeVague = $(this).data('method');

            INTERFACE_FRONT._planifVague(url, nbContact, nbVague, typeVague);
        });

        $(document).on('display-wave', function () {
            //on ferme les autres fancybox car à chaque clic, on en affiche une.
            var instance = $.fancybox.getInstance();
            if (instance.current.src == "#planifVague") {
                $.fancybox.close();
            }
            $.fancybox.open({
                src: "#planifVague"
            }
            );
        });
    },

    addFrance: function () {
        $('#addFrance').on('click', function (e) {
                e.preventDefault();

                var url = $('#profilia_demande_prospect_addFrancePath').data('route');

                $.confirm({
                    title: 'Sélection de zone',
                    type: 'green',
                    content: 'Voulez-vous vraiment sélectionner la France entière ?',
                    buttons: {
                        confirm: {
                            text: 'Oui',
                            btnClass: 'btn-green',
                            action: function () {
                                $.ajax({
                                    url: url,
                                    type: "post",
                                    cache: false,
                                    dataType: "json",
                                    global: false,
                                    error: function (err) {
                                        $.alert({
                                            title: 'Erreur:',
                                            content: err.responseJSON.message,
                                            type: 'red'
                                        });
                                    },
                                    success: function (data) {
                                        if (data.message == 'duplicate') {
                                            $.alert({
                                                title: 'Information:',
                                                content: 'Les villes en double n\'ont pas été ajoutées !',
                                                type: 'orange'
                                            });
                                        }
                                        $("#locationResults").html(data.html);
                                    },
                                    complete: function () {
                                        INTERFACE_FRONT._ajaxCompteProspects();
                                    }
                                });
                            }
                        },
                        cancel: {
                            text: 'Non'
                        }
                    }
                });
            }
        );
    },

    addState: function () {
        $('#addState').on('click', function (e) {
            e.preventDefault();

            var url = $('#profilia_demande_prospect_addStatePath').data('route');
            $.ajax({
                url: url,
                data: {
                    'insee_state': $('#profilia_demande_prospect_departementInsee').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                beforeSend: function () {
                    $('#profilia_demande_prospect_departement').val('');
                },
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red'
                    });
                },
                success: function (data) {
                    if (data.message == 'duplicate') {
                        $.alert({
                            title: 'Information:',
                            content: 'Les villes en double n\'ont pas été ajoutées !',
                            type: 'orange'
                        });
                    }
                    $("#locationResults").html(data.html);
                },
                complete: function () {
                    INTERFACE_FRONT._ajaxCompteProspects();
                }
            });
        }
        );
    },

    _bootstrapTabControl: function () {
        var i, items = $('#formDemande .nav-link'), pane = $('#formDemande .tab-pane');
        // next
        $('.nexttab').on('click', function () {
            for (i = 0; i < items.length; i++) {
                if ($(items[i]).hasClass('active') == true) {
                    break;
                }
            }

            if (i < items.length - 1) {
                // for tab
                $(items[i]).removeClass('active');
                $(items[i + 1]).addClass('active');
                $(items[i + 1]).removeClass('disabled');
                // for pane
                $(pane[i]).removeClass('show active');
                $(pane[i + 1]).addClass('show active');
            }

            $(pane[i + 1]).trigger('paneclic');

        });

        // Prev
        $('.prevtab').on('click', function () {
            for (i = 0; i < items.length; i++) {
                if ($(items[i]).hasClass('active') == true) {
                    break;
                }
            }

            if (i != 0) {
                // for tab
                $(items[i]).removeClass('active');
                $(items[i - 1]).addClass('active');
                // for pane
                $(pane[i]).removeClass('show active');
                $(pane[i - 1]).addClass('show active');
            }

            $(pane[i - 1]).trigger('paneclic');
        });
    },

    _bootstrapActiveTabControl: function () {
        var activeItem = $('.nav-tabs li > a.active');
        var currentActive = activeItem.parent().index();

        if (activeItem.find('.fa-check-circle').length == 0) {
            activeItem.find('.canal-name').append('<i class="fas fa-check-circle ml-2 text-info"></i>');
        }

        $('.nav-tabs li').each(function (index) {
            if (index <= currentActive + 1) {
                $(this).find('a').removeClass('disabled');

                if ($(this).find('a').attr("id") == "pills-secteur-tab") {
                    currentActive = currentActive + 1;
                }
            }
        });
    },

    _displayErrorsProfilia: function (form, errors) {
        var errorList = $("ul.error-messages", form);
        errorList.empty();

        for (error of errors) {
            errorList
                .show()
                .append("<div class='alert alert-dismissible alert-danger fade show' role='alert'>" + error.label + " : " + error.value + "<button type='button' class='close' data-dismiss='alert' aria-label='Fermer'><span aria-hidden='true'>&times;</span></button></div>");
        }

        if (errors.length == 0) {
            errorList.hide();
        } else {
            $(document).scrollTop('#formDemande');
        }
    },

    _setupHiddenInputChangeListener: function (inputElement) {
        const propertyName = 'value';
        const { get: originalGetter, set: originalSetter } = this._findPropertyDescriptor(inputElement, propertyName);

        // We wrap this in a function factory to bind the getter and setter values
        // so later callbacks refer to the correct object, in case we use this
        // method on more than one hidden input element.
        const newPropertyDescriptor = ((_originalGetter, _originalSetter) => {
            return {
                set: function (value) {
                    const currentValue = _originalGetter.call(inputElement);

                    // Delegate the call to the original property setter
                    _originalSetter.call(inputElement, value);

                    // Only fire change if the value actually changed.
                    if (currentValue !== value) {
                        inputElement.dispatchEvent(new Event('change'));
                    }
                },

                get: function () {
                    // Delegate the call to the original property getter
                    return _originalGetter.call(inputElement);
                }
            };
        })(originalGetter, originalSetter);

        Object.defineProperty(inputElement, propertyName, newPropertyDescriptor);
    },

    _findPropertyDescriptor: function (object, propertyName) {
        if (object === null) {
            return null;
        }

        if (object.hasOwnProperty(propertyName)) {
            return Object.getOwnPropertyDescriptor(object, propertyName);
        } else {
            const parentClass = Object.getPrototypeOf(object);

            return this._findPropertyDescriptor(parentClass, propertyName);
        }
    },
    recapProspects: function () {
        $('#recapProspects').on('click', function (e) {
            var form = $("#formDemande")[0];
            var url = $(this).data('route');
            INTERFACE_FRONT._displayErrorsProfilia(form, []);

            $.ajax({
                url: url,
                data: new FormData(form),
                type: "post",
                cache: false,
                dataType: "json",
                contentType: false,
                processData: false,
                error: function (err) {
                    INTERFACE_FRONT._displayErrorsProfilia(form, err.responseJSON.message);

                    $.alert({
                        title: 'Erreur:',
                        content: "Des erreurs ont été détectées, veuillez les corriger svp.",
                        type: 'red'
                    });

                },
                success: function (data) {
                    $("#recapProspectsModal").modal('show');
                }
            });


        });
    },

    afficheDetailsDemande: function () {
        $('.consulter-demande').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('route');

            $.ajax({
                type: "POST",
                cache: false,
                url: url,
                dataType: "html",
                beforeSend: function () {
                    $("#demandeContainer").fadeOut();
                },
                success: function (data) {
                    $("#demandeContainer").html(data).fadeIn();
                }
            });
        });
    },

    supprimeDemande: function () {
        $('.supprime-demande').on('click', function (e) {
            e.preventDefault();
            var idDemande = $(this).data('id');
            var urlDeleteDemande = $(this).data('route');
            $.confirm({
                title: 'Supprimer la demande',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer cette demande ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDeleteDemande,
                                dataType: "json",
                                success: function (json) {
                                    $("#trListeDemande" + idDemande).fadeOut();
                                    $("#demandeContainer").fadeOut();
                                },
                                error: function (err) {
                                    $.alert({
                                        title: 'Erreur:',
                                        content: err.responseJSON.message,
                                        type: 'red',
                                        buttons: {
                                            ok: {
                                                text: 'OK',
                                            }
                                        }
                                    });
                                },
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    envoiDemande: function () {
        $(document).on('click', '.envoi-demande', function (e) {
            e.preventDefault();
            var idDem = $(this).data('demid');
            var coutDemande = $('#coutProfDem' + idDem).html();
            var urlEnvoiDemande = $(this).data('route');
            if ($(this).hasClass('isDisabled')) {
                return;
            }
            $.confirm({
                title: 'Commander cette liste ?',
                type: 'blue',
                content: 'Commander cette liste vous coutera ' + coutDemande + ' <br> Êtes-vous sûr de vouloir continuer ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-blue',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlEnvoiDemande,
                                dataType: "json",
                                success: function (json) {
                                    window.location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    mailDemande: function () {
        $(document).on('click', '.mail-demande', function (e) {
            e.preventDefault();
            var idDem = $(this).data('demid');
            var coutDemande = $('#coutProfDem' + idDem).html();
            var urlEnvoiDemande = $(this).data('route');
            $.confirm({
                title: 'Commander cette liste ?',
                type: 'blue',
                content: 'Commander cette liste vous coutera ' + coutDemande + ' <br> Êtes-vous sûr de vouloir continuer ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-blue',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlEnvoiDemande,
                                dataType: "json",
                                success: function (json) {
                                    window.location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    annuleDemande: function () {
        $(document).on('click', '.annule-demande', function (e) {
            e.preventDefault();
            var idDem = $(this).data('demid');
            var urlAnnuleDemande = $(this).data('route');
            $.confirm({
                title: 'Annuler cette commande ?',
                type: 'blue',
                content: 'Êtes-vous sûr de vouloir annuler cette commande ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-blue',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlAnnuleDemande,
                                dataType: "json",
                                success: function (json) {
                                    window.location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    convertDemande: function () {
        $(document).on('click', '.convert-demande', function (e) {
            e.preventDefault();
            var idDemande = $(this).data('id');
            var urlConvertDemande = $(this).data('route');
            $.confirm({
                title: 'Exporter le fichier',
                type: 'blue',
                content: 'Voulez-vous vraiment exporter ce fichier en répertoire ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-blue',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlConvertDemande,
                                dataType: "html",
                                success: function (data) {
                                    window.location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    validDemande: function () {
        $(document).on('click', '.valide-demande', function (e) {
            e.preventDefault();
            var idDemande = $(this).data('demid');
            var urlValidDemande = $(this).data('route');
            $.confirm({
                title: 'Valider la demande',
                type: 'blue',
                content: 'Voulez-vous vraiment valider la demande ' + idDemande + ' ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-blue',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlValidDemande,
                                dataType: "html",
                                success: function (data) {
                                    window.location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    updateDemande: function () {
        $(document).on('click', '.update-demande', function (e) {
            e.preventDefault();
            var idDemande = $(this).data('id');
            var urlUpdateDemande = $(this).data('route');
            $.confirm({
                title: 'Mettre à jour la demande',
                type: 'blue',
                content: 'Voulez-vous vraiment mettre à jour cette demande ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-blue',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlUpdateDemande,
                                dataType: "html",
                                success: function (data) {
                                    window.location.reload();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    updateCoutProspect: function () {
        $('#volumeProspects').on('change', function (e) {
            e.preventDefault();
            $('#profilia_demande_prospect_volume').val($('#volumeProspects').val());
            INTERFACE_FRONT._updateCoutProspect();
        });
    },

    _updateCoutProspect: function () {
        var url = $('#update-cout-prospect').data('route');
        var form = $("#formDemande")[0];
        $.ajax({
            type: "POST",
            cache: false,
            url: url,
            dataType: "json",
            data: new FormData(form),
            contentType: false,
            processData: false,
            success: function (data) {
                $("#unit-price").html(data.prixBase + data.icon);
                $('#frais-export').html(data.fraisExport + data.icon);
                $("#global-price").html(data.prixTotal + data.icon);
            }
        });
    },

    _eraseAllCityState: function () {
        if (!$.fn.DataTable.fnIsDataTable($('#localisation-departement')) || !$.fn.DataTable.fnIsDataTable($('#localisation-ville'))) {
            var localisationDepartement = $('#localisation-departement').DataTable({
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                },
                buttons: [
                    {
                        extend: 'erase',
                        title: 'Supprimer tous les départements',
                        content: 'Voulez-vous vraiment supprimer tous  les départements ?',
                        url: $('#profilia_demande_prospect_eraseStatePath').data('route')
                    }
                ]
            });

            var localisationVille = $('#localisation-ville').DataTable({
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                },
                buttons: [
                    {
                        extend: 'erase',
                        title: 'Supprimer toutes les villes',
                        content: 'Voulez-vous vraiment supprimer toutes  les villes ?',
                        url: $('#profilia_demande_prospect_eraseCityPath').data('route')
                    }
                ]
            });


            localisationVille.on('buttons-action', function (e, buttonApi, dataTable, node, config) {
                $('#profilia_demande_prospect_villeInsee').val('');
                $('#profilia_demande_prospect_codesCity').val('');
                $('#profilia_demande_prospect_codesInsee').val('');
                $('#profilia_demande_prospect_codesIris').val('');
            });

            localisationDepartement.on('buttons-action', function (e, buttonApi, dataTable, node, config) {
                $('#profilia_demande_prospect_departementInsee').val('');
            });

            localisationVille.on('init.dt', function () {
                localisationVille.buttons().container().appendTo($('#localisation-ville_wrapper .col-sm-12:eq(0)'));
            });

            localisationDepartement.on('init.dt', function () {
                localisationDepartement.buttons().container().appendTo($('#localisation-departement_wrapper .col-sm-12:eq(0)'));
            });
        }
    },
    comptaFacture: function () {
        $(document).on('click', '#zipfact', function (e) {

            var isArchiv = $(this).data('archive');
            var url = $(this).data('route');
            var urlDelete = $(this).data('delroute');
            var idsFact = Array();
            $('.allfact').each(function () {
                if ($(this).is(':checked')) {
                    idsFact.push($(this).data("id"));
                }
            });

            if (isArchiv) {
                $.confirm({
                    theme: 'dark',
                    title: 'Exporter les archives de facture',
                    type: 'blue',
                    content: 'Voulez-vous vraiment exporter les factures ?',
                    buttons: {
                        confirm: {
                            text: 'Confirmer',
                            btnClass: 'btn-blue',
                            action: function () {
                                if (idsFact.length > 0) {
                                    $.ajax({
                                        type: "POST",
                                        cache: false,
                                        url: url,
                                        data: {
                                            "idsFact": JSON.stringify(idsFact),
                                        },
                                        dataType: "json",
                                        success: function (json) {
                                            document.location = json.fileZip;

                                            setTimeout(function () {
                                                // suppression du fichier
                                                $.ajax({
                                                    type: "POST",
                                                    cache: false,
                                                    url: urlDelete,
                                                    data: {
                                                        "zipFactName": json.zipFactName,
                                                        "idsFact": JSON.stringify(idsFact),
                                                    },
                                                    dataType: "json",
                                                    success: function (json) {
                                                        location.reload();
                                                    }
                                                });
                                            }, 5000);

                                        }
                                    });
                                }
                            }
                        },
                        cancel: {
                            btnClass: 'btn-red',
                            text: 'Annuler',
                        }
                    }
                });
            } else {
                if (idsFact.length > 0) {
                    $.ajax({
                        type: "POST",
                        cache: false,
                        url: url,
                        data: {
                            "idsFact": JSON.stringify(idsFact),
                        },
                        dataType: "json",
                        success: function (json) {
                            document.location = json.fileZip;

                            setTimeout(function () {
                                // suppression du fichier
                                $.ajax({
                                    type: "POST",
                                    cache: false,
                                    url: urlDelete,
                                    data: {
                                        "zipFactName": json.zipFactName,
                                        "idsFact": JSON.stringify(idsFact),
                                    },
                                    dataType: "json",
                                    success: function (json) {
                                        location.reload();
                                    }
                                });
                            }, 5000);

                        }
                    });
                }
            }


        });
        $(document).on('click', '#cbAllfact', function (e) {
            if ($(this).is(':checked')) {
                $(".allfact").prop("checked", true);
            } else {
                $(".allfact").prop("checked", false);
            }
        });
    },

    devisBonDeCommande: function () {
        $(document).on('click', '#bondeco', function (e) {
            urlAction = $(this).data("route");
            urlArchive = $(this).data("translink");
            urlVerif = $(this).data("veriflink");
            formChoixDate = $("#urlFormDatePlani").data("val");
            crmChoixDate = $(this).data("dateplani");
            status = $(this).data("status");
            bilan = $(this).data("bilan");
            idsFact = Array();
            $('.allfact').each(function () {
                if ($(this).is(':checked')) {
                    idsFact.push($(this).data("id"));
                }
            });

            if (idsFact.length > 0) {
                $.ajax({
                    type: "POST",
                    cache: false,
                    url: urlVerif,
                    data: { 'idsFact': JSON.stringify(idsFact) },
                    dataType: "json",
                    success: function (data) {
                        $.confirm({
                            theme: 'dark',
                            title: 'Transformer le devis en bon de commande',
                            type: 'blue',
                            content: 'url:' + urlArchive + "?idsFact=" + JSON.stringify(idsFact),
                            buttons: {
                                confirm: {
                                    text: 'Confirmer',
                                    btnClass: 'btn-blue',
                                    action: function () {
                                        serializedForm = "idsFact=" + JSON.stringify(idsFact) + "&" + this.$content.find('#formAct').serialize();
                                        $.ajax({
                                            type: "POST",
                                            cache: false,
                                            url: urlAction,
                                            data: serializedForm,
                                            dataType: "json",
                                            success: function (data) {
                                                location.reload();
                                            },
                                            statusCode: {
                                                400: function (data) {
                                                    $.alert({
                                                        theme: 'dark',
                                                        title: 'Erreur:',
                                                        content: data.responseJSON.message,
                                                        type: 'red',
                                                    });
                                                }
                                            }
                                        });

                                    }
                                },
                                cancel: {
                                    btnClass: 'btn-red',
                                    text: 'Annuler'
                                }
                            }
                        });
                    },
                    statusCode: {
                        400: function (data) {
                            if (data.responseJSON.typeRet == "C") {
                                $.alert({
                                    theme: 'dark',
                                    title: 'Erreur:',
                                    content: data.responseJSON.message,
                                    type: 'red',
                                    columnClass: 'col-md-7',
                                    buttons: {
                                        createCompte: {
                                            text: 'Créer un nouveau compte',
                                            btnClass: 'btn-blue',
                                            action: function () {
                                                window.location = data.responseJSON.routeCreateCompte;
                                            }
                                        },
                                        addPdv: {
                                            text: 'Ajouter un point de vente',
                                            btnClass: 'btn-light text-dark',
                                            action: function () {
                                                window.location = data.responseJSON.routeAddPdv;
                                            }

                                        },
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Annuler'
                                        },
                                    }
                                });
                            } else if (data.responseJSON.typeRet == "D") {

                                $.confirm({
                                    theme: 'dark',
                                    title: 'Sélectionner la date d\'envoi prévu',
                                    type: 'blue',
                                    content: 'url:' + formChoixDate,
                                    buttons: {
                                        confirm: {
                                            text: 'Confirmer',
                                            btnClass: 'btn-blue',
                                            action: function () {
                                                serializedForm = "idsFact=" + JSON.stringify(idsFact) + "&" + this.$content.find('#formActDate').serialize();
                                                $.ajax({
                                                    type: "POST",
                                                    cache: false,
                                                    url: crmChoixDate,
                                                    data: serializedForm,
                                                    dataType: "json",
                                                    success: function (data) {
                                                        $.confirm({
                                                            theme: 'dark',
                                                            title: 'Transformer le devis en bon de commande',
                                                            type: 'blue',
                                                            content: 'url:' + urlArchive + "?idsFact=" + JSON.stringify(idsFact),
                                                            buttons: {
                                                                confirm: {
                                                                    text: 'Confirmer',
                                                                    btnClass: 'btn-blue',
                                                                    action: function () {
                                                                        serializedForm = "idsFact=" + JSON.stringify(idsFact) + "&" + this.$content.find('#formAct').serialize();
                                                                        $.ajax({
                                                                            type: "POST",
                                                                            cache: false,
                                                                            url: urlAction,
                                                                            data: serializedForm,
                                                                            dataType: "json",
                                                                            success: function (data) {
                                                                                location.reload();
                                                                            },
                                                                            statusCode: {
                                                                                400: function (data) {
                                                                                    $.alert({
                                                                                        theme: 'dark',
                                                                                        title: 'Erreur:',
                                                                                        content: data.responseJSON.message,
                                                                                        type: 'red',
                                                                                    });
                                                                                }
                                                                            }
                                                                        });

                                                                    }
                                                                },
                                                                cancel: {
                                                                    btnClass: 'btn-red',
                                                                    text: 'Annuler'
                                                                }
                                                            }
                                                        });
                                                    },
                                                    statusCode: {
                                                        400: function (data) {
                                                            $.alert({
                                                                theme: 'dark',
                                                                title: 'Erreur:',
                                                                content: data.responseJSON.message,
                                                                type: 'red',
                                                            });
                                                        }
                                                    }
                                                });
                                            }
                                        },
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Annuler'
                                        }
                                    }
                                });
                            } else {
                                $.alert({
                                    theme: 'dark',
                                    title: 'Erreur:',
                                    content: data.responseJSON.message,
                                    type: 'red',
                                    columnClass: 'col-md-7',
                                    buttons: {
                                        cancel: {
                                            btnClass: 'btn-red',
                                            text: 'Ok'
                                        },
                                    }
                                });
                            }
                        }
                    }
                });
            }
        });
        $(document).on('click', '#cbAllfact', function (e) {
            if ($(this).is(':checked')) {
                $(".allfact").prop("checked", true);
            } else {
                $(".allfact").prop("checked", false);
            }
        });

    },

    compteProspectsBtoB: function () {
        $('.compte-prospectsBtoB').on('change', function (e) {
            e.preventDefault();
            INTERFACE_FRONT._ajaxCompteProspectsBtoB();
        });
    },

    _bootstrapTabControlBtoB: function () {
        var i, items = $('#formDemandeBtoB .nav-link'), pane = $('#formDemandeBtoB .tab-pane');
        // next
        $('.nexttab').on('click', function () {
            for (i = 0; i < items.length; i++) {
                if ($(items[i]).hasClass('active') == true) {
                    break;
                }
            }

            if (i < items.length - 1) {
                // for tab
                $(items[i]).removeClass('active');
                $(items[i + 1]).addClass('active');
                $(items[i + 1]).removeClass('disabled');
                // for pane
                $(pane[i]).removeClass('show active');
                $(pane[i + 1]).addClass('show active');
            }

            $(pane[i + 1]).trigger('paneclic');

        });

        // Prev
        $('.prevtab').on('click', function () {
            for (i = 0; i < items.length; i++) {
                if ($(items[i]).hasClass('active') == true) {
                    break;
                }
            }

            if (i != 0) {
                // for tab
                $(items[i]).removeClass('active');
                $(items[i - 1]).addClass('active');
                // for pane
                $(pane[i]).removeClass('show active');
                $(pane[i - 1]).addClass('show active');
            }

            $(pane[i - 1]).trigger('paneclic');
        });
    },


    emb: function (initValuesBtoB) {
        var thisYear = new Date().getFullYear();
        if ($.isEmptyObject(initValuesBtoB)) {
            $("#slider-range-annee").slider({
                range: true,
                min: 1850,
                max: thisYear,
                values: [1850, thisYear],
                slide: function (event, ui) {
                    $("#inputAnnee").val(ui.values[0] + " - " + ui.values[1]);
                },
                stop: function (event, ui) {
                    $("#emb_demande_prospect_anneeDebut").val(ui.values[0]);
                    $("#emb_demande_prospect_anneeFin").val(ui.values[1]);
                }
            });
        } else {
            $("#slider-range-annee").slider({
                range: true,
                min: 1850,
                max: thisYear,
                values: [parseInt(initValuesBtoB.anneeDebut), parseInt(initValuesBtoB.anneeFin)],
                slide: function (event, ui) {
                    $("#inputAnnee").val(ui.values[0] + " - " + ui.values[1]);
                },
                stop: function (event, ui) {
                    $("#emb_demande_prospect_anneeDebut").val(ui.values[0]);
                    $("#emb_demande_prospect_anneeFin").val(ui.values[1]);
                }
            });

            //init des valeurs des onglets
            for (tabInfo in initValuesBtoB.tabDatas) {
                $('#' + tabInfo).html(initValuesBtoB.tabDatas[tabInfo]);
                // init tab
                INTERFACE_FRONT._bootstrapActiveTabControlEdit(tabInfo, initValuesBtoB.tabDatas[tabInfo]);
            };

            INTERFACE_FRONT._bootstrapActiveTabControlEditEnd();

        }

        $("#inputAnnee").val($("#slider-range-annee").slider("values", 0) + " - " + $("#slider-range-annee").slider("values", 1));
        $("#emb_demande_prospect_anneeDebut").val($("#slider-range-annee").slider("values", 0));
        $("#emb_demande_prospect_anneeFin").val($("#slider-range-annee").slider("values", 1));

        $("#slider-city-radius").slider({
            min: 0,
            max: 100,
            step: 5,
            disabled: true,
            slide: function (event, ui) {
                $("#emb_demande_prospect_villeRayon").val(ui.value);
                $("#inputRadius").html(ui.value);
            }
        });

        $.fn.select2.defaults.set('language', 'fr');

    },

    _bootstrapActiveTabControlEdit: function (tab, valTab) {
        if (valTab != "&nbsp;") {
            $('#' + tab).parent().removeClass('disabled');
            $('#' + tab).parent().find('.canal-name').append('<i class="fas fa-check-circle ml-2 text-info"></i>');
        }
    },

    _bootstrapActiveTabControlEditEnd: function () {
        lastUnlock = 0;
        $('.nav-tabs li a').each(function (idx, info) {

            removeClass = 0;

            if ($(this).hasClass("disabled") && lastUnlock) {
                removeClass = 1;
            }

            if (!$(this).hasClass("disabled")) {
                lastUnlock = 1;
            } else {
                lastUnlock = 0;
            }

            if (removeClass) {
                $(this).removeClass('disabled');
            }

        });
    },

    findCityBtoB: function () {

        var cache = {};
        var url = $("#emb_demande_prospect_ville").data('route');
        $("#emb_demande_prospect_ville").on('focus', function (e) {
            $("#slider-city-radius").slider({
                disabled: true,
                value: 0,
                change: function (event, ui) {
                    $("#emb_demande_prospect_villeRayon").val(ui.value);
                    $("#inputRadius").html(ui.value);
                }
            });
            $(this).val('');
        });

        $("#emb_demande_prospect_ville").autocomplete({
            minLength: 2,
            source: function (request, response) {
                var term = request.term;
                if (term in cache) {
                    response(cache[term]);
                    return;
                }

                $.ajax({
                    url: url,
                    dataType: "json",
                    global: false,
                    data: {
                        term: request.term
                    },
                    success: function (data) {
                        cache[term] = data;
                        response(data);
                    }
                });
            },

            select: function (event, ui) {
                $('#emb_demande_prospect_ville').val(ui.item.label);
                $('#emb_demande_prospect_villeInsee').val(ui.item.value);
                $("#slider-city-radius").slider('enable');
                return false;

            }


        });
    },

    findStateBtoB: function () {

        var cache = {};
        var url = $("#emb_demande_prospect_departement").data('route');
        $("#emb_demande_prospect_departement").autocomplete({
            minLength: 2,
            source: function (request, response) {
                var term = request.term;
                if (term in cache) {
                    response(cache[term]);
                    return;
                }

                $.ajax({
                    url: url,
                    dataType: "json",
                    global: false,
                    data: {
                        term: request.term
                    },
                    success: function (data) {
                        cache[term] = data;
                        response(data);
                    }
                });
            },

            select: function (event, ui) {
                $('#emb_demande_prospect_departement').val(ui.item.label);
                $('#emb_demande_prospect_departementInsee').val(ui.item.value);
                return false;

            }


        });
        $('#emb_demande_prospect_departement').on('focus', function (e) {
            $(this).val('');
        });
    },
    deleteStateBtoB: function () {
        $(document).off('click', '.btn-delete-state');
        $(document).on('click', '.btn-delete-state', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer le département',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce département ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },
    deleteCityBtoB: function () {
        $(document).off('click', '.btn-delete-city');
        $(document).on('click', '.btn-delete-city', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer la ville',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer cette ville ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    deleteNafBtoB: function () {
        $(document).off('click', '.btn-delete-naf');
        $(document).on('click', '.btn-delete-naf', function (e) {
            var urlDelete = $(this).data('route');
            var tr = $(this).closest('tr');
            $.confirm({
                title: 'Supprimer le code naf',
                type: 'red',
                content: 'Voulez-vous vraiment supprimer ce code naf ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-red',
                        action: function () {
                            e.preventDefault();
                            $.ajax({
                                type: "POST",
                                cache: false,
                                url: urlDelete,
                                dataType: "json",
                                success: function (json) {
                                    tr.fadeOut();
                                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        });
    },

    addStateBtoB: function () {
        $('#addState').on('click', function (e) {
            e.preventDefault();

            var url = $('#emb_demande_prospect_addStatePath').data('route');
            $.ajax({
                url: url,
                data: {
                    'insee_state': $('#emb_demande_prospect_departementInsee').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                beforeSend: function () {
                    $('#emb_demande_prospect_departement').val('');
                },
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red'
                    });
                },
                success: function (data) {
                    if (data.message == 'duplicate') {
                        $.alert({
                            title: 'Information:',
                            content: 'Les villes en double n\'ont pas été ajoutées !',
                            type: 'orange'
                        });
                    }
                    $("#locationResults").html(data.html);
                },
                complete: function () {
                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                }
            });
        }
        );
    },

    addFranceBtoB: function () {
        $('#addFrance').on('click', function (e) {
            e.preventDefault();

            var url = $('#emb_demande_prospect_addFrancePath').data('route');

            $.confirm({
                title: 'Sélection de zone',
                type: 'green',
                content: 'Voulez-vous vraiment sélectionner la France entière ?',
                buttons: {
                    confirm: {
                        text: 'Oui',
                        btnClass: 'btn-green',
                        action: function () {
                            $.ajax({
                                url: url,
                                type: "post",
                                cache: false,
                                dataType: "json",
                                global: false,
                                error: function (err) {
                                    $.alert({
                                        title: 'Erreur:',
                                        content: err.responseJSON.message,
                                        type: 'red'
                                    });
                                },
                                success: function (data) {
                                    if (data.message == 'duplicate') {
                                        $.alert({
                                            title: 'Information:',
                                            content: 'Les villes en double n\'ont pas été ajoutées !',
                                            type: 'orange'
                                        });
                                    }
                                    $("#locationResults").html(data.html);
                                },
                                complete: function () {
                                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                                }
                            });
                        }
                    },
                    cancel: {
                        text: 'Non'
                    }
                }
            });
        }
        );
    },

    addNafBtoB: function () {
        $('#addNaf').on('click', function (e) {
            e.preventDefault();
            if ($(this).parent().find('textarea').val() === '' || $(this).parent().find('input').val() === '') {

                $.alert({
                    title: 'Erreur:',
                    content: 'Le champ ne peut être vide.',
                    type: 'red'
                });

                return false;

            }

            var url = $('#emb_demande_prospect_addNafPath').data('route');
            $.ajax({
                url: url,
                data: {
                    'codes_naf': $('#emb_demande_prospect_codesNaf').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red'
                    });
                },
                success: function (data) {
                    if (data.message == 'duplicate') {
                        $.alert({
                            title: 'Information:',
                            content: 'Les codes NAF en double n\'ont pas été ajoutés !',
                            type: 'orange'
                        });
                    }
                    $("#nafResults").html(data.html);
                },
                complete: function () {
                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                }
            });
        });
    },

    addCityBtoB: function () {
        $('#addCity,#addInsee').on('click', function (e) {
            e.preventDefault();

            if ($(this).attr('id').indexOf('addCity') != -1) {
                $('#emb_demande_prospect_codesInsee').val('');
                $('#emb_demande_prospect_codesCity').val('');
            }
            if ($(this).attr('id').indexOf('addInsee') != -1) {
                $('#emb_demande_prospect_codesCity').val('');
                $('#emb_demande_prospect_villeRayon').val('');
                $('#emb_demande_prospect_villeInsee').val('');
                $('#emb_demande_prospect_ville').val('');
            }

            if ($(this).parent().find('textarea').val() === '' || $(this).parent().find('input').val() === '') {

                $.alert({
                    title: 'Erreur:',
                    content: 'Le champ ne peut être vide.',
                    type: 'red'
                });

                return false;

            }

            INTERFACE_FRONT._addCityAjaxBtoB();
        }
        );
    },
    _addCityAjaxBtoB: function () {
        var url = $('#emb_demande_prospect_addCityPath').data('route');
        $.ajax({
            url: url,
            data: {
                'radius_city': $('#emb_demande_prospect_villeRayon').val(),
                'insee_city': $('#emb_demande_prospect_villeInsee').val(),
                'codes_insee': $('#emb_demande_prospect_codesInsee').val(),
                'codes_city': $('#emb_demande_prospect_codesCity').val(),
                'term': $('#emb_demande_prospect_ville').val(),
            },
            type: "post",
            cache: false,
            dataType: "json",
            global: false,
            beforeSend: function () {
                $('#emb_demande_prospect_ville').val('');
            },
            error: function (err) {
                $.alert({
                    title: 'Erreur:',
                    content: err.responseJSON.message,
                    type: 'red'
                });
            },
            success: function (data) {
                if (data.message == 'duplicate') {
                    $.alert({
                        title: 'Information:',
                        content: 'Les villes en double n\'ont pas été ajoutées !',
                        type: 'orange'
                    });
                }

                if (data.message != 'no_result') {
                    $("#locationResults").html(data.html);
                } else {
                    $.alert({
                        title: 'Information:',
                        content: 'Aucune ville ne correspond aux critères !',
                        type: 'orange'
                    });
                }
            },
            complete: function () {
                INTERFACE_FRONT._ajaxCompteProspectsBtoB();
            }
        });
    },
    checkCityBtoB: function () {
        $('#addPostal').on('click', function (e) {
            e.preventDefault();

            $('#emb_demande_prospect_codesInsee').val('');
            $('#emb_demande_prospect_villeRayon').val('');
            $('#emb_demande_prospect_villeInsee').val('');
            $('#emb_demande_prospect_ville').val('');

            if ($(this).parent().find('textarea').val() === '') {

                $.alert({
                    title: 'Erreur:',
                    content: 'Le champ ne peut être vide.',
                    type: 'red'
                });

                return false;

            }

            var url = $('#emb_demande_prospect_checkCityPath').data('route');

            $.ajax({
                url: url,
                data: {
                    'codes_city': $('#emb_demande_prospect_codesCity').val()
                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                // error: function (err) {
                //     $.alert({
                //         title: 'Erreur:',
                //         content: err.responseJSON.message,
                //         type: 'red'
                //     });
                // },
                success: function (data) {
                    INTERFACE_FRONT._addCityAjaxBtoB();
                },
                // complete: function () {
                //     INTERFACE_FRONT._ajaxCompteProspects();
                // },
                statusCode: {
                    400: function (data) {
                        $.fancybox.open({
                            src: data.responseText,
                            type: 'html',
                            afterClose: function () {
                                INTERFACE_FRONT._addCityAjaxBtoB();
                            },
                        })
                        return false;
                    },
                    404: function (data) {
                        $.alert({
                            title: 'Erreur:',
                            content: 'Aucune ville ne correspond aux critères !',
                            type: 'red'
                        });
                    }
                }
            });
        });
    },

    chooseCityBtoB: function () {
        $(document).on('click', '.choix-ville', function (e) {
            var that = $(this);
            if (INTERFACE_FRONT.envMode == "dev") {
                url = "/app_dev.php/choose-city";
            } else {
                url = "/choose-city";
            }
            $.ajax({
                url: url,
                data: {
                    'identifier_city': that.val(),
                    'action': (that.prop('checked') == true ? 'add' : 'remove')

                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                success: function (data) {

                },
            });
        })
    },

    updateCoutProspectBtoB: function () {
        $('#volumeProspectsBtoB').on('change', function (e) {
            e.preventDefault();
            $('#emb_demande_prospect_volume').val($('#volumeProspectsBtoB').val());
            INTERFACE_FRONT._updateCoutProspectBtoB();
        });
    },

    _updateCoutProspectBtoB: function () {
        var url = $('#update-cout-prospect').data('route');
        var form = $("#formDemandeBtoB")[0];
        $.ajax({
            type: "POST",
            cache: false,
            url: url,
            dataType: "json",
            data: new FormData(form),
            contentType: false,
            processData: false,
            success: function (data) {
                $("#unit-price").html(data.prixBase + data.icon);
                $('#frais-export').html(data.fraisExport + data.icon);
                $("#global-price").html(data.prixTotal + data.icon);
            }
        });
    },

    _displayErrorsEmb: function (form, errors) {
        var errorList = $("ul.error-messages", form);
        errorList.empty();

        for (error of errors) {
            errorList
                .show()
                .append("<div class='alert alert-dismissible alert-danger fade show' role='alert'>" + error.label + " : " + error.value + "<button type='button' class='close' data-dismiss='alert' aria-label='Fermer'><span aria-hidden='true'>&times;</span></button></div>");
        }

        if (errors.length == 0) {
            errorList.hide();
        } else {
            $(document).scrollTop('#formDemandeBtoB');
        }
    },

    _eraseAllCityStateBtoB: function () {
        if (!$.fn.DataTable.fnIsDataTable($('#localisation-departement')) || !$.fn.DataTable.fnIsDataTable($('#localisation-ville'))) {
            var localisationDepartement = $('#localisation-departement').DataTable({
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                },
                buttons: [
                    {
                        extend: 'erase',
                        title: 'Supprimer tous les départements',
                        content: 'Voulez-vous vraiment supprimer tous  les départements ?',
                        url: $('#emb_demande_prospect_eraseStatePath').data('route')
                    }
                ]
            });

            var localisationVille = $('#localisation-ville').DataTable({
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                },
                buttons: [
                    {
                        extend: 'erase',
                        title: 'Supprimer toutes les villes',
                        content: 'Voulez-vous vraiment supprimer toutes  les villes ?',
                        url: $('#emb_demande_prospect_eraseCityPath').data('route')
                    }
                ]
            });


            localisationVille.on('buttons-action', function (e, buttonApi, dataTable, node, config) {
                $('#emb_demande_prospect_villeInsee').val('');
                $('#emb_demande_prospect_codesCity').val('');
                $('#emb_demande_prospect_codesInsee').val('');
                $('#emb_demande_prospect_codesIris').val('');
            });

            localisationDepartement.on('buttons-action', function (e, buttonApi, dataTable, node, config) {
                $('#emb_demande_prospect_departementInsee').val('');
            });

            localisationVille.on('init.dt', function () {
                localisationVille.buttons().container().appendTo($('#localisation-ville_wrapper .col-sm-12:eq(0)'));
            });

            localisationDepartement.on('init.dt', function () {
                localisationDepartement.buttons().container().appendTo($('#localisation-departement_wrapper .col-sm-12:eq(0)'));
            });
        }

        if (!$.fn.DataTable.fnIsDataTable($('#naf-code-tab'))) {
            var nafCodeTab = $('#naf-code-tab').DataTable({
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
                },
                buttons: [
                    {
                        extend: 'erase',
                        title: 'Supprimer tous les codes naf',
                        content: 'Voulez-vous vraiment supprimer tous  les codes naf ?',
                        url: $('#emb_demande_prospect_eraseNafPath').data('route')
                    }
                ]
            });

            nafCodeTab.on('init.dt', function () {
                nafCodeTab.buttons().container().appendTo($('#naf-code-tab_wrapper .col-sm-12:eq(0)'));
            });

        }
    },

    recapProspectsBtoB: function () {
        $('#recapProspectsBtoB').on('click', function (e) {
            var form = $("#formDemandeBtoB")[0];
            var url = $(this).data('route');
            INTERFACE_FRONT._displayErrorsEmb(form, []);

            $.ajax({
                url: url,
                data: new FormData(form),
                type: "post",
                cache: false,
                dataType: "json",
                contentType: false,
                processData: false,
                error: function (err) {
                    INTERFACE_FRONT._displayErrorsEmb(form, err.responseJSON.message);

                    $.alert({
                        title: 'Erreur:',
                        content: "Des erreurs ont été détectées, veuillez les corriger svp.",
                        type: 'red'
                    });

                },
                success: function (data) {
                    $("#volumeProspectsBtoB").val($("#emb_demande_prospect_nbProspects").val()).trigger('change');
                    $("#recapProspectsModal").modal('show');
                }
            });


        });
    },


    _searchNaf: function () {
        $('#popNaf').on('click', function (e) {
            $("#popListeNafModal").modal('show');
        });

        $('#selCodeNaf').attr('data-placeholder', 'Sélectionnez un ou plusieurs code(s)');

        var mySelNaf = $('#selCodeNaf').select2({
            placeholder: "Sélectionnez un ou plusieurs code(s)",
            multiple: true,
        }
        );

        $(document).ready(function() {

         $('#selCodeNaf').select2({
            placeholder: "Sélectionnez un ou plusieurs code(s)",
            multiple: true,
         });

         $(document).on("click", ".select2-results__group", function(){

             var groupName = $(this).html()
             var options = $('#selCodeNaf option');

             $.each(options, function(key, value){

                 if($(value)[0].parentElement.label.indexOf(groupName) >= 0){
                     $(value).prop("selected","selected");
                 }

             });

             $("#selCodeNaf").trigger("change");
             $("#selCodeNaf").select2('close');

           });
         });


        $("#closeNafListe").on('click', function (e) {
            mySelNaf.val(null).trigger("change");
        });

        $("#addNafSearched").on('click', function (e) {

            var listeNafSelected = ($('#selCodeNaf option:selected').toArray().map(item => item.value));


            var sep = "";
            var pastedListNaf = "";
            $.each(listeNafSelected, function (index, value) {
                // faire quelque chose avec `value` (ou `this` qui est `value` )
                pastedListNaf += sep + value;
                sep = "\n";
                //$("#emb_demande_prospect_codesNaf").append(value).append("\n");
            });

            var url = $('#emb_demande_prospect_addNafPath').data('route');

            $.ajax({
                url: url,
                data: {
                    'codes_naf': pastedListNaf
                },
                type: "post",
                cache: false,
                dataType: "json",
                global: false,
                error: function (err) {
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red'
                    });
                },
                success: function (data) {
                    if (data.message == 'duplicate') {
                        $.alert({
                            title: 'Information:',
                            content: 'Les codes NAF en double n\'ont pas été ajoutés !',
                            type: 'orange'
                        });
                    }
                    $("#nafResults").html(data.html);
                    /** on vire les codes nafs déja sélectionnés si autre sélection */

                    $("#selCodeNaf option:selected").prop("selected", false);
                    $.each($('#selecteurNafs ul.select2-selection__rendered').children(), function (index, value) {
                        if(!$(value).hasClass("select2-search--inline")){
                            $(value).remove();
                        }
                    });
                },
                complete: function () {
                    INTERFACE_FRONT._ajaxCompteProspectsBtoB();
                }
            });

            $("#popListeNafModal").modal('hide');
            mySelNaf.val(null).trigger("change");

            /*
                        $("#popListeNafModal").modal('hide');
                        mySelNaf.val(null).trigger("change");
                        $("#addNaf").trigger("click");
            */
        });


    },

    _ajaxCompteProspectsBtoB: function () {
        var form = $("#formDemandeBtoB")[0];

        if (this.requestCpteProspectBtoB && this.requestCpteProspectBtoB != 4) {
            this.requestCpteProspectBtoB.abort();
        }

        this.requestCpteProspectBtoB = $.ajax({
            url: INTERFACE_FRONT.urlCpteProspectBtoB,
            data: new FormData(form),
            type: "post",
            cache: false,
            dataType: "json",
            contentType: false,
            processData: false,
            global: false,
            beforeSend: function () {
                $('.nb_prospectsBtoB').hide();
                $('#loader').show();
            },
            error: function (err) {
                if (err.readyState == 4) {
                    $('.nb_prospectsBtoB').html(0);
                    $('#emb_demande_prospect_nbProspects').val(0);
                    $.alert({
                        title: 'Erreur:',
                        content: err.responseJSON.message,
                        type: 'red'
                    });
                }
            },
            success: function (data) {
                var nf = new Intl.NumberFormat();
                $('.nb_prospectsBtoB').html(nf.format(data.html));
                for (tabInfo in data.tabDatas) {
                    $('#' + tabInfo).html(data.tabDatas[tabInfo]);
                };
                $('#emb_demande_prospect_nbProspects').val(data.html);
                $('#unit-price').html(data.prixBase);
                $('#frais-export').html(data.fraisExport);
                $('#global-price').html(data.prixTotal);
                INTERFACE_FRONT._bootstrapActiveTabControl();
            },
            complete: function () {
                $('#loader').hide();
                $('.nb_prospectsBtoB').show();
            }
        });

    },
};

jQuery(document).ready(function ($) {
    INTERFACE_FRONT.initGlobalDataTable();
    INTERFACE_FRONT.init();
    INTERFACE_FRONT.initSelect2();
    INTERFACE_FRONT.initDateTimePicker();
    INTERFACE_FRONT.initChart();
    INTERFACE_FRONT.createAllDataTable();
    INTERFACE_FRONT.copyClipboard();
    INTERFACE_FRONT.transformClientDataToInput();
    INTERFACE_FRONT.initAjax();
    INTERFACE_FRONT.ajaxListeContact();
    INTERFACE_FRONT.confirmDeletePtsVente();
    INTERFACE_FRONT.confirmDeleteUser();
    INTERFACE_FRONT.activeSaveTelephoneManuel();
    INTERFACE_FRONT.ajaxSmsCheck();
    INTERFACE_FRONT.eventTextSms();
    INTERFACE_FRONT.insertField();
    INTERFACE_FRONT.ajaxSendSmsTest();
    INTERFACE_FRONT.checkSmsSender();
    INTERFACE_FRONT.displayAlertSmsType();
    INTERFACE_FRONT.disableDatePlanifEnvoi();
    INTERFACE_FRONT.ajaxSmsRecapitulatif();
    INTERFACE_FRONT.choixSmsCampagne();
    INTERFACE_FRONT.setCompteCampagneSession();
    INTERFACE_FRONT.updateNbreContact();
    INTERFACE_FRONT.checkTelephoneManuel();
    INTERFACE_FRONT.saveTelephoneManuel();
    INTERFACE_FRONT.saveModeleCampagne();
    INTERFACE_FRONT.afficheModeleCampagne();
    INTERFACE_FRONT.afficheChoixModeleMiniSite();
    INTERFACE_FRONT.actionBrouillon();
    INTERFACE_FRONT.afficheChampsVariables();
    INTERFACE_FRONT.updateNbreContacts();
    INTERFACE_FRONT.updateNbreListeNoireContacts();
    INTERFACE_FRONT.sauveCampagneSMS();
    INTERFACE_FRONT.actionCampagne();
    INTERFACE_FRONT.comptePrincipalChooser();
    INTERFACE_FRONT.statistiquesExportGlobal();
    INTERFACE_FRONT.choixDatePlanif();
    INTERFACE_FRONT.checkDatePlanif();
    INTERFACE_FRONT.ajaxConsulterListeContact();
    INTERFACE_FRONT.ajaxSupprimerListeContact();
    INTERFACE_FRONT.alertChoixPdv();
    INTERFACE_FRONT.alertCredit();
    INTERFACE_FRONT.sauveBrouillonCampagneSMS();
    INTERFACE_FRONT.chercheCampagne();
    INTERFACE_FRONT.afficheDetailsCampagne();
    INTERFACE_FRONT.filterCampagne();
    INTERFACE_FRONT.effaceCherche();
    INTERFACE_FRONT.paginateCampagne();
    INTERFACE_FRONT.afficheContactsCampagne();
    INTERFACE_FRONT.statistiquesFilterDate();
    INTERFACE_FRONT.limitLogos();
    // INTERFACE_FRONT.activeCampagneDropDown();
    INTERFACE_FRONT.updateSmsInteractifChamps();
    INTERFACE_FRONT.hidePreviewList();
    INTERFACE_FRONT.toggleButton();
    INTERFACE_FRONT.updateThemeCouleur();
    INTERFACE_FRONT.toggleDisplay();
    INTERFACE_FRONT.addReponseformulaire();
    INTERFACE_FRONT.gestionEmailRetour();
    INTERFACE_FRONT.gestionReseauxSociaux();
    INTERFACE_FRONT.updateLogo();
    INTERFACE_FRONT.updateHeaderImage();
    INTERFACE_FRONT.applyThemeCouleur();
    INTERFACE_FRONT.getMarqueFont();
    INTERFACE_FRONT.saveModeleMiniSite();
    INTERFACE_FRONT.afficheModeleSmsInteractif();
    INTERFACE_FRONT.previsuEnLigne();
    INTERFACE_FRONT.effaceDatesStatistique();
    INTERFACE_FRONT.disabledItem();
    INTERFACE_FRONT.gestionSlider();
    INTERFACE_FRONT.confirmDeleteCampagne();
    INTERFACE_FRONT.rechargeCredit();
    INTERFACE_FRONT.checkTelephonesParamGen();
    INTERFACE_FRONT.deleteMessageVocal();
    INTERFACE_FRONT.enregistrerMessageVocal();
    INTERFACE_FRONT.vmsEnvoi();
    INTERFACE_FRONT.planifVague();
    INTERFACE_FRONT.choixDatePlanifVms();
    INTERFACE_FRONT.choixDatePlanifVague();
    INTERFACE_FRONT.checkDatePlanifVague();
    // INTERFACE_FRONT.resetIsSave();
    INTERFACE_FRONT.dupliquerSmsInteractif();
    INTERFACE_FRONT.crmDemandeCrea();
    INTERFACE_FRONT.crmExportPdv();
    INTERFACE_FRONT.demandeAssistance();
    INTERFACE_FRONT.redirectAdminMiniSite();
    INTERFACE_FRONT.sanitizeInputText();
    INTERFACE_FRONT.hideLoader();
    INTERFACE_FRONT.triDateCampagne();
    // INTERFACE_FRONT.reinitFilterMiniSite();
    INTERFACE_FRONT.applyFilterMiniSite();
    INTERFACE_FRONT.confirmDeleteMiniSite();
    INTERFACE_FRONT.confirmDuplicateMiniSite();
    INTERFACE_FRONT.integrationMiniSite();
    INTERFACE_FRONT.afficheApercuCampagne();
    INTERFACE_FRONT.crmEventText();
    INTERFACE_FRONT.crmMiniSite();
    INTERFACE_FRONT.paginationPlaningCampagne();
    INTERFACE_FRONT.disableKeySubmitForm();
    INTERFACE_FRONT.crmAddPdv();
    // INTERFACE_FRONT.profilia();
    INTERFACE_FRONT.deleteModeleSms();
    INTERFACE_FRONT.confirmDemandeMiniSitePremium();
    INTERFACE_FRONT.updateVueMsgMaj();
    INTERFACE_FRONT.afficheVague();
    INTERFACE_FRONT.afficheDetailsDemande();
    INTERFACE_FRONT.supprimeDemande();
    INTERFACE_FRONT.envoiDemande();
    //INTERFACE_FRONT.exportDemande();
    INTERFACE_FRONT.checkCity();
    INTERFACE_FRONT.chooseCity();
    INTERFACE_FRONT.mailDemande();
    INTERFACE_FRONT.annuleDemande();
    INTERFACE_FRONT.validDemande();
    INTERFACE_FRONT.convertDemande();
    INTERFACE_FRONT.updateDemande();
    INTERFACE_FRONT.updateCoutProspect();
    INTERFACE_FRONT.comptaFacture();
    INTERFACE_FRONT.devisBonDeCommande();
    INTERFACE_FRONT.checkCityBtoB();
    INTERFACE_FRONT.chooseCityBtoB();
    INTERFACE_FRONT.updateCoutProspectBtoB();
    INTERFACE_FRONT.ajaxListeContactTechnique();
    INTERFACE_FRONT.ajaxSmsRecapitulatifTechnique();
    INTERFACE_FRONT.afficheDetailsCampagneTechnique();
    INTERFACE_FRONT.filterCampagneTechnique();
});
